import React from 'react';
import { Card, Icon, Tag } from '@blueprintjs/core';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router';
import './ProposalCard.css';
import { Link } from 'react-router-dom';
import { getFullDate, getReadableDate } from '../../../ui/FormattedDate/dateUtils';
import { Tooltip2 } from '@blueprintjs/popover2';
import VotingSummary from '../../../ui/VotingSummary/VotingSummary';
import PositionedIcon from '../../../ui/PositionedIcon/PositionedIcon';

const ProposalCard = ({
    docID,
    _id, 
    title,
    teamSlug,
    status,
    number,
    author,
    votes,
    voters,
    created,
    comments,
}) => {

    const createdDate = DateTime.fromISO(created);
    const formattedDate = getReadableDate(createdDate);
    const fullDate = getFullDate(createdDate);

    let statusIntent;
    if (status === 'ACTIVE') statusIntent = 'active';
    if (status === 'ACCEPTED') statusIntent = 'success';
    if (status === 'CLOSED') statusIntent = 'danger';

    return (
        <Link to={`/${teamSlug}/${docID}/suggestions/${_id}/changes`} className='no-deco'>
            <div
                className='ProposalCard rounded-lg border border-offwhite-hover hover:bg-offwhite transition'
            >
                <div className='MainInfo'>
                    <div className='ProposalCardHeader'>
                        <h3 className='ellipsis text-lg font-bold mt-0 mb-0'>
                            {title ? title : `Untitled suggestion`}
                        </h3>    
                        <div>
                            <Tag intent={statusIntent}
                                className='StatusTag'
                                // large
                            >
                                {status.toLowerCase()}
                            </Tag>
                        </div>
                    </div>
 
                    {/* <div className='ProposalVotes'>
                        <div className={votes.for > 0 ? 'VoteIndicator' : 'VoteIndicator half-opaque'}>
                            <Icon icon='thumbs-up' size={14}/> {votes.for}
                        </div>
                        <div className={votes.against > 0 ? 'VoteIndicator' : 'VoteIndicator half-opaque'}>
                            <Icon icon='thumbs-down' size={14}/> {votes.against}
                        </div>
              
                    </div> */}
                    <span>
                        <span className='font-size-12'>
                            {author.username}
                        </span>
                        <Tooltip2
                            content={fullDate}
                            minimal
                            popoverClassName='small-tooltip'
                            className='small-tooltip margin-left-12'
                            placement={'top'}
                        >
                            <span className='font-size-12 half-opaque'>
                                {formattedDate}
                            </span>
                        </Tooltip2>
                        {comments.length > 0 && (
                            <div className='ProposalVotes margin-left-8 margin-right-4'>
                                <span className={comments.length > 0 ? 'VoteIndicator' : 'VoteIndicator half-opaque'}>
                                    <PositionedIcon icon='chat' iconSize={11} top={-2} /> {comments.length}
                                </span>
                            </div>
                        )}
                    </span>
                </div>
                <VotingSummary voters={voters} />
            </div>
        </Link>
    )
}

export default ProposalCard;