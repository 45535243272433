import { gql } from "@apollo/client";

const UPDATE_DOC_SETTINGS = gql`
    mutation UpdateDocSettings($input: UpdateDocSettingsInput) {
        updateDocSettings(input: $input) {
            _id
            docID
            deadline
            participateAccess
            process {
                creatorCanEdit
                creatorCanChangeSettings
                controls {
                    processType
                    supportNeeded
                    approvers {
                        _id
                        username
                    }
                    approvalCount
                }
            }
        }
    }
`

export default UPDATE_DOC_SETTINGS;