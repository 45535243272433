import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import ACCEPT_INVITE from './mutations/acceptInvite';
import { useNavigate, useParams } from 'react-router';
import GlobalSpinner from '../ui/GlobalSpinner/GlobalSpinner';
import { useSelector } from 'react-redux';
import { addInvite, removeInvite } from '../../utils/localStorage';
import { apolloClient } from '../../index';

const InviteHandler = ({

}) => {

    const { inviteID } = useParams();
    const navigate = useNavigate();
    const currentUser = useSelector(state => state.auth.user);
    const inviteRedirect = useSelector(state => state.auth.inviteRedirect);

    const [acceptInvite, { loading, error, data }] = useMutation(ACCEPT_INVITE, {
        variables: {
            inviteID: inviteID
        },
        onCompleted: (data) => {

            if (!currentUser._id) {
                addInvite(`${data.acceptInvite.docID}-${inviteID}`);
            }

            if (inviteRedirect) {
                removeInvite(inviteID);
                navigate(inviteRedirect);
            }

            if (!inviteRedirect) {
                navigate(`/${data.acceptInvite.team}/${data.acceptInvite.docID}`);
            }

        }
    });

    useEffect(() => {
        acceptInvite();
    }, [])

    if (loading) {
        return <GlobalSpinner />
    }

    return (
        <div>invite handler</div>
    )

}

export default InviteHandler;