import React from 'react';
import { useQuery } from '@apollo/client';
import BLOCK_REPLIES from './queries/blockReplies';
import Comment from '../Comment';
import { Spinner } from '@blueprintjs/core';
import './CommentReplies.css'
import BLOCK_COMMENTS from '../../../../../../../DocText/TextBlock/ThreadSidebar/ThreadContainer/queries/blockComments';
import CommentEditor from '../../../CommentEditor/CommentEditor';

const CommentReplies = ({
    parentType, // BLOCK | COMMENT
    parentID,
    activeUser
}) => {

    const { loading, error, data } = useQuery(BLOCK_COMMENTS, {
        variables: {
            blockID: parentID
        }
    });

    if (loading) {
        return (
            <Spinner 
                size={16} 
                className='CommentRepliesLoading'
            />
        );
    }
    if (error) return 'error';

    const replyComments = data.thread.comments.filter((c, i) => i !== 0).map((comment, i) => {
        return (
            <Comment
                key={i}
                activeUser={activeUser}
                {...comment}
            />
        )
    });

    return (
        <div className='CommentReplies margin-top-16'>
            {replyComments}
            <CommentEditor 
                blockID={parentID} // TODO: revise this to support parent IDs for replying to comments (rather than blocks)
            />
        </div>
    )
}

export default CommentReplies;