import React, { useState } from 'react';
import { Button, Dialog, FormGroup, InputGroup, Overlay } from '@blueprintjs/core';
import slugify from 'slugify';
import isEmail from "validator/lib/isEmail";
import * as actions from '../../store/actions/index';
import './SignUpModal.css';
import UNIQUE_USERNAME from './queries/uniqueUsername';
import { useLazyQuery, useMutation } from '@apollo/client';
import SIGN_UP_WITH_EMAIL_NO_INVITE from './mutations/signUpWithEmailNoInvite';
import VERIFY_ACCOUNT from './mutations/verifyAccount';
import { useDispatch, useSelector } from 'react-redux';
import USERNAME_SIGN_IN from './mutations/usernameSignIn';
import TwitterLogo from '../ui/svg/TwitterLogo';
import TWITTER_SIGN_IN_START from './mutations/twitterSignInStart';
import { useLocation, useNavigate } from 'react-router';
import InvitedUserScreen from './InvitedUserScreen/InvitedUserScreen';
import ModalTopBar from '../ui/ModalTopBar/ModalTopBar';

const SignUpModal = ({
    initialPanel = 'SIGN_IN',
    isOpen = true,
    onClose,
    redirectOnSignIn
}) => {

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const user = useSelector(state => state.auth.user);

    const [emailError, setEmailError] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const setUser = (user) => dispatch(actions.setUser(user));

    const [twitterError, setTwitterError] = useState(false);

    const [panel, setPanel] = useState(() => user && user.newUser ? 'INVITED_USER' : initialPanel);
    const onSetSignUpPanel = () => setPanel('SIGN_UP');
    const onSetSignInPanel = () => setPanel('SIGN_IN');

    const [signInError, setSignInError] = useState(false);

    const [usernameInput, setUsernameInput] = useState('');
    const onSetUsernameInput = (e) => {
        if (signInError) setSignInError(false);
        setUsernameInput(e.target.value);
    }

    const [verificationCodeInput, setVerificationCodeInput] = useState('');
    const onSetVerificationCode = (e) => setVerificationCodeInput(e.target.value);

    const [emailInput, setEmailInput] = useState('');
    const onSetEmailInput = (e) => setEmailInput(e.target.value);

    const [passwordInput, setPasswordInput] = useState('');
    const onSetPasswordInput = (e) => {
        if (signInError) setSignInError(false);
        setPasswordInput(e.target.value);
    }

    const handleClose = () => {
        setEmailError(false);
        setUsernameError(false);
        setPanel(initialPanel);
        onClose();
    }

    const [signUpWithEmail, { loading: signingUp, error: signUpError, data: signUpData }] = useMutation(
        SIGN_UP_WITH_EMAIL_NO_INVITE, 
        {
            onCompleted: (data) => {
                if (data && data.signUpWithEmailNoInvite && data.signUpWithEmailNoInvite.success === true) {
                    setPanel('VERIFY');
                }
            }
        }
    )

    const [verifyAccount, { loading: verifyingAcct, error: verifyError, data: verifyData }] = useMutation(
        VERIFY_ACCOUNT, 
        {
            onCompleted: (data) => {
                if (data.verifyAccount.error) {
                    setSignInError(`That code didn't work`);
                }
                if (data && data.verifyAccount && data.verifyAccount.jwt) {
                    window.localStorage.setItem(`__stele-token__`, data.verifyAccount.jwt);
                    setUser(data.verifyAccount.user);
                    handleClose();
                }
            }
        }
    )

    const [signInWithUsername, { loading: signingInWithUsername, error: signInWithUsernameError, data: signInWithUsernameData }] = useMutation(
        USERNAME_SIGN_IN, 
        {
            onCompleted: (data) => {
                if (data.usernameSignIn.error) {
                    setSignInError(`Those credentials didn't work`);
                }
                if (data && data.usernameSignIn && data.usernameSignIn.jwt) {
                    window.localStorage.setItem(`__stele-token__`, data.usernameSignIn.jwt);
                    setUser(data.usernameSignIn.user);
                    if (redirectOnSignIn) {
                        navigate(redirectOnSignIn);
                    } else {
                        handleClose();
                    }
                }
            }
        }
    )

    const [signInWithTwitterStart, { loading: signingInWithTwitter, error: signInWithTwitterError, data: signInWithTwitterData }] = useMutation(
        TWITTER_SIGN_IN_START,
        {
            onCompleted: (data) => {
                if (signInWithTwitterError) {
                    setSignInError('Sorry, unable to log in with Twitter right now');
                }
                if (data && !signInWithTwitterError) {
                    window.location.href = `https://api.twitter.com/oauth/authorize?oauth_token=${data.twitterSignInStart}`;
                }
            }
        }
    )

    const [checkUsername, { called, loading: checkingUsername, data: usernameStatus }] = useLazyQuery(UNIQUE_USERNAME,
        {
            variables: {
                username: usernameInput
            },
            onComplete: (data) => {
            }
        }
    );

    const checkEmailForError = () => {
        setEmailError(!isEmail(emailInput));
    }

    const checkUsernameForError = () => {
        const regex = /^[a-zA-Z0-9_]{1,32}$/;
        if (usernameInput === '') {
            setUsernameError('NO_USERNAME');
            return;
        }

        else {
            setUsernameError(!regex.test(usernameInput));
            if (panel === 'SIGN_UP') { checkUsername(); }
        }
    }

    let errors = [];
    if (usernameError && usernameError !== `NO_USERNAME`) errors.push(`Usernames can only contain letters, numbers and underscores`);
    if (usernameError === `NO_USERNAME`) errors.push(`Enter a username`);
    if (emailError) errors.push(`Enter a valid email`);
    if (usernameStatus && usernameStatus.uniqueUsername === false) errors.push(`That username is taken`);
    if (signInError) errors.push(signInError);

    const cannotJoin = errors.length !== 0 || emailInput === '' || usernameInput === '' || passwordInput === '';
    const cannotSignIn = errors.length !== 0 || usernameInput === '' || passwordInput === '';

    const errorList = errors.map((error, i) => {
        return (
            <div className='SignInError' key={i}>
                <span className='ErrorBody'>
                    <span className='ErrorIcon'>☹</span> 
                    <span className='ErrorText'>{error}</span>
                </span>
            </div>
        )
    })

    const onSignInWithTwitter = () => {
        signInWithTwitterStart({
            variables: {
                location: location.pathname
            }
        })
    }

    const onSignUpWithEmail = () => {
        signUpWithEmail({
            variables: {
                input: {
                    username: usernameInput,
                    email: emailInput,
                    password: passwordInput
                }
            }
        })
    }

    const onVerifyAccount = () => {
        verifyAccount({
            variables: {
                input: {
                    username: usernameInput,
                    email: emailInput,
                    otp: verificationCodeInput
                }
            }
        })
    }

    const onSignInWithUsername = () => {
        signInWithUsername({
            variables: {
                username: usernameInput,
                password: passwordInput
            }
        })
    }

    return (
        <Dialog 
            className='cw-modal'
            isOpen={isOpen}
            onClose={handleClose}
            transitionDuration={300}
            transitionName='fade'
        >
            <ModalTopBar 
                minimal
                onClose={onClose}
            />
            <div className='modal-bottom-padding'>
                {panel === 'INVITED_USER' && (
                    <InvitedUserScreen
                        user={user}
                        onClose={onClose}
                        checkUsername={checkUsername}
                        checkingUsername={checkingUsername}
                        checkUsernameForError={checkUsernameForError}
                        usernameError={usernameError}
                    />
                )}
                {(panel === `SIGN_UP` || panel === `VERIFY`) && <h1 style={{ marginBottom: 24 }}>Join Crowdwrite</h1>}
                {panel === `SIGN_IN` && <h1 style={{ marginBottom: 24 }}>Sign in</h1>}
                {errorList.length > 0 && <div className='ErrorList'>{errorList}</div>}
                {panel === `SIGN_UP` && (
                    <div className='modal-padded-section'>
                        <FormGroup 
                            label='Username'
                            labelFor='username'
                        >
                            <InputGroup 
                                id='username'
                                value={usernameInput}
                                onChange={onSetUsernameInput}
                                onBlur={checkUsernameForError}
                            />
                        </FormGroup>
                        <FormGroup 
                            label='Email'
                            labelFor='email'
                        >
                            <InputGroup 
                                id='email'
                                value={emailInput}
                                onChange={onSetEmailInput}
                                onBlur={checkEmailForError}
                            />
                        </FormGroup>
                        <FormGroup 
                            label='Password'
                            labelFor='password'
                        >
                            <InputGroup 
                                id='password'
                                type='password'
                                value={passwordInput}
                                onChange={onSetPasswordInput}
                            />
                        </FormGroup>
                        <Button 
                            intent='primary'
                            text='Join'
                            className='btn-padding'
                            style={{ minWidth: 120 }}
                            onClick={onSignUpWithEmail}
                            disabled={cannotJoin}
                            loading={signingUp}
                        />
                        <h3 style={{ marginTop: 24 }}>Already have an account?</h3>
                        <Button 
                            className='primary-neutral-btn btn-padding'
                            text='Sign in'
                            style={{ minWidth: 120 }}
                            onClick={onSetSignInPanel}
                        />
                    </div>
                )}
                {panel === `SIGN_IN` && (
                    <div className='modal-padded-section'>
                        <div className='SocialSignIn'>
                            <Button 
                                className='primary-neutral-btn'
                                loading={signingInWithTwitter}
                                onClick={onSignInWithTwitter}
                            >
                                <span style={{
                                    position: 'relative',
                                    top: 2,
                                    marginRight: 6
                                }}>
                                    <TwitterLogo />
                                </span>
                                <span 
                                    className='bold'
                                    style={{
                                        position: 'relative',
                                        top: -1
                                    }}
                                >
                                    {signInWithTwitterData ? `Redirecting to Twitter...` : `Sign in with Twitter`}
                                </span>
                            </Button>
                        </div>
                        <FormGroup 
                            label='Username'
                            labelFor='username'
                        >
                            <InputGroup 
                                id='username'
                                value={usernameInput}
                                onChange={onSetUsernameInput}
                                onBlur={checkUsernameForError}
                            />
                        </FormGroup>
                        <FormGroup 
                            label='Password'
                            labelFor='password'
                        >
                            <InputGroup 
                                id='password'
                                type='password'
                                value={passwordInput}
                                onChange={onSetPasswordInput}
                            />
                        </FormGroup>
                        <Button 
                            intent='primary'
                            text='Sign in'
                            className='btn-padding'
                            style={{ minWidth: 120 }}
                            onClick={onSignInWithUsername}
                            disabled={cannotSignIn}
                            loading={signingInWithUsername}
                        />
                        <h3 style={{ marginTop: 24 }}>Don't have an account?</h3>
                        <Button 
                            className='primary-neutral-btn btn-padding'
                            text='Sign up'
                            style={{ minWidth: 120 }}
                            onClick={onSetSignUpPanel}
                        />
                    </div>
                )}
                {panel === `VERIFY` && (
                    <div>
                        <p className='large-text soft-text'>Confirm your email</p>
                        <FormGroup 
                            label='Enter the code we emailed you'
                            labelFor='verification-code'
                            style={{ marginBottom: 64}}
                        >
                            <InputGroup 
                                id='verification-code'
                                value={verificationCodeInput}
                                onChange={onSetVerificationCode}
                            />
                        </FormGroup>
                        <Button 
                            intent='primary'
                            text='Confirm'
                            className='btn-padding'
                            style={{ minWidth: 120 }}
                            onClick={onVerifyAccount}
                            disabled={verificationCodeInput.length !== 6}
                            loading={verifyingAcct}
                        />
                    </div>
                )}
            </div>
        </Dialog>
    )
}

export default SignUpModal;