import { gql } from "@apollo/client";

const READ_DOC = gql`
    query ReadDoc($docID: String) {
        doc(docID: $docID) {
            activeProposals
        }
    }
`;

const WRITE_DOC = gql`
    query WriteDoc($docID: String) {
        doc(docID: $docID) {
            activeProposals
        }
    }
`;

const updateDocProposalCount = (cache, docID, direction) => {
    const cachedDoc = cache.readQuery({
        query: READ_DOC,
        variables: { docID: docID }
    });

    let newCount;
    switch (direction) {
        case 'DOWN': newCount = cachedDoc.activeProposals - 1; break;
        case 'UP': 
        default: newCount = cachedDoc.activeProposals + 1;
    };

    cache.writeQuery({
        query: WRITE_DOC,
        data: {
            doc: {
                activeProposals: newCount
            }
        },
        variables: {
            docID: docID
        }
    });
};

export default updateDocProposalCount;
