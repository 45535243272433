import React from 'react';
import Modal from '../../../ui/Modal/Modal';
import ModalTopBar from '../../../ui/ModalTopBar/ModalTopBar';
import { Dialog } from '@blueprintjs/core';
import MiniProposalCard from '../../../SnippetDoc/SnippetProposals/MiniProposalCard/MiniProposalCard';
import ProposalCard from '../../DocProposals/ProposalCard/ProposalCard';

const SuggestionListModal = ({
    docID,
    teamSlug,
    proposals,
    isOpen,
    onClose,
    blockKey
}) => {

    let proposalBlocks;
    if (proposals) {
        proposalBlocks = proposals.filter(pr => pr.touchedBlockKeys.includes(blockKey)).map((pr, i) => {
            return (
                <ProposalCard
                    key={i} 
                    docID={docID} 
                    teamSlug={teamSlug}
                    {...pr} 
                />
            );
        })
    }

    return (
        <Dialog 
            isOpen={isOpen}
            onClose={onClose}
            className='cw-modal'
            transitionDuration={300}
            transitionName='fade'
        >
            <ModalTopBar minimal 
                onClose={onClose}
            />
            <div className='px-4 max-h-[70vh] overflow-scroll'>
                <p className='text-lg mb-2 font-bold'>
                    Suggestions
                </p>
                <div className='flex flex-col gap-2 pb-16'>
                    {proposalBlocks}
                </div>
            </div>    
        </Dialog>
    )

}

export default SuggestionListModal;