import React from 'react';
import CrowdwriteLogo from '../../ui/svg/CrowdwriteLogo';
import './ProfilePic.css';

const ProfilePic = ({
    profilePicPath,
    size = 'lg'
}) => {


    const profilePicURL = profilePicPath && profilePicPath !== '' ? `${process.env.REACT_APP_IMAGE_CDN_BASE_URL}${profilePicPath}` : null;

    return (
        <div className={`ProfilePic ${size}`}>
            {profilePicURL && (
                <img 
                    src={profilePicURL} 
                    alt='Profile Picture' 
                    className={`border border-gray-200`}
                />
            )}
            {!profilePicURL && (
                <div className='PlaceholderGraphic flex justify-center -mt-2'>
                    <CrowdwriteLogo width={88} fill='#fff'/>
                </div>
            )}
        </div>
    )
}

export default ProfilePic;