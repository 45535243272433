import { Button } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import React from 'react';
import './BlockActionMenu.css';

const BlockActionMenu = ({
    diffStatus,
    onToggleComments,
    showingComments
}) => {

    if (diffStatus === 'UNCHANGED') return null;

    return (
        <div className='BlockActionMenu'>
            <Tooltip2
                content={'Comment'}
                placement='top'
            >
                <Button
                    small
                    icon='comment'
                    className={showingComments ? 'primary-neutral-btn active' : 'primary-neutral-btn'}
                    onClick={onToggleComments}
                />
            </Tooltip2>
        </div>
    )
}

export default BlockActionMenu;