import React, { useEffect, useRef, useState } from "react";
import { getReadableDate } from "../../ui/FormattedDate/dateUtils";
import TextBlock from "../../Doc/DocText/TextBlock/TextBlock";
import { getListItemIndex } from "../../Doc/DocText/TextBlock/textBlockUtils/listItemUtils";
import './DocReadView.css';
import UserHeader from "./UserHeader/UserHeader";
import { useLocation } from "react-router";
import { mode } from "../../../utils/mode";
import TOC from "./TOC/TOC";
import { getHeadings } from "./TOC/tocUtils";

const DocReadView = ({
    title,
    description,
    sponsor,
    lastEdited,
    text,
    entityMap,
    creator
}) => {

    const sectionRefs = useRef([]);
    const location = useLocation();

    const lastEditedDate = getReadableDate(lastEdited);

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
          const sectionId = hash.substring(1); // Remove the '#' from the hash
          const section = document.getElementById(sectionId);
          if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
          }
        }
    }, [location]);

    const textBlocks = text.map((block, i) => {
        const blockEntityKeys = block.entityRanges.map(range => range.key);
        const entities = entityMap ? entityMap.filter(entity => blockEntityKeys.includes(entity.key)) : [];
        return (
            <div 
                key={i}
                id={block._id}
                ref={(el) => (sectionRefs.current[i] = el)}
            >
                <TextBlock key={i} {...block} 
                    blockKey={block.key}
                    canInteract={false}
                    showEditModal={() => null}
                    listItemIndex={getListItemIndex(text, i)}
                    entities={entities}
                    // showAddComment={onShowAddComment}
                    // hideAddComment={onHideAddComment}
                    // showEmojiPalette={onShowEmojiPalette}
                    // hideEmojiPalette={onHideEmojiPalette}
                    // showingEmojiPalette={showingEmojiPalette}
                    // docID={docID}
                />
            </div>
            
        )
    });

    const showTOC = getHeadings(text).length > 0;

    return (
        <div className="margin-left-20 DocReadView relative mb-96">
            <UserHeader {...creator} />
            <h1 className="tracking-tight text-4xl">{title}</h1>
            <p 
                className='large-text soft-text mb-2'
                style={{ 
                    fontSize: 24
                }}
            >
                {description}
            </p>
            <p className="opacity-60">Last edited {lastEditedDate}</p>
            <div className="flex gap-16 relative">
                <div>
                    <div className="mt-8 TextBody">
                        {textBlocks}
                    </div>
                </div>
                {showTOC && (
                    <TOC 
                        text={text}
                        sectionRefs={sectionRefs}
                    />
                )}
            </div>
    
        </div>
    )

}

export default DocReadView;