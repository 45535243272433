import { gql } from "@apollo/client";

const USER_ACTIVITY = gql`
    query UserActivity($id: String, $offset: Int, $limit: Int) {
        userEdits(id: $id)
        docs(creator: $id, limit: 100) {
            docID
            title
            team
            activeProposals
            lastEdited
            versionCount
            created
            contributors {
                username
            }
        }
        eventCount(user: $id)
        activity(user: $id, offset: $offset, limit: $limit) {
            _id
            date 
            ... on CreateDocEvent {
                userID
                doc {
                    docID
                    team
                    title
                }
            }
            ... on CreateProposalEvent {
                user {
                    username
                }
                doc {
                    docID
                    team
                    title
                }
                proposal {
                    _id
                    number
                    title
                    status
                    totalComments
                    votes {
                        for
                        against
                    }
                }
            }
            ... on UpdateDocEvent {
                user {
                    username
                }
                doc {
                    docID
                    team
                    title
                }
                docVersion {
                    version
                    changeMessage
                    changeDescription
                }
            }
            ... on AcceptProposalEvent {
                proposer {
                    username
                }
                approver {
                    username
                }
                doc {
                    docID
                    team
                    title
                }
                proposal {
                    _id
                    title
                    status
                    number
                    totalComments
                }
            }
            ... on CloseProposalEvent {
                proposer {
                    username
                }
                closer {
                    username
                }
                doc {
                    docID
                    team
                    title
                }
                proposal {
                    _id
                    title
                    status
                    number
                    totalComments
                }
            }
            ... on CommentEvent {
                user {
                    username
                }
                comment {
                    _id
                    text
                }
                doc {
                    docID
                    team
                    title
                }
                proposal {
                    _id
                    title
                    author {
                        username
                    }
                    status
                    number
                    totalComments
                }
            }
            ... on VoteEvent {
                date
                user {
                    username
                }
                proposal {
                    _id
                    title
                    author {
                        username
                    }
                    doc {
                        team
                        docID
                        title
                    }
                    status
                    number
                    totalComments
                }
                vote {
                    stance
                    prID
                    user {
                        _id
                        username
                    }
                }
            }
        }
    }
`

export default USER_ACTIVITY;