import { gql } from "@apollo/client";

const SIGN_UP_WITH_EMAIL_NO_INVITE = gql`
    mutation SignUpWithEmailNoInvite($input: SignUpWithEmailNoInviteInput) {
        signUpWithEmailNoInvite(input: $input) {
            success
            error
        }
    }
`

export default SIGN_UP_WITH_EMAIL_NO_INVITE;