import { Editor, EditorState, getDefaultKeyBinding, KeyBindingUtil, Modifier, RichUtils } from "draft-js";
import { OrderedSet } from "immutable";
import { checkForLockedPrevChar, checkForLockedTextInRange, isLockedAtNextChar, isLockedAtPrevChar, isLockedTextInRange, isRangeSelected, preventLockedTextEdit } from "./lockedTextUtils";

export const keyBindingFn = (e, userCanSave, editorState, setEditorState) => {
    if (e.keyCode === 8 /* backspace */) { 
        e.preventDefault();
        return `backspace` 
    };

    if (e.keyCode === 75 && KeyBindingUtil.hasCommandModifier(e)) {
        e.preventDefault();
        return 'add-link'
    }

    // copy
    if (e.keyCode === 67 && KeyBindingUtil.hasCommandModifier(e)) {
        return getDefaultKeyBinding(e);
    }

    // paste
    if (e.keyCode === 86 && KeyBindingUtil.hasCommandModifier(e)) {
        return getDefaultKeyBinding(e);
    }

    const preventLockedEdit = preventLockedTextEdit(editorState);
    if (preventLockedEdit
        && (
            (e.keyCode >= 48 && e.keyCode <= 90) 
            || e.keyCode === 32
            || (e.keyCode >= 96 && e.keyCode <= 111) 
            || (e.keyCode >= 186 && e.keyCode <= 192)
            || (e.keyCode >= 219 && e.keyCode <= 222)
        )
    )
    {
        e.preventDefault();
        if (isLockedAtPrevChar(editorState) && !isLockedAtNextChar(editorState)) {
            const currentContent = editorState.getCurrentContent();
            const currentSelection = editorState.getSelection();
            const newChar = e.shiftKey ? String.fromCharCode(e.keyCode).toUpperCase() : String.fromCharCode(e.keyCode).toLowerCase();  
            const newContent = Modifier.insertText(
                currentContent,
                currentSelection,
                newChar
            );
            const newEditorState = EditorState.push(
                editorState, 
                newContent,
                'insert-characters'
            );
            setEditorState(newEditorState);
            return getDefaultKeyBinding(e);
        }
        return 'backspace' // TODO: differentiate this label from backspace for maintainability
    }
    return getDefaultKeyBinding(e);
}

export const handleKeyCommand = (command, userCanSave, editorState, onSetEditorState) => {
    let newState;

    if (command === `unlock`) {
        const currentInlineStyles = [...editorState.getCurrentInlineStyle()];
        const filteredInlineStyles = currentInlineStyles.filter(style => style !== 'LOCKED');
        const styleSet = OrderedSet(filteredInlineStyles);
        const editorStateWithoutActiveLock = EditorState.setInlineStyleOverride(editorState, styleSet);
        onSetEditorState(editorStateWithoutActiveLock);
        return 'handled';
    }

    if (command === `backspace`) {
        
        // use 'userCanSave' as a proxy for authorized users for now
        // if (userCanSave) return 'not-handled';

        const selection = editorState.getSelection();
        const anchorKey = selection.getAnchorKey();
        const focusKey = selection.getFocusKey();
        const anchorOffset = selection.getAnchorOffset();
        const focusOffset = selection.getFocusOffset();

        // handle cases where there is no selection (anchor and focus are the same)
        if (anchorKey === focusKey && anchorOffset === focusOffset) {
            return checkForLockedPrevChar(editorState);
        } 
        // handle selections
        return checkForLockedTextInRange(editorState);

    }

    newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      onSetEditorState(newState);
      return 'handled';
    }

    return 'not-handled';
}

export const textControls = (userCanSave, editorState, setEditorState) => {
    return {
        focus: Editor.focus,
        currentBlockType: RichUtils.getCurrentBlockType(editorState),
        bold: {
            status: editorState.getCurrentInlineStyle().has('BOLD'),
            toggle: () => {
                setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'))
            }
        },
        italic: {
            status: editorState.getCurrentInlineStyle().has('ITALIC'),
            toggle: () => {
                setEditorState(RichUtils.toggleInlineStyle(editorState, 'ITALIC'))
            }
        },
        underline: {
            status: editorState.getCurrentInlineStyle().has('UNDERLINE'),
            toggle: () => {
                setEditorState(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'))
            }
        },
        locked: {
            status: (isLockedAtPrevChar(editorState) && isLockedAtNextChar(editorState) 
                    || ((isRangeSelected(editorState) && isLockedTextInRange(editorState)))),
            toggle: () => setEditorState(RichUtils.toggleInlineStyle(editorState, 'LOCKED'))
        },
        code: {
            status: editorState.getCurrentInlineStyle().has('CODE'),
            toggle: () => setEditorState(RichUtils.toggleInlineStyle(editorState, 'CODE'))
        },
        blockType: {
            toggle: (blockType) => {
                if (!userCanSave) {
                    const rangeHasLockedText = checkForLockedTextInRange(editorState);
                    if (rangeHasLockedText === 'handled') return;
                }
                setEditorState(RichUtils.toggleBlockType(editorState, blockType))
            }
        }
    }
}