import { Button, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import React, { useState } from 'react';

const SortSelect = ({
    isDisabled,
    selectedSort,
    selectSort
}) => {

    const options = [
        {
            label: 'Most popular',
            value: 'MOST_POPULAR'
        },
        {
            label: 'Least popular',
            value: 'LEAST_POPULAR'
        },
        {
            label: 'Newest',
            value: 'NEWEST'
        },
        {
            label: 'Oldest',
            value: 'OLDEST'
        },
    ];

    const onItemSelect = (item) => selectSort(item.value);
    const itemRenderer = (item, { active }) => {
        return (
            <MenuItem 
                key={item.value}
                text={item.label} 
                active={active} 
                onClick={() => onItemSelect(item)}
            />
        );
    }
    
    const selectedSortLabel = options.find(option => option.value === selectedSort).label;

    return (
        <Select 
            filterable={false}
            itemRenderer={itemRenderer}
            items={options}
            onItemSelect={onItemSelect}
            popoverProps={{ minimal: true }}
            disabled={isDisabled}
        >
            <Button small 
                className='primary-neutral-btn'
                text={selectedSortLabel} 
                rightIcon='caret-down' 
                disabled={isDisabled}
            />
        </Select>
    )
}

export default SortSelect;