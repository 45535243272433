import React from 'react';
import PositionedIcon from '../../../ui/PositionedIcon/PositionedIcon';
import { Link, useLocation } from 'react-router-dom';
import { Tooltip2 } from '@blueprintjs/popover2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookOpenReader } from '@fortawesome/free-solid-svg-icons'
import './ViewToggle.css';

const ViewToggle = ({
    teamSlug, 
    docID
}) => {

    const { pathname } = useLocation();
    const readIsActive = pathname.substring(pathname.length-5) === '/read';

    return (
        <div className='ViewToggle flex'>
            {readIsActive && (
                <Tooltip2
                    minimal
                    small
                    placement='left'
                    hoverOpenDelay={300}
                    popoverClassName='relative right-2'
                    content={<span className='text-md'>Contribute to this text</span>}
                >
                    <Link 
                        to={`/${teamSlug}/${docID}/text`}
                        target='_blank'
                        className={'ViewToggle'} 
                    >
                        {/* <FontAwesomeIcon
                            icon={faBookOpen}
                        /> */}
                        {/* <PositionedIcon 
                            icon='edit' 
                            iconSize={14} 
                            top={0} 
                            color='#555'
                        /> */}
                    </Link>
                </Tooltip2>
            )}
            {!readIsActive && (
                <Tooltip2
                    minimal
                    small 
                    placement='left'
                    hoverOpenDelay={300}
                    popoverClassName='relative right-2 z-50'
                    portalClassName='z-50'
                    content={<span className='font-size-12 leading-none'>Reading view</span>}
                >
                    <Link to={`/${teamSlug}/${docID}/read`}
                        target='_blank'
                        className={'ViewToggle'} 
                    >
                        <FontAwesomeIcon
                            icon={faBookOpenReader}
                            // size='lg'
                        />
                    </Link>
                </Tooltip2>
            )}
        </div>
    )

}

export default ViewToggle;