import { getUser } from './authClient';

const bootstrapAppData = async () => {
    
    const data = await getUser();

    if (!data) {
        return { user: null }
    }

    return {
        user: { user: data.login }
    }

}

export default bootstrapAppData;