import { gql } from "@apollo/client";

const DOC_VERSIONS = gql`
    query DocVersions($docID: String) {
        docVersions(docID: $docID) {
            _id
            created
            creator {
                _id
                username
            }
            changeMessage
            changeDescription
            prID
            version
            proposal {
                author {
                    _id
                    username
                }
                acceptedBy {
                    _id
                    username
                }
                voters {
                    stance
                    user {
                        _id
                        username
                    }
                }
                votes {
                    for
                    against
                    net
                    total
                }
            }
        }
    }
`

export default DOC_VERSIONS;