import React from 'react';
import { Link } from 'react-router-dom';
import PositionedIcon from '../../../ui/PositionedIcon/PositionedIcon';
import StatusTag from '../../../ui/StatusTag/StatusTag';
import ProposalTag from '../ProposalTag/ProposalTag';
import DocTag from '../DocTag/DocTag';

const CloseProposalEvent = ({
    closer,
    date,
    doc,
    proposal,
    proposer,
    context
}) => {

    let text = (
        <p>
            <span className='font-size-12'>
                {context === 'GLOBAL_FEED' && (
                    <span><Link to={`/user/${closer.username}`} className='font500'>{closer.username}</Link> closed </span>
                )}
                {context === 'PROFILE' && (
                    <span>Closed </span>
                )}
                <Link to={`/user/${proposer.username}`} className='font500'>
                    {proposer.username}'s
                </Link> suggestion 
            </span>
            <span className='margin-left-8'>
                <ProposalTag
                    link={`/${doc.team}/${doc.docID}/suggestions/${proposal._id}/changes`}
                    title={proposal.title}
                    status={proposal.status}
                />
            </span>
        </p>
    )

    return (
        <div className='EventBlock'>
            <div className='EventIcon'>
                <PositionedIcon icon='cross' top={3} iconSize={14} />
            </div>
            <div className='EventInfo'>
                {text}
            </div>
        </div>
    )
}

export default CloseProposalEvent;
