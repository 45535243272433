import gql from "graphql-tag"

// TODO: refactor or eliminate duplicate PROPOSAL query in ProposalModal

const PROPOSAL = gql`
    query Proposal($docID: String, $prID: String) {
        doc(docID: $docID) {
            title
            docID
            activeProposals
            creator {
                _id
            }
            process {
                controls {
                    processType
                    supportNeeded
                    approvalCount
                    approvers {
                        _id
                        username
                    }
                }
            }
        }
        vote(prID: $prID) {
            _id
            user {
                _id
            }
            prID
            stance
        }
        proposal(prID: $prID) {
            _id
            title
            docTitleDiff {
                value
                added
                removed
            }
            description
            closed
            closedBy {
                _id
                username
            }
            accepted
            acceptedBy {
                _id
                username
            }
            votes {
                for
                against
                net
                total
            }
            voters {
                stance
                user {
                    _id
                    username
                }
            }
            status
            number
            author {
                _id
                username
            }
            totalComments
            comments {
                _id
                author {
                    _id
                    username
                }
                text
                created
                blockID
            }
            created
            text { 
                key
                text
                _id
                type
                depth
                inlineStyleRanges {
                    length
                    offset
                    style
                }
            }
            diff {
                _id
                text
                contextualID
                key
                diffStatus
                textDiff {
                    value
                    added
                    removed
                }
                type
                depth
                inlineStyleRanges {
                    length
                    offset
                    style
                }
                entityRanges {
                    length
                    offset
                    key
                }
            }
            entities {
                type
                mutability
                data {
                    url
                }
                key
            }
            changes {
                _id
                contextualID
                text
                key
                type
                depth
                diffStatus
                inlineStyleRanges {
                    length
                    offset
                    style
                }
                entityRanges {
                    length
                    offset
                    key
                }
                textDiff {
                    value
                    added
                    removed
                }
            }
        }
    }
`

export default PROPOSAL;