import React from 'react';
import VerifyEmailBanner from '../VerifyEmailBanner/VerifyEmailBanner';
import { Link } from 'react-router-dom';
import CrowdwriteLogo from '../../../ui/svg/CrowdwriteLogo';
import AccountMenu from '../AccountMenu/AccountMenu';
import { Button } from '@blueprintjs/core';
import ViewToggle from '../ViewToggle/ViewToggle';
import DocEditorModal from '../../../Doc/DocText/DocEditorModal/DocEditorModal';

const SourceViewMenu = ({
    currentUser,
    onShowEditor,
    teamSlug,
    docID,
    onCloseEditor,
    topMenuContext,
    showingEditModal
}) => {

    return (
        <div className='fixed top-0 inset-x-0 z-40 left-60'
        >
            <VerifyEmailBanner
                user={currentUser}
            />
            <div 
                className='flex justify-between bg-white relative px-2 py-2' 
            >
                <div className='flex space-between align-center gap-2'
                    style={{ width: 200 }}
                >
                    <nav className='GlobalNav font-size-12 vis-hidden'>
                        <Link to='/activity'>Activity</Link>
                    </nav>
                </div>
                {docID && topMenuContext && (
                    <div className='font-semibold tracking-tight text-sm flex gap-2 align-center'>
                        <span className='leading-none'>
                            {topMenuContext.title}
                        </span>
                    </div>
                )}
                <div className='flex justify-end align-center gap-2 RightMenu'
                    style={{ width: 200 }}
                >
                    <AccountMenu currentUser={currentUser}/>
                    <div 
                        className='NewTextBtn'
                    >
                        <Button 
                            small
                            intent='primary'
                            // className='secondary-btn'
                            onClick={onShowEditor}
                        >
                            New
                        </Button>
                    </div>
                    {/* {docID && topMenuContext && (
                        <ViewToggle 
                            teamSlug={teamSlug}
                            docID={docID}
                        />
                    )} */}
                </div>
                {currentUser && (
                    <DocEditorModal
                        isOpen={showingEditModal}
                        docID={'NEW'}
                        teamID={currentUser._id}
                        teamSlug={currentUser.username}
                        userCanSave={true}
                        onClose={onCloseEditor}
                        text={null}
                        entityMap={null}
                        title={''}
                        userId={currentUser._id}
                    />
                )}
            </div>
        </div>
    )
}

export default SourceViewMenu;