import React from 'react';

const TOCItem = ({ 
    item,
    onClickTOCItem
}) => { 

    let classes = ['ml-5', 'text-lg', 'font-semibold'];
    if (!item.isActive) { classes.push('opacity-50')}

    return (
        <li className={'ml-5 font-bold tracking-tight leading-tight'}>
            <a 
                href={`#${item._id}`}
                className={!item.isActive ? 'opacity-50 mb-1 block max-w-48' : 'mb-1 block max-w-48'}
                onClick={onClickTOCItem}
            >
                {item.text}
            </a>
            {item.items && item.items.length > 0 && (
            <ul className='list-none'>
                {item.items.map((childItem) => (
                <TOCItem key={childItem._id} item={childItem} />
                ))}
            </ul>
            )}
        </li>
    );
};

export default TOCItem;