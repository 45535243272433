import React, { useEffect } from "react";
import GlobalSpinner from "../ui/GlobalSpinner/GlobalSpinner";
import { useMutation } from "@apollo/client";
import VERIFY_EMAIL from "./mutations/verifyEmail";
import { useNavigate, useParams } from "react-router";
import { SteleToaster } from "../ui/Toaster/Toaster";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../store/actions";

const VerifyEmail = ({

}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const currentUser = useSelector(state => state.auth.user);


    const { token } = useParams();
    const [verifyEmail, { loading, error, data }] = useMutation(VERIFY_EMAIL);

    useEffect(() => {
        if (token) {
            verifyEmail({
                variables: {
                    token: token
                },
                onCompleted: (data) => {
                    if (data && data.verifyEmail && data.verifyEmail === true) {
                        dispatch(setUser({
                            ...currentUser,
                            emailVerified: true
                        }));
                        SteleToaster.show({
                            message: 'Email verified',
                            className: 'dark'
                        });
                    }
                    navigate(`/docs`)
                }
            })
        }
    }, [token])

    return (
        <div>
            <GlobalSpinner />
        </div>
    )
}

export default VerifyEmail;