import React from 'react';
import { Card } from '@blueprintjs/core';
import SnippetTopMenu from '../../SnippetEditor/SnippetTopMenu/SnippetTopMenu';

const TeamSkeleton = ({
    currentUser
}) => {
    
    return (
        <div className='SnippetDocContainer SnippetHorizontalContainer'>
            <SnippetTopMenu currentUser={currentUser} />
            <h1 className='bp3-skeleton'>Team</h1>
            <div className='TeamDocs'>
                <Card className='content-card bp3-skeleton'>
                    <h3>Card title</h3>
                    <p className='half-opaque font-size-12'>
                        Started by skeleton
                    </p>
                </Card>
                <Card className='content-card bp3-skeleton'>
                    <h3>Card title</h3>
                    <p className='half-opaque font-size-12'>
                        Started by skeleton
                    </p>
                </Card>
                <Card className='content-card bp3-skeleton'>
                    <h3>Card title</h3>
                    <p className='half-opaque font-size-12'>
                        Started by skeleton
                    </p>
                </Card>
                <Card className='content-card bp3-skeleton'>
                    <h3>Card title</h3>
                    <p className='half-opaque font-size-12'>
                        Started by skeleton
                    </p>
                </Card>
                <Card className='content-card bp3-skeleton'>
                    <h3>Card title</h3>
                    <p className='half-opaque font-size-12'>
                        Started by skeleton
                    </p>
                </Card>
            </div>
        </div>
    )
}

export default TeamSkeleton;