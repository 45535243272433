import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import './SnippetDocNav.css';

const SnippetDocNav = ({
    showSettings,
    creator,
    activeProposals,
    totalProposals,
    totalComments,
    versionCount
}) => {

    const { teamSlug, docID } = useParams();
    // const userId = useSelector(state => state.auth.user._id);
    // const userIsCreator = creator === userId;

    return (
        <div className='SnippetDocNav'>
            <div className='SnippetDocNavItemContainer'>
                <NavLink to={`/${teamSlug}/${docID}/text`}>Text</NavLink>
            </div>
            {/* <div className='SnippetDocNavItemContainer'>
                <NavLink to={`/${teamSlug}/${docID}/comments`}>
                    Comments
                    <span className='SnippetDocNavItemCount'>{totalComments}</span>
                </NavLink>
            </div> */}
            <div className='SnippetDocNavItemContainer'>
                <NavLink to={`/${teamSlug}/${docID}/suggestions`}>
                    Suggestions
                    {activeProposals > 0 && (<span className='SnippetDocNavItemCount active'>{activeProposals}</span>)}
                    {(totalProposals > 1 || (totalProposals === 1 && activeProposals === 0)) && (<span className='SnippetDocNavItemCount'>{totalProposals}</span>)}
                </NavLink>
            </div>
            <div className='SnippetDocNavItemContainer'>
                <NavLink to={`/${teamSlug}/${docID}/history`}>
                    History
                    <span className='SnippetDocNavItemCount'>{versionCount}</span>
                </NavLink>
            </div>
        </div>
    )
}

export default SnippetDocNav;