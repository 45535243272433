import * as actionTypes from './actionTypes';

export const setUser = (user) => {
    return { 
        type: actionTypes.SET_USER,
        user
    }
}

export const setLoginRedirect = (path) => {
    return {
        type: actionTypes.SET_LOGIN_REDIRECT,
        path
    }
}

export const setInviteRedirect = (path) => {
    return {
        type: actionTypes.SET_INVITE_REDIRECT,
        path
    }
}