import React from 'react';

const VersionSkeleton = ({

}) => {
    return (
        <div 
            className='DocVersion'
            style={{
                marginTop: 32
            }}
        >
            <div 
                className='VersionContext bp3-skeleton skeleton'
                style={{
                    width: '40%'
                }}
            >
                <div className='content-card'>
                    <p className='no-margin font-size-12 font600 ellipsis soft-text'>
                        skeleton
                    </p>
                </div>
            </div>
            <div className='VersionHeader'>
                <h2 className='width-25-percent bp3-skeleton skeleton'>
                    Skeleton Title
                </h2>
                <p className='width-75-percent bp3-skeleton skeleton'>
                    Description of the skeleton
                </p>

                <div className='width-25-percent bp3-skeleton skeleton'>
                    Skeleton text
                </div>
                
            </div>
        
            <div style={{
                maxWidth: 600
            }}>
                <p className='bp3-skeleton skeleton'>
                    Many in the art world are observing the centennial of the Lithuanian-born poet and filmmaker 
                    Jonas Mekas (1922–2019), a founder and icon of avant-garde cinema. Cultural programs, exhibits, and 
                    conferences are marking the occasion. But not everyone is celebrating. In two recent reviews, 
                    historian Michael Casper has made allegations that appear to tarnish Mekas’s legacy. The first, 
                    published in the New York Review of Books (NYRB), took aim at the poet-filmmaker’s diary/memoir 
                    I Had Nowhere To Go. The second attack appeared in Jewish Currents as a review of “The Camera Was 
                    Always Running,” an exhibition of Mekas’s work in New York that concluded in June 2022.
                </p>
                <p className='bp3-skeleton skeleton'>
                    Many in the art world are observing the centennial of the Lithuanian-born poet and filmmaker 
                    Jonas Mekas (1922–2019), a founder and icon of avant-garde cinema. Cultural programs, exhibits, and 
                    conferences are marking the occasion.
                </p>
                <p className='bp3-skeleton skeleton'>
                    Many in the art world are observing the centennial of the Lithuanian-born poet and filmmaker 
                    Jonas Mekas (1922–2019), a founder and icon of avant-garde cinema. Cultural programs, exhibits, and 
                    conferences are marking the occasion. But not everyone is celebrating. In two recent reviews, 
                    historian Michael Casper has made allegations that appear to tarnish Mekas’s legacy. The first, 
                    published in the New York Review of Books (NYRB), took aim at the poet-filmmaker’s diary/memoir 
                    I Had Nowhere To Go.
                </p>
            </div> 
        </div>
    )
}

export default VersionSkeleton;