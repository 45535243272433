import gql from "graphql-tag";

const UPDATE_DOC = gql`
    mutation UpdateDoc($input: UpdateDocInput) {
        updateDoc(input: $input) {
            _id
            docID
            title
            text {
                text
                key
            }
        }
    }
`

export default UPDATE_DOC;