import { useMutation } from '@apollo/client';
import { Button, Card, FormGroup, InputGroup } from '@blueprintjs/core';
import isEmail from "validator/lib/isEmail";
import React, { useState } from 'react';
import GoogleLogin from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import * as actions from '../../store/actions/index';
import GoogleLogo from '../ui/svg/GoogleLogo';
import GOOGLE_SIGN_IN from './mutations/googleSignIn';
import './SignIn.css';
import EMAIL_SIGN_IN from './mutations/emailSignIn';
import CrowdwriteLogo from '../ui/svg/CrowdwriteLogo';
import TextButton from '../ui/TextButton/TextButton';
import { Link } from 'react-router-dom';
import SignInHeader from './SignInHeader/SignInHeader';
import { getUnacceptedInvites } from '../../utils/localStorage';
import { apolloClient } from '../../index';

const SignIn = ({
    toggleSignUp,
    handleClose,
    loginRedirect
}) => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const setUser = (user) => dispatch(actions.setUser(user));

    const { docID } = useParams();
    const unacceptedInvites = getUnacceptedInvites();
    const inviteDocIDs = unacceptedInvites ? unacceptedInvites.map(id => id.split('-')[0]) : [];
    const userHasDocInvite = inviteDocIDs.includes(docID);

    let activeDocInviteID;
    if (userHasDocInvite) {
        activeDocInviteID = unacceptedInvites.map(id => {
            return {
                docID: id.split('-')[0],
                inviteID: id.split('-')[1]
            }
        }).find(idObj => idObj.docID === docID).inviteID;
    }

    const handleToggleSignUp = () => {
        if (toggleSignUp) {
            toggleSignUp();
        }
        else {
            navigate('/sign-up');
        }
    }

    const [emailInput, setEmailInput] = useState('');
    const onChangeEmailInput = (e) => setEmailInput(e.target.value);

    const [passwordInput, setPasswordInput] = useState('');
    const onChangePasswordInput = (e) => setPasswordInput(e.target.value);

    const validEmailSignIn = isEmail(emailInput) && passwordInput !== ``;

    const [signInError, setSignInError] = useState(false);

    const [googleSignIn, { loading: googleSignInLoading }] = useMutation(GOOGLE_SIGN_IN, {
        onCompleted: (data) => {
            if (!data || data.googleSignIn.error) {
                setSignInError(true)
            }

            if (data.googleSignIn) { 
                const { jwt, user } = data.googleSignIn; 
                window.localStorage.setItem(`__stele-token__`, jwt);
                setUser(user);
                if (loginRedirect) navigate(loginRedirect.path);
            }
        }
    });

    const [emailSignIn, { loading: emailSignInLoading }] = useMutation(EMAIL_SIGN_IN, {
        onCompleted: (data) => {
            if (!data || data.emailSignIn.error) {
                setSignInError(true)
            }
            if (data.emailSignIn && data.emailSignIn.jwt) { 

                const { jwt, user } = data.emailSignIn; 
                window.localStorage.setItem(`__stele-token__`, jwt);
                setUser(user);

                if (userHasDocInvite) {
                    dispatch(actions.setInviteRedirect(location.pathname));
                    navigate(`/invite/${activeDocInviteID}`);
                }

                if (loginRedirect) navigate(loginRedirect);
                
                if (handleClose) {
                    apolloClient.refetchQueries({
                        include: "all"
                    })
                    handleClose();
                }
            }
        }
    });

    const _onGoogleSignIn = (res) => {
        googleSignIn({
            variables: {
                id_token: res.tokenObj.id_token,
                // TODO: add support for redirecting to doc id if someone tries to go straight to a doc
            }
        })
    }

    const _onEmailSignIn = (res) => {
        emailSignIn({
            variables: {
                email: emailInput,
                password: passwordInput
            }
        })
    }

    const onKeyDown = (e) => {
        if (e.key === 'Enter' && isEmail(emailInput) && passwordInput.length > 3) {
            _onEmailSignIn();
        }
    }

    const _onGoogleRejection = (err, details) => {
    }

    return (
        <div className='w-96 mx-auto'>
            <SignInHeader />
            {/* <GoogleLogin 
                clientId="512210275616-1t12n26rdgskev45mb76upv2e0m5p2bl.apps.googleusercontent.com"
                buttonText="Sign in with Google"
                onSuccess={_onGoogleSignIn}
                onFailure={_onGoogleRejection}
                cookiePolicy={'single_host_origin'}
                responseType='id_token'
                className='GoogleLogin'
                render={renderProps => {
                    return (
                        <Button fill
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            loading={googleSignInLoading}
                        >
                            <GoogleLogo
                                width={20}
                                top={2}
                                marginRight={7}
                            />
                            <span style={{
                                position: 'relative',
                                fontWeight: 400,
                                fontSize: 16,
                                top: -3
                            }}>
                                Sign in with Google
                            </span>
                        </Button>
                    )
                }}
            /> */}
            {signInError && (
                <Card className='border-0 bg-warning px-1 py-2 my-3 text-center shadow-none text-xs tracking-tight font-semibold'>
                    No user found for those credentials
                </Card>
            )}
            <FormGroup
            >
                <InputGroup 
                    id='email'
                    large
                    aria-label='Enter your email'
                    placeholder='Enter your email'
                    value={emailInput}
                    onChange={onChangeEmailInput}
                    onKeyDown={onKeyDown}
                    type='email'
                />
            </FormGroup>
            <FormGroup
            >
                <InputGroup 
                    id='password'
                    large
                    aria-label='Enter your password'
                    placeholder='Enter your password'
                    value={passwordInput}
                    onChange={onChangePasswordInput}
                    onKeyDown={onKeyDown}
                    type='password'
                />
            </FormGroup>
            <Link to='/forgot' className='text-xs font-semibold hover:no-underline'>
                Forgot password?
            </Link>
            <div className='mt-5'>
                <Button 
                    intent='primary'
                    text='Sign in'
                    disabled={!validEmailSignIn}
                    onClick={_onEmailSignIn}
                    loading={emailSignInLoading}
                />
            </div>
            <div className='mt-8 text-xs text-center'>
                New? <span onClick={handleToggleSignUp}
                    className='font-semibold hover:no-underline cursor-pointer'>
                    Sign up
                </span>
            </div>
        </div>
    )
}

export default SignIn;