import { Tooltip2 } from '@blueprintjs/popover2';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { getFullDate } from '../../../../../../ui/FormattedDate/dateUtils';
import PositionedIcon from '../../../../../../ui/PositionedIcon/PositionedIcon';
import CommentEditor from '../CommentEditor/CommentEditor';
import './BlockFeedback.css';
import Comment from './Comment/Comment';

const BlockFeedback = ({
    comments,
    blockID,
    showingComments,
    toggleComments
}) => {



    const onShowComments = () => toggleComments(true);
    const onHideComments = () => toggleComments(false);
    const onToggleComments = () => {
        toggleComments(!showingComments);
    }

    const dates = comments.map(comment => new Date(comment.created));
    const mostRecent = new Date(Math.max.apply(null,dates));
    const mostRecentDate = new Date(mostRecent);
    const mostRecentDateTime = DateTime.fromJSDate(mostRecentDate);
    const formattedMostRecent = mostRecentDateTime.toRelative();
    const fullDate = getFullDate(mostRecent);

    let commentBlocks;
    if (comments && comments.length > 0) {
        commentBlocks = comments.map((comment, i) => {
            return (
                <Comment
                    key={i}
                    {...comment}
                />
            )
        })
    }

    return (
        <div className={comments.length > 0 ? 'BlockFeedback' : 'BlockFeedback'}>
            {/* {comments && comments.length > 0 && (
                <div className='BlockFeedbackSummary'>
                    <button 
                        className='CommentsToggle'
                        onClick={onToggleComments}
                    >
                        <PositionedIcon
                            icon='chat' 
                            iconSize={11} 
                        /> 
                        <span className='feedback-label'>
                            {comments.length}
                        </span>
                        <span className='date-summary'>
                            {formattedMostRecent}
                        </span>
                    </button>
                </div>
            )} */}
            {showingComments && (
                <div className='Comments'>
                    {commentBlocks}
                </div>
            )}
            {showingComments && (
                <CommentEditor 
                    blockID={blockID}
                    onHideEditor={onHideComments}
                />
            )}
        </div>
    )
}

export default BlockFeedback;