import { useLazyQuery } from '@apollo/client';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link, matchPath, matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import DOC from '../../components/Doc/queries/doc';
import PROPOSAL from '../../components/Proposal/queries/proposal';
import { RealtimeToaster } from '../../components/ui/Toaster/Toaster';

export const websocket = new WebSocket(process.env.REACT_APP_WEBSOCKET_ENDPOINT);

const WebsocketProvider = ({
    children
}) => {

    const [getDoc] = useLazyQuery(DOC, { fetchPolicy: 'network-only' });
    const [getProposal] = useLazyQuery(PROPOSAL, { fetchPolicy: 'network-only' });

    const activeUserId = useSelector(state => state.auth.user._id);
    const location = useLocation();
    const navigate = useNavigate();

    websocket.onopen = (event) => {
    };

    websocket.onmessage = async (event) => {
        const eventData = JSON.parse(event.data);
        const { teamSlug, docID, prID, userId } = eventData.data;

        // don't make further queries if the active user is the one mentioned in the websocket message 
        if (activeUserId === userId) { return null }
        switch (eventData.event) {
            case 'UPDATE_DOC': await getDoc({ variables: { docID: docID, teamSlug: teamSlug } }); break;
            case 'USER_VOTED_ON_PROPOSAL': {
                await getProposal({ variables: { docID: docID, prID: prID } }); 
                if (eventData.data.prStatus === 'ACCEPTED') getDoc({ variables: { docID: docID, teamSlug: teamSlug } });
                break;
            }
            case 'NEW_PROPOSAL': {
                const { username, docTitle, prID, docID } = eventData.data;
                const routes = [{ path: "/t/:teamSlug/*" }];
                const match = matchRoutes(routes, location);
                const { teamSlug } = match[0].params;
                const prPath = `/t/${teamSlug}/pr/${docID}/${prID}`;
                const handleLink = () => navigate(prPath)
                const prLink = (
                    <a href='#' onClick={handleLink}>
                        {username} proposed changes to {docTitle}
                    </a>
                );
                RealtimeToaster.show({
                    message: prLink
                });
                break;
            }
        }
    }

    return (
        <Fragment>
            {children}
        </Fragment>
    );
}

export default WebsocketProvider;