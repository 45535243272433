import React from 'react';
import { Link } from 'react-router-dom';
import PositionedIcon from '../../../ui/PositionedIcon/PositionedIcon';
import StatusTag from '../../../ui/StatusTag/StatusTag';
import ProposalTag from '../ProposalTag/ProposalTag';

const AcceptProposalEvent = ({
    username,
    approver,
    date,
    doc,
    proposal,
    proposer,
    context
}) => {

    let text;
    if (context === 'GLOBAL_FEED' || !approver) {
        text = (
            <p>
                <Link to={`/user/${proposer.username}`} className='font500'>{proposer.username}'s</Link> suggestion was accepted
                <span className='margin-left-8'>
                    <ProposalTag
                        link={`/${doc.team}/${doc.docID}/suggestions/${proposal._id}/changes`}
                        title={proposal.title}
                        status={proposal.status}
                    />
                </span>
            </p>
        )
    }

    else if (approver) {
        text = (
            <p>
                <span className='font-size-12'>
                    {context === 'GLOBAL_FEED' && (
                        <Link to={`/user/${approver.username}`} className='font500'>{approver.username}</Link>
                    )}
                    <span> approved </span>
                    <Link to={`/user/${proposer.username}`} className='font500'>
                        {proposer.username}'s
                    </Link> suggestion 
                </span>
                <span className='margin-left-8'>
                    <ProposalTag
                        link={`/${doc.team}/${doc.docID}/suggestions/${proposal._id}/changes`}
                        title={proposal.title}
                        status={proposal.status}
                    />
                </span>
            </p>
        )
    }

    return (
        <div className='EventBlock'>
            <div className='EventIcon'>
                <PositionedIcon icon='data-lineage' top={1} left={2} iconSize={11} />
            </div>
            <div className='EventInfo'>
                {text}
            </div>
        </div>
    )
}

export default AcceptProposalEvent;
