import React, { useEffect, useRef, useState } from 'react';
import { convertFromRaw } from 'draft-js'
import { convertToHTML } from 'draft-convert';
import TextBlockMenu from './TextBlockMenu/TextBlockMenu';
import parse, { attributesToProps, domToReact } from 'html-react-parser';
import { addStartToOrderedList } from './textBlockUtils/listItemUtils';
import './TextBlock.css';
import BlockReactions from './BlockReactions/BlockReactions';
import { getEntityMapFromArray } from '../DocEditorModal/entityUtils';
import ThreadSidebar from './ThreadSidebar/ThreadSidebar';
import ProposalsSidebar from './ProposalsSidebar/ProposalsSidebar';
import { Button, ButtonGroup, Divider } from '@blueprintjs/core';

function findTextNode(node, offset) {
    if (node.nodeType === Node.TEXT_NODE) {
      return node;
    }
    
    const walker = document.createTreeWalker(node, NodeFilter.SHOW_TEXT, null, false);
    let currentNode = walker.nextNode();
    let currentOffset = 0;
  
    while (currentNode) {
      if (currentOffset + currentNode.length > offset) {
        return currentNode;
      }
      currentOffset += currentNode.length;
      currentNode = walker.nextNode();
    }
  
    return null;
}

const getHighlightComments = (threads, text) => {
    if (!threads || threads.length === 0) {
        return [];
    }

    let comments = threads.map(thread => {
        return {
            id: thread._id,
            start: thread.highlightStart,
            end: thread.highlightEnd,
            text: text.substring(thread.highlightStart, thread.highlightEnd)
        }
    });

    return comments;
}

function getTextNodeOffset(parentNode, targetNode) {
    let offset = 0;
    const walker = document.createTreeWalker(parentNode, NodeFilter.SHOW_TEXT, null, false);
    let currentNode = walker.nextNode();
  
    while (currentNode && currentNode !== targetNode) {
      offset += currentNode.length;
      currentNode = walker.nextNode();
    }
  
    return offset;
}

function getInnerTextNode(node) {
    // If the node is a text node, return it
    if (node.nodeType === Node.TEXT_NODE) {
      return node;
    }
  
    // If it's an element node, search for the first text node
    if (node.nodeType === Node.ELEMENT_NODE) {
      // Use a TreeWalker to efficiently traverse the DOM tree
      const walker = document.createTreeWalker(
        node,
        NodeFilter.SHOW_TEXT,
        null,
        false
      );
  
      // Get the first text node
      const textNode = walker.nextNode();
  
      // If a text node is found, return it
      if (textNode) {
        return textNode;
      }
    }
  
    // If no text node is found, return null
    return null;
}

const TextBlock = ({
    _id,
    blockKey,
    depth,
    type,
    text,
    listItemIndex,
    inlineStyleRanges,
    showEditModal,
    canParticipate,
    showAddComment,
    hideAddComment,
    showEmojiPalette,
    hideEmojiPalette,
    showingEmojiPalette,
    docID,
    threads,
    proposals,
    reactions,
    entityRanges,
    entities,
    setOpenThreadContext,
    threadIsOpen,
    onShowBlockSuggestions,
}) => {

    const rawContent = {
        blocks: [{
            key: blockKey,
            depth: depth,
            type: type,
            text: text,
            inlineStyleRanges: inlineStyleRanges,
            entityRanges: entityRanges
        }], 
        entityMap: getEntityMapFromArray(entities)
    }

    const [selection, setSelection] = useState(null);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const [comments, setComments] = useState(() => getHighlightComments(threads, text));
    const [highlights, setHighlights] = useState([]);
    const containerRef = useRef(null);
    const textRef = useRef(null);
    const isMouseDownRef = useRef(false);


    useEffect(() => {
        setComments(getHighlightComments(threads, text));
    }, [threads])

    useEffect(() => {
        const updateHighlights = () => {
            if (!textRef.current) return;
      
            const newHighlights = comments.flatMap((comment) => {
                const range = document.createRange();
                const startNode = findTextNode(textRef.current, comment.start);
                const endNode = findTextNode(textRef.current, comment.end);
        
                if (!startNode || !endNode) return [];
        
                range.setStart(startNode, comment.start - getTextNodeOffset(textRef.current, startNode));
                range.setEnd(endNode, comment.end - getTextNodeOffset(textRef.current, endNode));
        
                const rects = Array.from(range.getClientRects());
                const containerRect = containerRef.current.getBoundingClientRect();
        
                return rects.map((rect, index) => ({
                        id: `${comment.id}`,
                        style: {
                            position: 'absolute',
                            left: `${rect.left - containerRect.left}px`,
                            top: `${rect.top - containerRect.top}px`,
                            width: `${rect.width}px`,
                            height: `${rect.height}px`,
                        },
                        comment: comment.comment,
                }));
            });
      
            setHighlights(newHighlights);
        };
    
        // Use requestAnimationFrame to ensure the DOM has been painted
        requestAnimationFrame(updateHighlights);
    
        // Add resize listener to handle window size changes
        window.addEventListener('resize', updateHighlights);
        return () => window.removeEventListener('resize', updateHighlights);
    }, [comments, text]);
    
    useEffect(() => {
        const handleSelection = () => {
          if (isMouseDownRef.current) return;
          const selectionObj = window.getSelection();
          const selectedText = selectionObj.toString().trim();
    
          if (selectedText && textRef.current && textRef.current.contains(selectionObj.anchorNode) && !selectedText.includes(`\n`)) {
            const range = selectionObj.getRangeAt(0);
            const preSelectionRange = range.cloneRange();
            preSelectionRange.selectNodeContents(textRef.current);
            preSelectionRange.setEnd(range.startContainer, range.startOffset);
            const start = preSelectionRange.toString().length;
    
            setSelection({
              text: selectedText,
              start: start,
              end: start + selectedText.length,
            });    
            const rect = range.getBoundingClientRect();
            const containerRect = containerRef.current.getBoundingClientRect();
            setTooltipPosition({
              x: rect.left - containerRect.left + rect.width / 2,
              y: rect.top - containerRect.top,
            });
          } else {
            setSelection(null);
          }
        };
    
        const handleMouseDown = (e) => {
          if (textRef.current.contains(e.target)) {
            isMouseDownRef.current = true;
            setSelection(null);
          }
        };
    
        const handleMouseUp = () => {
          isMouseDownRef.current = false;
        };
    
        document.addEventListener('mouseup', handleSelection);
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('selectionchange', handleSelection);
        
        return () => {
          document.removeEventListener('mousedown', handleMouseDown);
          document.removeEventListener('mouseup', handleMouseUp);
          document.removeEventListener('selectionchange', handleSelection);
        };
    
    }, []);
    
    const handleAddComment = () => {
        if (selection) {
          const newComment = {
            id: Date.now(),
            start: selection.start,
            end: selection.end,
            text: selection.text,
            comment: 'New comment',
          };
          setComments([...comments, newComment]);
          setSelection(null);
        }
    };

    const onOpenSuggestions = () => {
        onShowBlockSuggestions(blockKey)
    }

    const contentState = convertFromRaw(rawContent);
    const htmlContent = convertToHTML({
        entityToHTML: (entity, originalText) => {
            if (entity.type === 'LINK') {
              return <a href={entity.data.url} target="_blank">{originalText}</a>;
            }   
            return originalText;
        }
    })(contentState);
    
    let richText;
    if (type !== `ordered-list-item`) richText = parse(htmlContent);
    else if (type === `ordered-list-item`) {
        richText = parse(htmlContent, {
            replace: (domNode) => addStartToOrderedList(domNode, depth, listItemIndex, attributesToProps, domToReact)
        });
    }

    const handleShowEditModal = () => {
        showEditModal(blockKey)
    }

    const onShowAddComment = (e) => {
        // const blockHasComments = threads && threads.length > 0 ? true : false;
        // if (blockHasComments) {
        //     setOpenThreadContext({
        //         blockID: _id,
        //         x: e.clientX,
        //         y: e.clientY,
        //     });
        // } 
        // else {
        showAddComment(e, _id, selection.start, selection.end);
        // }
    }

    if (highlights && highlights.length > 0) {
    }

    const highlightBlocks = highlights.map((highlight, i) => {
        const onClickHighlight = (e) => {
            setOpenThreadContext({
                threadID: highlight.id,
                x: e.clientX,
                y: e.clientY
            })
        }
        return (
            <div
                key={i}
                className='highlight'
                style={highlight.style}
                title={highlight.comment}
                onClick={onClickHighlight}
            />
        )
    })
    

    return (
        <div 
            className={`TextBlock ${type} flex gap-1`}
            id={_id}
        >
            <div
                ref={containerRef} style={{ position: 'relative' }}
            >
                <div 
                    className='sm:w-[672px] sm-max-w-[672px] sm-min-w-[672px]'
                    ref={textRef} style={{ position: 'relative', zIndex: 1 }}
                >
                    {richText}
                </div>
                <div className='highlights'>
                    {highlightBlocks}
                </div>
                {selection && canParticipate && (
                    <div
                        className='shadow'
                        style={{
                            position: 'absolute',
                            left: `${tooltipPosition.x}px`,
                            top: `${tooltipPosition.y}px`,
                            transform: 'translate(-50%, -100%)',
                            backgroundColor: '#333',
                            color: 'white',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            zIndex: 2,
                        }}
                    >
                        <ButtonGroup className='bp3-intent-dark'>
                            <Button 
                                intent='dark'
                                icon='lightbulb'
                                text={'Suggest changes'}
                                onClick={handleShowEditModal}
                            />
                            <Button 
                                intent='dark'
                                icon='chat'
                                text={'Comment'}
                                onClick={onShowAddComment}
                            />
                        </ButtonGroup>
                    </div>
                )}
            </div>
            {threads && threads.length > 0 ? (
                <ThreadSidebar
                    threads={threads}
                    docID={docID}
                    threadIsOpen={threadIsOpen}
                    setOpenThreadContext={setOpenThreadContext}
                />
            ) : (
                <div style={{ width: 22, minWidth: 22 }}></div>
            )}
            {proposals && proposals.length > 0 && (
                <ProposalsSidebar
                    proposals={proposals}
                    onOpenSuggestions={onOpenSuggestions}
                />
            )}
            {reactions && reactions.length > 0 && (
                <BlockReactions reactions={reactions} />
            )}
        </div>

    );
}

export default React.memo(TextBlock);