import React, { Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import DateWithTooltip from '../../../ui/DateWithTooltip/DateWithTooltip';
import { getFullDate, getReadableDate, getReadableShortDate } from '../../../ui/FormattedDate/dateUtils';
import PositionedIcon from '../../../ui/PositionedIcon/PositionedIcon';
import VotingSummary from '../../../ui/VotingSummary/VotingSummary';

const HistoryCard = ({
    _id,
    changeMessage,
    changeDescription,
    created,
    creator,
    prID,
    version,
    proposal
}) => {

    const { teamSlug, docID } = useParams();
    const createdString = getReadableDate(created);

    return (
        <Link
            className='no-deco'
            to={`/${teamSlug}/${docID}/history/${version}`}
        >
            <div className='content-card'>
                {/* Special handling for first firstion */}
                {version === 1 && (
                    <h3>
                        <PositionedIcon 
                            icon='clean'
                            marginRight={8}
                        /> 
                        {creator.username} started the document
                    </h3>
                )}   
                <p className='ellipsis width-75-percent font-size-12'>
                    {changeMessage ? changeMessage : <span className='half-opaque'>Untitled change</span>}
                </p>   
                {changeDescription && changeDescription !== '' && (
                    <p className='ellipsis soft-text width-75-percent font-size-12'>
                        {changeDescription}
                    </p>
                )}      
                <p>
                    {/* {proposal && proposal.votes && (
                        <span className='font-size-12'>
                            <PositionedIcon 
                                icon='thumbs-up'
                                iconSize={12} 
                            /> {proposal.votes.for} 
                            <PositionedIcon 
                                icon='thumbs-down'
                                iconSize={12} 
                                marginLeft={8}
                            /> {proposal.votes.against}
                        </span>
                    )} */}
                    {version !== 1 && (
                        <span className='margin-right-8 font-size-12'>
                            {proposal ? proposal.author.username : creator.username} 
                        </span>
                    )}
                    {proposal && !proposal.acceptedBy && (
                        <span className='font-size-12 half-opaque margin-right-4'>
                            accepted
                        </span>
                    )}
                    {proposal && proposal.acceptedBy && proposal.acceptedBy.username && (
                        <span className='font-size-12 half-opaque margin-right-4'>
                            approved by <span className='font600'>
                                {proposal.acceptedBy.username}
                            </span>
                        </span>
                    )}
                    <span className='font-size-12 half-opaque margin-right-4'>
                        {createdString}
                    </span>
                </p>
                {proposal && proposal.voters && <VotingSummary voters={proposal.voters} />}
            </div>
        </Link>
    )
}

export default HistoryCard;