export const getProcessInput = (protocol, supportNeeded, approvers, approvalCount, currentUserId) => {
    
    let processControl;
    
    if (protocol === 'OPEN') {
        processControl = {
            processType: 'OPEN'
        }
    }
    if (protocol === 'VOTING') {
        processControl = {
            processType: 'VOTING',
            supportNeeded: supportNeeded  
        }
    }
    if (protocol === 'APPROVALS') {
        const approverStrings = approvers.map((approver) => approver._id ? approver._id : approver.username);
        processControl = {
            processType: 'APPROVALS',
            approvalCount: approvalCount,
            approvers: approverStrings
        }
    }

    const processInput = {
        controls: [processControl],
        operator: 'AND',
        creatorCanEdit: true,
        creatorCanChangeSettings: true,
    }

    return processInput;

}