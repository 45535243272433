import gql from "graphql-tag";

const CREATE_PROPOSAL = gql`
    mutation CreateProposal($input: CreateProposalInput) {
        createProposal(input: $input) {
            _id
            docID
            title
            number
            text {
                text
                key
            }
        }
    }
`

export default CREATE_PROPOSAL;