import React, { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';
import { MultiSelect } from '@blueprintjs/select';
import Avatar from '../Avatar/Avatar';
import { Button, FormGroup, Icon } from '@blueprintjs/core';
import { addToArray, removeArrayItem } from '../../../store/utility';
import { useLazyQuery } from '@apollo/client';
import GET_USERS from './queries/getUsers';
import './UserMultiSelect.css';
import validator from 'validator';

const UserMultiSelect = ({
    label,
    labelFor,
    actionLabel,
    selectedUsers,
    setSelectedUsers,
    onAction
}) => {

    const [getUsers, { loading, error, data, previousData }] = useLazyQuery(GET_USERS);
    const debouncedGetUsers = useCallback(_.debounce(getUsers, 500), []);

    const [query, setQuery] = useState('');

    const onSelectUser = (user) => {
        const userToAdd = {
            _id: user._id,
            username: user.username,
            profilePicPath: user.profilePicPath
        }
        const newSelectedUsers = addToArray(selectedUsers, [userToAdd]);
        setSelectedUsers(newSelectedUsers);
    }

    const onChangeQuery = (query) => {

        setQuery(query.toLowerCase());
        if (query.length >= 3) {
            debouncedGetUsers({ 
                variables: {
                    query: query.toLowerCase()
                }
            })
        }

        // attempt to support comma-based user selection (depends on whether data is available)
        const queryUser = query.substring(0, query.length-1);

        if (!validator.isEmail(queryUser) && query[query.length-1] === ',') {
            const availableData = data ? data : previousData ? previousData : null;
            
            // if there's no data, or the username isn't in the data, make an invalid user tag
            if (!availableData || !availableData.users.find(u => u.username === queryUser)) {
                const userToAdd = {
                    username: queryUser,
                    valid: false
                }
                const newSelectedUsers = addToArray(selectedUsers, [userToAdd]);
                setSelectedUsers(newSelectedUsers);
                setQuery('');
            }

            // if the username is in the data, add them to the selected users list
            const foundUser = availableData && availableData.users ? availableData.users.find(u => u.username === queryUser) : null;
            
            if (foundUser) {
                const userToAdd = {
                    _id: foundUser._id,
                    username: foundUser.username,
                    profilePicPath: foundUser.profilePicPath
                }
                const newSelectedUsers = addToArray(selectedUsers, [userToAdd]);
                setSelectedUsers(newSelectedUsers);
                setQuery('');
            }

        }

        // add emails to the selected user list when a comma is used
        if (validator.isEmail(queryUser) && query[query.length-1] === ',') {
            const userToAdd = {
                _id: queryUser,
                username: queryUser,
                email: queryUser
            }
            const newSelectedUsers = addToArray(selectedUsers, [userToAdd]);
            setSelectedUsers(newSelectedUsers);
            setQuery('');
        }
    }

    const onRemoveUser = (user) => {
        const userToRemoveIndex = selectedUsers.findIndex(u => u.username === user.username);
        const newSelectedUsers = removeArrayItem(selectedUsers, userToRemoveIndex);
        setSelectedUsers(newSelectedUsers);
    }

    const itemRenderer = (user, itemProps) => {
        let classes = [`UserListItem`, `select-list-item`, `flex`, `gap-2`, `align-center`]
        if (itemProps.modifiers.active) {
            classes.push('active');
        }

        // no need to show selected users in the list — filter them out
        if (selectedUsers.map(u => u.username).includes(user.username)) {
            return null;
        }

        return (
            <div 
                className={classes.join(' ')}
                onClick={itemProps.handleClick}
                key={user._id}
            >
                <Avatar username={user.username} />
                {user.username}
            </div>
        );
    }

    const userListFilter = (query, item) => {
        const usernameMatches = item.username.toLowerCase().includes(query.toLowerCase());
        if (usernameMatches) {
            return true;
        }
        const nameMatches = item.name && item.name.toLowerCase().includes(query.toLowerCase());
        if (nameMatches) {
            return true;
        }
        return false;
    }

    const onItemSelect = (item, event) => {
        onSelectUser(item);
    }

    const onRemoveItem = (item) => {
        onRemoveUser(item);
    }

    const tagRenderer = (user, i) => {
        const classes = ['UserTag'];
        if (user.valid === false || (!user._id && !validator.isEmail(user.username))) {
            classes.push('invalid')
        }

        return (
            <div className={classes.join(' ')} key={i}>
                {user.username}
            </div>
        );
    }

    const createNewItemFromQuery = (query) => {

        const availableData = data ? data : previousData ? previousData : null;
            
        // if there's no data, or the username isn't in the data, make an invalid user tag
        if (!availableData || !availableData.users.find(u => u.username === query)) {
           return {
                username: query,
                valid: false
            }
        }

        // if the username is in the data, add them to the selected users list
        const foundUser = availableData && availableData.users ? availableData.users.find(u => u.username === query) : null;
        if (foundUser) {
            return {
                _id: foundUser._id,
                username: foundUser.username,
                profilePicPath: foundUser.profilePicPath
            }
        }

        else {
            return {
                username: query,
                valid: false
            }
        }

    }

    const createNewItemRenderer = (query, active, handleClick) => {
        
        // don't show the invite prompt unless the query is an email
        if (!validator.isEmail(query) || selectedUsers.map(u => u.username).includes(query)) {
            return null;
        }

        const onInviteNewUser = () => {
            onSelectUser({
                username: query,
                email: query
            })
            setQuery('');
        }

        let classes = [`UserListItem`, `select-list-item`, `flex`, `gap-2`, `align-center`]
        if (active) {
            classes.push('active');
        }


        return (
            <div 
                className={classes.join(' ')}
                onClick={onInviteNewUser}
            >
                <Icon icon='send-message' size={8}/>
                invite {query}
            </div>
        );
    }


    let userList = [];
    if (data) {
        userList = data.users.filter(u => {
            return !selectedUsers.map(selectedUser => selectedUser.username).includes(u.username)
        });
    }
    if (loading && previousData) {
        userList = previousData.users.filter(u => {
            return !selectedUsers.map(selectedUser => selectedUser.username).includes(u.username)
        });
    }

    const listHasUsers = data && data.users.length > 0 || previousData && previousData.users.length > 0;
    const popoverProps = {
        position: 'bottom', 
        fill: true, 
        transitionDuration: 0,
        minimal: true,
        portalClassName: 'z-300',
        popoverClassName: query.length > 3 || listHasUsers || validator.isEmail(query) ? 'UserMultiSelectList' : 'UserMultiSelectList hidden'
    }

    // add invalid intent for invalid tags
    const tagProps = (val) => {
        if (val.props.className.includes('invalid')) {
            return {
                ...val.props,
                intent: 'danger'
            }
        }
        else {
            return val.props;
        }
    }

    return (
        // <FormGroup
        //     label={label}
        //     labelFor={labelFor}
        // >
            <div className='flex align-center space-between gap-2 z-30'>
                <MultiSelect
                    placeholder='Enter usernames or emails, comma separated'
                    id={labelFor}
                    items={userList}
                    itemRenderer={itemRenderer}
                    onItemSelect={onItemSelect}
                    onRemove={onRemoveItem}
                    tagRenderer={tagRenderer}
                    popoverProps={popoverProps}
                    scrollToActiveItem={true}
                    selectedItems={selectedUsers}
                    itemPredicate={userListFilter}
                    resetOnSelect={true}
                    resetOnQuery={false}
                    query={query}
                    onQueryChange={onChangeQuery}
                    createNewItemFromQuery={createNewItemFromQuery}
                    createNewItemRenderer={createNewItemRenderer}
                    tagInputProps={{ tagProps: tagProps }}
                    fill
                />
                <Button intent='primary'
                    text={actionLabel}
                    onClick={onAction}
                    disabled={selectedUsers.length === 0}
                />
            </div>
        // </FormGroup>

    )
}

export default UserMultiSelect;