import React from 'react';
import { Divider, NumericInput } from '@blueprintjs/core';

const VotingEditor = ({
    editableSupportNeeded,
    onChangeSupportNeeded,
    isOpen
}) => {

    if (!isOpen) {
        return null;
    }

    return (
        <div>
            <div className='modal-padded-section'>
                <p className='font-size-12 font600'>Voting</p>
                <div className='flex align-center gap-2 margin-bottom-16'>
                    <span className='font-size-12'>Any suggestion needs</span>
                    <NumericInput
                        value={editableSupportNeeded}
                        onValueChange={onChangeSupportNeeded}
                        buttonPosition='none'
                        className='inline-numeric 2digit'
                    />
                    <span>more upvotes than downvotes</span>
                </div>
            </div>
            <Divider />
        </div>
    )
}

export default VotingEditor;