import React from 'react';
import emoji from 'node-emoji';
import { Button } from '@blueprintjs/core';
import './BlockReactions.css';

const BlockReactions = ({
    reactions 
}) => {

    const stickers = reactions.map((r, i) => {
        const stickerEmoji = emoji.get(r.emoji);
        return (
            <Button minimal small
                key={i}
                className='ReactionSticker'
                text={`${stickerEmoji} ${r.count}`}
            />
        )
    })

    return (
        <div className='BlockReactions'>
            {stickers}
        </div>
    );
}

export default BlockReactions;