import React from 'react';
import { Link } from 'react-router-dom';
import PositionedIcon from '../../../ui/PositionedIcon/PositionedIcon';
import StatusTag from '../../../ui/StatusTag/StatusTag';

const VoteEvent = ({
    user,
    date,
    proposal,
    vote
}) => {

    let text = (
        <p>Voted on <Link to={`/user/${proposal.author.username}`} className='font600'>
                {proposal.author.username}'s
            </Link> suggestion <Link 
                to={`/${proposal.doc.team}/${proposal.doc.docID}/suggestions/${proposal._id}/changes`}
                className='half-opaque'
            >
                (#{proposal.number}: {proposal.doc.title})
            </Link> 
        </p>
    )

    return (
        <div className='EventBlock'>
            <div className='EventIcon'>
                <PositionedIcon icon={vote.stance === 'FOR' ? 'caret-up' : 'caret-down'} top={3} iconSize={15} />
            </div>
            <div className='EventInfo font-size-12'>
                {text}
            </div>
        </div>
    )
}

export default VoteEvent;
