import { useQuery } from '@apollo/client';
import React from 'react';
import SnippetTopMenu from '../SnippetEditor/SnippetTopMenu/SnippetTopMenu';
import EXPLORE_DOCS from './queries/exploreDocs';

const Explore = ({

}) => {

    const { loading, error, data } = useQuery(EXPLORE_DOCS, {
        variables: {
            offset: 0,
            limit: 20
        }
    });

    if (error) return 'error';
    if (loading) return null;

    return (
        <div className='Explore'>
            <SnippetTopMenu />
        </div>
    )
}

export default Explore;