import { gql } from "@apollo/client";

const DOC_COMMENTS = gql`
    query DocComments($docID: String, $offset: Int, $limit: Int) {
        doc(docID: $docID) {
            _id
            docID
            comments(docID: $docID, offset: $offset, limit: $limit) {
                _id
                parentID
                blockID
                author {
                    username
                    _id
                }
                created
                edited
                text
                replies
                lastReplyDate
                referenceText
                lastReplyUser {
                    _id
                    username
                }
            }
        }
    }
`

export default DOC_COMMENTS;