import React, { Fragment, useState } from 'react';
import { Button, Callout, Icon, Spinner } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import './Participate.css';
import PositionedIcon from '../../ui/PositionedIcon/PositionedIcon';
import LinkButton from '../../ui/LinkButton/LinkButton';
import { apolloCache } from '../../..';
import { gql } from '@apollo/client';
import getCachedVote from '../../../cache/readUtils/getCachedVote';
import { PRIMARY_COLOUR } from '../../ui/colours/colours';
import SignUpModal from '../../SignUpModal/SignUpModal';
import SignInPrompter from '../../ui/SignInPrompter/SignInPrompter';
import { DANGER, NEUTRAL_DARK, NEUTRAL_LIGHT, SUCCESS } from '../../../utils/colors';

const getVoteVerb = (stance, status) => {
    if (stance === 'FOR' && status === 'ACTIVE') return ' support';
    if (stance === 'FOR' && status !== 'ACTIVE') return ' supported';
    if (stance === 'AGAINST' && status === 'ACTIVE') return `'re against`;
    if (stance === 'AGAINST' && status !== 'ACTIVE') return 'opposed';
}

const Participate = ({
    createVote,
    deleteVote,
    userVote,
    voteIsLoading,
    deleteVoteIsLoading,
    prStatus,
    prID
}) => {

    const userId = useSelector(state => state.auth.user._id);


    const createSupportVote = () => {
        createVote('FOR');
    }
    
    const createOpposeVote = () => {
        createVote('AGAINST');
    }


    const cachedVote = getCachedVote(apolloCache, prID, userId);
    const userHasVoted = cachedVote && cachedVote.stance !== 'NO_VOTE';
    
    let userStance;
    if (userHasVoted) userStance = cachedVote.stance;
    const voteVerb = getVoteVerb(userStance, prStatus);
    
    const revokeSupportAction = (
        <div className='PostParticipate'>
            <div className='flex gap-4 items-center'>
                <div>
                    <PositionedIcon 
                        iconSize={24}
                        opacity={1}
                        color={userStance === 'FOR' ? NEUTRAL_DARK : NEUTRAL_LIGHT} 
                        icon={userStance === 'FOR' ? 'thumbs-up' : 'thumbs-down'} 
                    />
                </div>
                <div>
                    <p className='PostParticipateText font-semibold tracking-tight leading-none'>
                        You{voteVerb} this
                    </p>
                    {prStatus === 'ACTIVE' && (
                        <div className='font-medium tracking-tight'>
                            <LinkButton small
                                text='Undo'
                                onClick={() => deleteVote(userStance)}
                            />
                        </div>
                    )}
                </div>
            </div>

           
        </div>
    )

    const supportAction = (
        <div className='SupportAction'>
            <p className='font-bold tracking-tight'>Participate</p>
            <div className='flex gap-1 mt-1 mb-1'>
                <SignInPrompter>
                    <Button fill
                        icon='thumbs-up'
                        intent='neutral-dark'
                        onClick={createSupportVote} 
                        disabled={voteIsLoading} 
                    />
                </SignInPrompter>
                <SignInPrompter>
                    <Button fill
                        icon='thumbs-down'
                        intent='neutral-light'
                        onClick={createOpposeVote} 
                        disabled={voteIsLoading}
                    />
                </SignInPrompter>
            </div>
        </div>
    )

    return (
        <div className='Participate'>
            {userHasVoted ? revokeSupportAction : prStatus === 'ACTIVE' && supportAction}
        </div>
    )
}

export default Participate;