import { gql } from "@apollo/client";

const VERIFY_ACCOUNT = gql`
    mutation VerifyAccount($input: VerifyAccountInput) {
        verifyAccount(input: $input) {
            user {
                _id
                username
            }
            jwt
            error
        }
    }
`

export default VERIFY_ACCOUNT;