import { gql } from "@apollo/client";

const EXPLORE_DOCS = gql`
    query ExploreDocs($offset: Int, $limit: Int) {
        docs(offset: $offset, limit: $limit) {
            title
            created
            docID
            lastEdited
            lastEditor {
                _id
                username
            }
            creator {
                _id
                username
            }
        }
    }
`

export default EXPLORE_DOCS;