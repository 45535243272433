import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache }  from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { localStorageKey } from './utils/authClient';
import { cacheOptions } from './cache/cacheOptions';

import { Provider } from 'react-redux';
import store from './store/store';

import { AuthProvider } from './context/AuthContext';
import WebsocketProvider from './context/WebsocketProvider/WebsocketProvider';

import './index.css';
import 'draft-js/dist/Draft.css';
import "react-datepicker/dist/react-datepicker.css";
import './bp3-overrides.css';
import './react-datepicker-overrides.css';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(localStorageKey);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
}); 

export const apolloCache = new InMemoryCache(cacheOptions);

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: apolloCache
});

ReactDOM.render(
  <Router>
      <ApolloProvider client={apolloClient}>
        <Provider store={store}>
          <App />
        </Provider>
      </ApolloProvider>
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
