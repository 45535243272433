import { useSelector } from "react-redux";

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    }
};

export const addToArray = (oldArray, newElements) => {
    return [
        ...oldArray,
        ...newElements
    ]
};

export const updateArrayItem = (oldArray, index, updatedElement) => {
    return [
        ...oldArray.slice(0, index),
        updatedElement,
        ...oldArray.slice(index+1)
    ]
}

export const removeArrayItem = (oldArray, index) => {
    return [
        ...oldArray.slice(0, index),
        ...oldArray.slice(index+1)
    ]
}