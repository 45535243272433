import { gql } from "@apollo/client";

const EDIT_COMMENT = gql`
    mutation EditComment($input: EditCommentInput) {
        editComment(input: $input) {
            _id
            author {
                _id
                username
            }
            text
            created
            edited
            blockID
            prID
            docID
        }
    }
`

export default EDIT_COMMENT;