import React from 'react';
import { Link } from 'react-router-dom';
import ProfilePic from '../../../UserProfile/ProfilePic/ProfilePic';

const UserHeader = ({
    username,
    name,
    profilePicPath
}) => {

    return (
        <div className='flex gap-4 items-center mb-16 -mt-8'>
            <Link to={`/user/${username}`}>
                <ProfilePic
                    profilePicPath={profilePicPath}
                    size='md'
                />
            </Link>
            <Link to={`/user/${username}`} className='text-xl font-semibold hover:no-underline'>
                {name}
            </Link>
        </div>
    )

}

export default UserHeader;