import React from 'react';
import { Tooltip2 } from '@blueprintjs/popover2';
import { getFullDate } from '../FormattedDate/dateUtils';

const DateWithTooltip = ({
    date,
    text,
    noStyle
}) => {

    const fullDate = getFullDate(date)

    return (
        <Tooltip2
            content={fullDate}
            minimal
            popoverClassName='small-tooltip'
            className='small-tooltip'
            placement={'top'}
        >
            <span className={noStyle ? '' : 'font-size-12 half-opaque'}>
                {text}
            </span>
        </Tooltip2>
    )
}

export default DateWithTooltip;