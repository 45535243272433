import React from 'react';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import './DocIndexItem.css';

const DocIndexItem = ({
    teamSlug,
    docID,
    title,
    created
}) => {

    const createdDate = DateTime.fromISO(created);
    const formattedDate = createdDate.toLocaleString(DateTime.DATE_MED);
    return (
        <Link to={`/${teamSlug}/${docID}`} className='no-deco'>
            <div
                className='border border-gray-200 rounded-xl shadow-sm px-6 pt-4 pb-5 hover:border-gray-300 hover:bg-gray-50 transition' 
            >
                <h3 className='text-lg m-0 truncate ellipsis'>
                    {title}
                </h3>
                <p className='bp3-text-muted text-xs font-medium'>Last edited {formattedDate}</p>
            </div>
        </Link>
    );

}

export default DocIndexItem;