import { gql } from "@apollo/client";

const ACCEPT_INVITE = gql`
    mutation AcceptInvite($inviteID: String) {
        acceptInvite(inviteID: $inviteID) {
            docID
            team
            userAccess
        }
    }
`

export default ACCEPT_INVITE;