import React from 'react';
import { Button, Callout } from '@blueprintjs/core';
import DocTextDiff from '../../../Doc/DocProposals/ProposalModal/DocTextDiff/DocTextDiff';
import Participate from '../../../Proposal/Participate/Participate';
import { useParams } from 'react-router';
import CurrentResults from '../../../Proposal/CurrentResults/CurrentResults';
import StringDiff from '../../../Doc/DocProposals/ProposalModal/DocTextDiff/BlockDiff/StringDiff/StringDiff';
import CommentEditor from '../../../Doc/DocProposals/ProposalModal/DocTextDiff/BlockDiff/CommentEditor/CommentEditor';
import { Link } from 'react-router-dom';
import Comment from '../../../Doc/DocProposals/ProposalModal/DocTextDiff/BlockDiff/BlockFeedback/Comment/Comment';
import ProposalComments from '../ProposalComments/ProposalComments';

const ProposalChanges = ({
    status,
    diff,
    changes,
    docTitleDiff,
    comments,
    totalComments,
    prID,
    entities,
    voters,
    onCreateVote,
    onDeleteVote,
    voteIsLoading,
    deleteVoteIsLoading,
    canApprove,
    canClose,
    user,
    doc,
    acceptingProposal,
    onAcceptProposal,
    closingProposal,
    onCloseProposal,
    votes,
    canParticipate
}) => {

    const diffContent = status === 'ACTIVE' ? diff : changes;
    const hasConflictBlocks = status === 'ACTIVE' && diffContent.map(b => b.diffStatus).includes(`CONFLICT`);

    let titleDiff;
    if (docTitleDiff && docTitleDiff.length > 1) {
        titleDiff = <StringDiff parts={docTitleDiff} />
    }

    const commentBlocks = comments.map((comment, i) => {
        return <Comment key={i} {...comment} />
    }) 

    return (
        <div className='SnippetDocTextContainer'>
            <div className='SnippetDocText flex-grow'>
                <div 
                    className='ProposalDiff'
                >
                    {hasConflictBlocks && (
                        <Callout
                            title='This proposal is out of date'
                            intent='warning'
                            style={{ marginBottom: 20 }}
                        >
                        </Callout>
                    )}
                    {titleDiff && (
                        <h1 className='margin-bottom-24 soft-border-dotted-bottom padding-bottom-24 padded-block-sides'>
                            {titleDiff}
                        </h1>
                    )}
                    <DocTextDiff 
                        diffData={diffContent} 
                        comments={comments}
                        entities={entities}
                    />
                </div>
                {totalComments === 0 && !canParticipate ? null : (
                    <div className='margin-left-20 margin-top-32' style={{ 
                        maxWidth: 640,
                        borderTop: '1px dotted #ddd',
                        paddingTop: 16
                    }}>
                        <h3>{totalComments > 0 ? 'Comments' : 'No comments yet'}</h3>
                        <ProposalComments
                            prID={prID}
                            totalComments={totalComments}
                            status={status}
                            canParticipate={canParticipate}
                        />
                    </div>
                )}
            </div>
            <div className='SnippetDocSidebar'>
                <div className='ProposalActions'>
                    {canParticipate && (
                        <div>
                            {status === 'ACTIVE' && (
                                <Participate
                                    prID={prID}
                                    createVote={onCreateVote}
                                    deleteVote={onDeleteVote}
                                    voteIsLoading={voteIsLoading}
                                    deleteVoteIsLoading={deleteVoteIsLoading}
                                    prStatus={status}
                                />
                            )}
                            {(canApprove || canClose) && status === 'ACTIVE' && (
                                <div className='ProposalAdminActions margin-bottom-15'>
                                    <div className='AdminActions'>
                                        {canApprove && (
                                            <Button 
                                                fill
                                                className='font500'
                                                text='Accept this suggestion'
                                                loading={acceptingProposal}
                                                onClick={onAcceptProposal}
                                            />
                                        )}
                                        {canClose && (
                                            <Button 
                                                fill
                                                className='font500 margin-top-5'
                                                text='Close this suggestion'
                                                loading={closingProposal}
                                                onClick={onCloseProposal}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    <CurrentResults
                        prID={prID}
                        process={doc.process}
                        votes={votes}
                        voters={voters}
                        supportNeeded={doc.process.controls[0].supportNeeded}
                        prStatus={status}
                    />
                </div>
            </div>
        </div>
    )

}

export default ProposalChanges;