import { Button, Divider, FormGroup, HTMLSelect, Icon, InputGroup, NumericInput } from '@blueprintjs/core';
import React, { Fragment, useState } from 'react';
import PositionedIcon from '../../ui/PositionedIcon/PositionedIcon';
import AccessSelect from '../AccessSelect/AccessSelect';
import ProtocolOption from '../ProtocolOption/ProtocolOption';
import TimerSelect from '../TimerSelect/TimerSelect';
import DatePicker from "react-datepicker";
import Avatar from '../../ui/Avatar/Avatar';
import { MultiSelect } from '@blueprintjs/select';
import UserMultiSelect from '../../ui/UserMultiSelect/UserMultiSelect';
import ApproverList from './ApproverList/ApproverList';
import { addToArray, removeArrayItem } from '../../../store/utility';
import ApprovalsEditor from './ApprovalsEditor/ApprovalsEditor';
import VotingEditor from './VotingEditor/VotingEditor';
import ModalTopBar from '../../ui/ModalTopBar/ModalTopBar';
import { SteleToaster } from '../../ui/Toaster/Toaster';
import UPDATE_DOC_SETTINGS from '../../SnippetDoc/ProtocolCard/mutations/updateDocSettings';
import { useMutation } from '@apollo/client';
import { getProcessInput } from './processInputUtils';
import { copyTextToClipboard } from '../../../utils/copyToClipboard';
import { Tooltip2 } from '@blueprintjs/popover2';
import CREATE_INVITE_ID from './mutations/createInviteID';

const DocSettingsEditor = ({
    timerOption,
    onSetTimerOption,
    deadlineDate,
    onSetDeadlineDate,
    docProcess,
    inviteID,
    participateAccess = 'PUBLIC',
    onSelectApproval,
    onSelectVoting,
    supportNeeded,
    viewAccess = 'ANYONE_WITH_LINK',
    onSetViewAccess,
    suggestAccess = 'ANYONE_WITH_LINK',
    onSetSuggestAccess,
    docTitle,
    owner,
    onClose,
    docID,
    userId
}) => {

    const [editableTitle, setEditableTitle] = useState(() => docTitle);
    const onChangeTitle = (e) => setEditableTitle(e.target.value);

    const [editableProtocol, setEditableProtocol] = useState(() => docProcess.controls[0].processType); // TODO: revise for multi-control modes in future

    const [approversToAdd, setApproversToAdd] = useState([]);

    const hasApprovers = docProcess.controls[0].approvers && docProcess.controls[0].approvers.length > 0;
    const [editableApprovers, setEditableApprovers] = useState(hasApprovers ? docProcess.controls[0].approvers : []);
    const [editableApprovalCount, setEditableApprovalCount] = useState(hasApprovers ? docProcess.controls[0].approvalCount : 1);
    const onChangeApprovalCount = (val) => setEditableApprovalCount(val);

    const hasVoting = docProcess.controls[0].processType === 'VOTING';
    const [editableSupportNeeded, setEditableSupportNeeded] = useState(hasVoting ? docProcess.controls[0].supportNeeded : 5);
    const onChangeSupportNeeded = (val) => setEditableSupportNeeded(val); 

    const [createInviteID, { 
        loading: creatingInviteID, 
        error: inviteIDerror, 
        data: inviteIDdata
    }] = useMutation(CREATE_INVITE_ID);

    const [editableParticipateAccess, setEditableParticipateAccess] = useState(() => {
        return participateAccess ? participateAccess : 'PUBLIC'
    });
    const onSetParticipateAccess = (e) => {
        setEditableParticipateAccess(e.currentTarget.value);
        if (e.currentTarget.value === `INVITE_LINK`) {
            createInviteID({
                variables: {
                    docID: docID
                }
            });
        }
    }

    const [isCopied, setCopied] = useState(false);
    const onSetCopied = () => {
        setCopied(true);
    }
    const onSetUncopied = () => {
        window.setTimeout(() => setCopied(false), 1000);
    }

    const [updateDocSettings, { loading: updatingSettings, error, data }] = useMutation(UPDATE_DOC_SETTINGS, {
        onCompleted: () => {
            SteleToaster.show({ message: 'Settings saved' });
            onClose();
        }
    });

    const onUpdateDocSettings = () => {
        const processInput = getProcessInput(editableProtocol, editableSupportNeeded, editableApprovers, editableApprovalCount, userId)
        updateDocSettings({
            variables: {
                input: {
                    docID: docID,
                    process: processInput,
                    participateAccess: editableParticipateAccess,
                    deadline: timerOption === 'UNTIL_DATE' ? deadlineDate : null
                }
            }
        })
    }

    const onChangeProtocol = (e) => {
        if (e.currentTarget.value === 'APPROVALS' && editableApprovers.length === 0) {
            setEditableApprovers([owner]);
        }
        setEditableProtocol(e.currentTarget.value);
    }

    const onAddApprovers = () => {
        const validApproversToAdd = approversToAdd.filter(approver => approver.valid !== false);
        const uniqueUsernames = [...new Set(validApproversToAdd.map(approver => approver.username))];
        const existingApproverUsernames = editableApprovers.map(approver => approver.username);
        const uniqueValidApprovers = uniqueUsernames.map(username => {
            return validApproversToAdd.find(approver => approver.username === username);
        });
        const notPreExistingValidApprovers = uniqueValidApprovers.filter(approver => !existingApproverUsernames.includes(approver.username));
        const newEditableApprovers = addToArray(editableApprovers, notPreExistingValidApprovers);
        setEditableApprovers(newEditableApprovers);
        setApproversToAdd([]);
    }

    const onRemoveApprover = (username) => {
        const approverToRemoveIndex = editableApprovers.findIndex(approver => approver.username === username);
        const newEditableApprovers = removeArrayItem(editableApprovers, approverToRemoveIndex);
        setEditableApprovers(newEditableApprovers);
    }

    let inviteLink;
    if (inviteID || (editableParticipateAccess === `INVITE_LINK` && inviteIDdata)) {
        inviteLink = `${process.env.REACT_APP_SITE_URL}/invite/${inviteID ? inviteID : inviteIDdata.createInviteID}`;
    }

    console.log('inviteLink', inviteLink)

    const onCopyInviteLink = () => {
        copyTextToClipboard(inviteLink)
        onSetCopied();
    }

    return (
        <div>
            <ModalTopBar
                title={'Document settings'}
                onClose={onClose} 
            />
            <div className='cw-modal-body'>
                <div className='modal-padded-section margin-top-8'>
                    <div className='width-67-percent'>
                        <FormGroup 
                            label='Title'
                            labelFor='docTitle'
                        >
                            <InputGroup 
                                id='docTitle'
                                value={editableTitle}
                                onChange={onChangeTitle}
                            />
                        </FormGroup>
                        <FormGroup 
                            label='Change Management'
                            labelFor='changeManagemnt'
                        >
                            <HTMLSelect 
                                id='changeManagement'
                                value={editableProtocol}
                                onChange={onChangeProtocol}
                                fill
                                iconProps={{ icon: 'caret-down' }}
                                options={[
                                    {label: 'Open', value: 'OPEN'},
                                    {label: 'Approvals', value: 'APPROVALS'},
                                    {label: 'Voting', value: 'VOTING'}
                                ]}
                            />
                        </FormGroup>
                    </div>
                </div>
                <Divider />
                <ApprovalsEditor
                    approversToAdd={approversToAdd}
                    setApproversToAdd={setApproversToAdd}
                    onAddApprovers={onAddApprovers}
                    editableApprovers={editableApprovers}
                    editableApprovalCount={editableApprovalCount}
                    onChangeApprovalCount={onChangeApprovalCount}
                    onRemoveApprover={onRemoveApprover}
                    isOpen={editableProtocol === 'APPROVALS'}
                />
                <VotingEditor
                    isOpen={editableProtocol === 'VOTING'}
                    editableSupportNeeded={editableSupportNeeded}
                    onChangeSupportNeeded={onChangeSupportNeeded}
                />
                <div className='modal-padded-section'>
                    <p className='font-size-12 font600'>Access</p>
                    <div className='GroupPermission flex space-between margin-right-4 margin-top-4 margin-bottom-4 items-start'>
                        <FormGroup 
                            // label='Change Management'
                            labelFor='changeManagemnt'
                        >
                            <HTMLSelect 
                                // id='changeManagement'
                                value={editableParticipateAccess}
                                onChange={onSetParticipateAccess}
                                iconProps={{ icon: 'caret-down' }}
                                options={[
                                    {label: 'Anyone', value: 'PUBLIC'},
                                    {label: 'Anyone with the invite link', value: 'INVITE_LINK'},
                                ]}
                            />
                        </FormGroup>
                        <span className='font-size-12 mt-1.5'>can participate</span>
                    </div>
                    {editableParticipateAccess === `INVITE_LINK` && (
                        <div className='-mt-3 mb-6 w-full'>
                            <div onClick={onCopyInviteLink}
                                onMouseLeave={onSetUncopied}
                                className='text-xs bg-gray-100 w-full py-2 px-3 rounded'
                            >
                                <Tooltip2
                                    minimal
                                    content={isCopied ? (
                                        <div>
                                            <Icon icon='tick' size={14} />
                                            {/* Copied */}
                                        </div>
                                    ) : 'Copy'}
                                    portalClassName='z-[201]'
                                    className='w-full'
                                    placement='top'
                                >
                                    <div className='flex space-between items-center cursor-pointer'>
                                        <div>
                                            <Icon icon='link' size={12} color='#777' className='mr-2'/>
                                            {inviteLink ? inviteLink : <span className='bp3-skeleton'>http://localhost:3000/tucker/clvf8y6ne0080m5vi60xe3hk8/</span>}
                                        </div>
                                        <Icon icon='duplicate' size={12} color='#777' />
                                    </div>
                                </Tooltip2>
                            </div>
                        </div>
                    )}
                    <Divider />
                    <div className='UserPermissions'>
                        <div className='UserPermission flex space-between align-center margin-right-4 margin-top-16 margin-bottom-16'>
                            <div className='flex align-center gap-2'>
                                <Avatar username={owner.username} noLink={true} />
                                <span>{owner.name ? owner.name : owner.username}</span>
                            </div>
                            <span>owner</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ModalActionBar'>
                <Button 
                    className='st-padded-btn' 
                    onClick={onUpdateDocSettings}
                    loading={updatingSettings && !data}
                    intent='primary'
                    text='Save'
                />
                <Button
                    minimal
                    text='Cancel'
                    onClick={onClose}
                />
            </div>
        </div>
    )
}

export default DocSettingsEditor;
