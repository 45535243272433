import React from 'react';
import cwIcon from './cw-icon.svg';

const CrowdwriteLogo = ({
    width,
    top = 0,
    marginRight = 0,
    fill = '#000'
}) => {

    return (
        <svg 
            width={width} 
            height={width} 
            viewBox="0 0 44 44" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            style={{
                position: 'relative',
                top: top,
                marginRight: marginRight
            }}
        >
            <path 
                d="M8.11233 36.428L5 34.6207L19.8257 9.21587C20.7702 7.5973 23.127 7.59407 24.0761 9.21004L39 34.6207L35.8958 36.4232C34.4183 37.2812 32.53 36.9422 31.45 35.6251L30.7563 34.779C29.2545 32.9474 26.3795 33.1259 25.1202 35.1289L24.9621 35.3803C23.6044 37.5399 20.4318 37.5399 19.074 35.3803L18.9075 35.1154C17.6506 33.1162 14.7829 32.9337 13.2782 34.7571L12.5512 35.6382C11.4698 36.9488 9.58632 37.2839 8.11233 36.428Z" 
                fill={fill}
            />
        </svg>
    )

}

export default CrowdwriteLogo;