import React from "react";
import SignIn from "../SignIn/SignIn";

const SignInPage = ({

}) => {

    return (
        <div className='h-screen flex flex-col justify-center'>
            <SignIn
                loginRedirect={`/docs`}
            />
        </div>
    )

}

export default SignInPage;