import { gql } from "@apollo/client";

const PROPOSAL_COMMENTS = gql`
    query ProposalComments($prID: String, $offset: Int, $limit: Int) {
        comments(prID: $prID, offset: $offset, limit: $limit) {
            _id
            author {
                _id
                username
                profilePicPath
            }
            created
            blockID
            text
            prID
        }
    }
`

export default PROPOSAL_COMMENTS;