import React from 'react';

const ProposalsSidebarSkeleton = ({

}) => {
    return (
        <div className='SnippetProposals'>
            <h3 className='bp3-skeleton skeleton'
                style={{
                    marginTop: 0,
                    width: 160,
                    marginBottom: 2
                }}>
                    test
            </h3>
            <div 
                className='content-card bp3-skeleton skeleton' 
                style={{ 
                    marginTop: 8, 
                    width: 256,
                    height: 74,
                    marginBottom: 11
                }}
            >
                skeleton
            </div>
            <div 
                className='content-card bp3-skeleton skeleton' 
                style={{ 
                    marginTop: 6, 
                    width: 256,
                    height: 74
                }}
            >
                skeleton
            </div>
            <div 
                className='content-card bp3-skeleton skeleton' 
                style={{ 
                    marginTop: 6, 
                    width: 256,
                    height: 74
                }}
            >
                skeleton
            </div>
        </div>
    )
}

export default ProposalsSidebarSkeleton;