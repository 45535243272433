import React, { useState } from 'react';
import { Button } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import './BlockTypeDropdown.css';
import { EditorState } from 'draft-js';

const BlockTypeDropdown = ({
    currentBlockType,
    onChangeBlockType,
    editorState,
    setEditorState,
    disabled
}) => {

    const [menuIsOpen, toggleIsOpen] = useState(false);

    const blockTypeItems = [
        { label: 'Normal text', value: 'unstyled' },
        { label: 'Normal text', value: 'unordered-list-item' },
        { label: 'Normal text', value: 'ordered-list-item' },
        { label: 'Heading 1', value: 'header-one' },
        { label: 'Heading 2', value: 'header-two' },
        { label: 'Heading 3', value: 'header-three' },
        { label: 'Heading 4', value: 'header-four', hidden: true },
        { label: 'Heading 5', value: 'header-five', hidden: true },
        { label: 'Heading 6', value: 'header-six', hidden: true },
        { label: 'Block Quote', value: 'blockquote' }
    ]

    const _onOpenMenu = (e) => {
        e.preventDefault();
        const selection = editorState.getSelection();
        const newEditorState = EditorState.forceSelection(editorState, selection);
        setEditorState(newEditorState);
        toggleIsOpen(true);
    }

    const _onClickBlockType = (e, value) => {
        onChangeBlockType(e, value);
        toggleIsOpen(false);
    }

    const blockTypeItemRenderer = (item, itemProps) => {
        if (item.value.includes('list-item') || item.hidden) return null;

        let classes = ['BlockTypeBtn', item.value];
        if (item.value.includes('header')) {
            classes.push('font600')
        }

        return <Button minimal fill 
                    key={item.value}
                    onMouseDown={(e) => _onClickBlockType(e, item.value)}
                    text={item.label} 
                    className={classes.join(' ')} />;
    } 

    return (
        <Select
            items={blockTypeItems}
            filterable={false}
            itemRenderer={blockTypeItemRenderer}
            disabled={disabled}
            popoverProps={{
                minimal: true, 
                popoverClassName: 'cw-menu-popover',
                portalClassName: 'relative',
                isOpen: menuIsOpen,
                onClose: () => toggleIsOpen(false)
            }}>
                <Button minimal small
                    className='CurrentBlockType'
                    onClick={_onOpenMenu}
                    text={blockTypeItems.find(type => type.value === currentBlockType).label} 
                    rightIcon='caret-down' 
                    disabled={disabled}
                />
        </Select>
    )
}

export default BlockTypeDropdown;