import { useQuery } from '@apollo/client';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import TEAM_DOCS from './queries/teamDocs';
import './Team.css';
import NewDoc from '../NewDoc/NewDoc';
import Invite from '../Invite/Invite';
import TeamError from './TeamError/TeamError';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Card } from '@blueprintjs/core';
import SnippetTopMenu from '../SnippetEditor/SnippetTopMenu/SnippetTopMenu';
import { useSelector } from 'react-redux';
import TeamSkeleton from './TeamSkeleton/TeamSkeleton';

const Team = ({

}) => {

    const currentUser = useSelector(state => state.auth.user);
    const { teamSlug } = useParams();
    const navigate = useNavigate();
    const { loading, error, data } = useQuery(TEAM_DOCS, {
        variables: {
            team: teamSlug
        },
        fetchPolicy: 'cache-and-network'
    });
    if (error) return <TeamError message={error.message} />;
    if (loading) return (
        <TeamSkeleton currentUser={currentUser} />
    )

    const docs = data.team.docs.map((doc, i) => {
        const createdDate = DateTime.fromISO(doc.lastEdited);
        const relativeDate = createdDate.toRelative();
        return (
            <Link to={`/${teamSlug}/${doc.docID}`} className='no-deco'>
                <Card 
                    className='content-card' 
                    key={i}
                >
                    <h3>
                        {doc.title}
                    </h3>
                    <p className='half-opaque font-size-12'>
                        {doc.versionCount > 1 ? 'Last edited ' : 'Started '}
                        {relativeDate} by {doc.lastEditor.username}
                        {doc.versionCount > 1 && (
                            <span> • {doc.versionCount} edits by {doc.contributors.length} contributor{doc.contributors.length > 1 && 's'}</span>
                        )}
                    </p>
                </Card>
            </Link>
        )
    })

    return (
        <div className='SnippetDocContainer SnippetHorizontalContainer'>
            <SnippetTopMenu currentUser={currentUser} />
            <h1>{data.team.name}</h1>
            <div className='TeamDocs'>
                {docs}
            </div>
        </div>
    )
}

export default Team;