import React from 'react';
import { getListItemIndex } from '../../../DocText/TextBlock/textBlockUtils/listItemUtils';
import BlockDiff from './BlockDiff/BlockDiff';
import ChangedSection from './ChangedSection/ChangedSection';
import { createRelevanceSections } from './relevanceUtils';
import UnchangedSection from './UnchangedSection/UnchangedSection';

const DocTextDiff = ({
    diffData,
    entities = [],
    canComment = true,
    comments = [],
    expandAll = false
}) => {

    const relevanceSections = createRelevanceSections(diffData);

    const sectionBlocks = relevanceSections.map((section, i) => {

        let entityKeysInSection = [];
        section.blocks.map(block => {
            if (block.entityRanges) {
                block.entityRanges.map(range => {
                    entityKeysInSection.push(range.key);
                })
            }
        });
        const entitiesInSection = entities.filter(entity => entityKeysInSection.includes(entity.key));

        if (section.relevant === true) {
            return (
                <ChangedSection 
                    blocks={section.blocks} 
                    diffData={diffData} 
                    canComment={canComment}
                    comments={comments}
                    entities={entitiesInSection}
                    key={i} 
                />
            );
        }
        return (
            <UnchangedSection 
                blocks={section.blocks} 
                key={i} 
                diffData={diffData} 
                entities={entitiesInSection}
                canComment={canComment}
                comments={comments}
                nextIsRelevant={section.nextIsRelevant}
                prevIsRelevant={section.prevIsRelevant}
                expandAll={expandAll}
            />
        );
    })

    return (
        <div className='DocTextDiff st-para'>
            {/* {diffBlocks} */}
            {sectionBlocks}
        </div>
    )
}

export default DocTextDiff;