import { gql } from "@apollo/client";

const FORK_DOC = gql`

    mutation ForkDoc($input: ForkDocInput) {
        forkDoc(input: $input) {
            _id
            docID
            docTextID
            team
            title
            description
            creator {
                _id
                username
            }
            contributors {
                _id
                username
                orbDeg
            } 
            created
            activeProposals
            totalProposals
            totalComments
            totalTopLevelComments
            versionCount
            arweaveID
            deadline
            text { 
                key
                text
                _id
                depth
                type
                inlineStyleRanges {
                    length
                    offset
                    style
                }
                entityRanges {
                    length
                    offset
                    key
                }
            }
            entityMap {
                type
                mutability
                key
                data {
                    url
                }
            }
            process {
                creatorCanEdit
                creatorCanChangeSettings
                controls {
                    processType
                    supportNeeded
                    approvers {
                        _id
                        username
                    }
                    approvalCount
                }
            }
        }
    }

`

export default FORK_DOC;