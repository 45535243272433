import React from 'react';
import CopyLink from '../../ui/CopyLink/CopyLink';
import { Link } from 'react-router-dom';
import Authorship from '../Authorship/Authorship';
import ProtocolCard from '../ProtocolCard/ProtocolCard';
import SnippetDocNav from '../SnippetDocNav/SnippetDocNav';

const SourceViewHeader = ({
    teamSlug,
    docID,
    docTitle,
    data,
    userId,
    userCanSave,
    participateAccess,
    inviteID
}) => {

    return (
        <div>
            <div className='ml-5'>
                <CopyLink />
            </div>
            <h1 className='font-bold tracking-tight margin-left-20 margin-right-20 mt-0 text-4xl'>
                <Link to={`/${teamSlug}/${docID}`} className='no-deco'>
                    {docTitle}
                </Link>
            </h1>
            <p 
                className='large-text soft-text'
                style={{ 
                    margin: '0 20px 16px',
                    fontSize: 24
                }}
            >
                {data.doc.description}
            </p>
            <Authorship
                team={data.team}
                contributors={data.doc.contributors}
            />
            <ProtocolCard
                docID={docID}
                docTitle={data.doc.title}
                process={data.doc.process}
                deadline={data.doc.deadline}
                userCanEdit={userCanSave}
                userId={userId}
                owner={data.doc.creator}
                participateAccess={participateAccess}
                inviteID={inviteID}
            />
            <SnippetDocNav 
                activeProposals={data.doc.activeProposals}
                totalProposals={data.doc.totalProposals}
                totalComments={data.doc.totalComments}
                versionCount={data.doc.versionCount}
            />
        </div>
    )
}

export default SourceViewHeader;