import { Button, Menu, MenuItem } from '@blueprintjs/core';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import React, { useState } from 'react';
import SignUpModal from '../../../SignUpModal/SignUpModal';
import PositionedIcon from '../../../ui/PositionedIcon/PositionedIcon';
import * as actions from '../../../../store/actions/index';
import './AccountMenu.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import EntryModal from '../../../EntryModal/EntryModal';

const AccountMenu = ({
    currentUser
}) => {

    const [showSignInModal, toggleSignInModal] = useState(false);
    const onShowSignInModal = () => toggleSignInModal(true);

    const [entryWay, setEntryWay] = useState('LOG_IN');
    const onHideSignInModal = () => {
        setEntryWay('LOG_IN');
        toggleSignInModal(false);
    };


    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onSignOut = () => {
        window.localStorage.removeItem(`__stele-token__`);
        dispatch(actions.setUser({ email: '', _id: null }));
        navigate('/sign-in');
    }

    let accountButton = (
            <Button 
                className='font600'
                minimal
                text='Log in'
                // intent='primary'
                onClick={onShowSignInModal}
            />
    );

    if (currentUser && currentUser.username) {
        accountButton = (
            <Popover2 minimal
                popoverClassName='dark'
                placement='bottom-end'
                content={(
                    <Menu>
                        <MenuItem 
                            text='Sign out'
                            onClick={onSignOut}
                        />
                    </Menu>
                )}
            >
                <button className='AccountMenuButton'>
                    {currentUser.username} 
                    <PositionedIcon
                        icon='caret-down'
                        iconSize={11}
                        top={-1}
                    />
                </button>
            </Popover2>
        )
    }

    return (
        <div className='AccountMenu'>
            {accountButton}
            <EntryModal 
                entryWay={entryWay}
                setEntryWay={setEntryWay}
                isOpen={showSignInModal}
                handleClose={onHideSignInModal} 
            />
        </div>
    )
}

export default AccountMenu;