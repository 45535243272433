import React from 'react';

const ProposalSkeleton = (props) => {
    return (
        <div style={{
            width: 672,
            marginTop: 40,
            marginLeft: 20
        }}>
            <h2 className='bp3-skeleton skeleton'
                style={{
                    width: 400
                }}
            >
                Skeleton
            </h2>
            <p className='bp3-skeleton skeleton'
                style={{
                    width: 320
                }}
            >
                Skeleton
            </p>
            <p
                className='bp3-skeleton skeleton'
                style={{
                    height: 120,
                    marginTop: 32
                }}
            >
                skeleton
            </p>
            <p
                className='bp3-skeleton skeleton'
                style={{
                    height: 70,
                }}
            >
                skeleton
            </p>
            <p
                className='bp3-skeleton skeleton'
                style={{
                    height: 200,
                }}
            >
                skeleton
            </p>
            <p
                className='bp3-skeleton skeleton'
                style={{
                    height: 70,
                }}
            >
                skeleton
            </p>
            <p
                className='bp3-skeleton skeleton'
                style={{
                    height: 200,
                }}
            >
                skeleton
            </p>
        </div>
    );
}

export default ProposalSkeleton;