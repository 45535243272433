import { gql } from "@apollo/client";

const TEAM_DOCS = gql`
    query TeamDocs($team: String) {
        team(slug: $team) {
            _id
            name
            slug
            docs {
                title
                _id
                docID
                versionCount
                contributors {
                    _id
                }
                lastEdited
                lastEditor {
                    _id
                    username
                }
            }
        }
    }
`

export default TEAM_DOCS;