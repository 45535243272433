import React, { useState } from 'react';
import PositionedIcon from '../PositionedIcon/PositionedIcon';
import { useLocation } from 'react-router';
import './CopyLink.css';

const fallbackCopyTextToClipboard = (text) => {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
  
    document.body.removeChild(textArea);
}

const copyTextToClipboard = (text, setLabel) => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(function() {
      setLabel('Copied');
      setTimeout(() => setLabel('Copy link'), 3000);
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
}


const CopyLink = (props) => {

    const location = useLocation();
    const link = `${process.env.REACT_APP_SITE_URL}${location.pathname}`;
    const [label, setLabel] = useState('Copy link');
    const handleClick = () => {
        copyTextToClipboard(link, setLabel);
    }

    return (
        <button 
            className='CopyLink'
            onClick={handleClick}
        >
            <PositionedIcon 
                icon={label === 'Copy link' ? 'link' : 'tick'} 
                iconSize={12}
                marginRight={4}
                top={-2}
            />
            {label}
        </button>
    )

}

export default CopyLink;