import { useMutation } from "@apollo/client";
import React from "react";
import RESEND_VERIFY_EMAIL from "./mutations/resendVerifyEmail";

const VerifyEmailBanner = ({
    user
}) => {

    const [resendVerifyEmail, { loading, error, data }] = useMutation(RESEND_VERIFY_EMAIL)

    if (!user || user.emailVerified !== false) {
        return null;
    }

    const onResendEmail = () => {
        resendVerifyEmail({
            variables: {
                id: user._id
            }
        })
    }

    return (
        <div className='bg-offwhite px-3 py-2 font-medium text-xs tracking-tight'>
            <div className=''>
                <div className='flex justify-between w-full'>
                    <div>Please verify your email</div>
                    <div>
                        {!loading && !data && (
                            <button 
                                className='ml-2 text-gray-800 hover:underline'
                                onClick={onResendEmail}
                            >
                                Resend
                            </button>
                        )}
                        {loading && (
                            <span className='ml-2 text-gray-800'>Sending...</span>
                        )}
                        {data && (
                            <span className='ml-2 text-gray-800'>Sent</span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerifyEmailBanner;