import { Toast, Toaster } from "@blueprintjs/core";
import { useSelector } from "react-redux";
import { Router } from "react-router";

export const SteleToaster = Toaster.create({
    className: "st-toaster",
    position: 'top',
});

export const RealtimeToaster = Toaster.create({
    className: 'st-realtime-toaster',
    position: 'bottom-right'
});