import { gql } from "@apollo/client";

const DELETE_DOC = gql`
    mutation DeleteDoc($docID: String) {
        deleteDoc(docID: $docID) {
            _id
            docID
            deleted
        }
    }
`

export default DELETE_DOC;