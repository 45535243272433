import { gql } from "@apollo/client";

const DOC_BLOCK_THREADS = gql`
    query BlockThreads($docID: String) {
        blockThreads(docID: $docID) {
            _id
            blockID
            commentCount
            highlightStart
            highlightEnd
            commenters {
                _id
                username
                profilePicPath
            }
            firstCommenter {
                _id
                username
                profilePicPath
            }
            created
            firstComment
        }
        proposals(docID: $docID, status: ACTIVE, limit: 100) {
            _id
            title
            description
            touchedBlockKeys
            created
            status
            voters {
                _id
                stance
                user {
                    _id
                    username
                }
            }
            comments {
                _id
            }
            author {
                _id
                username
            }
            votes {
                for
                against
                net
                total
            }
        }
    }
`

export default DOC_BLOCK_THREADS;