import gql from "graphql-tag";

const EMAIL_SIGN_IN = gql`
    mutation EmailSignIn($email: Email, $password: String) {
        emailSignIn(email: $email, password: $password) {
            jwt
            user {
                _id
                email
                username
            }
            error
        }
    }
`

export default EMAIL_SIGN_IN;