import { useMutation } from '@apollo/client';
import { Button, Card, FormGroup, InputGroup } from '@blueprintjs/core';
import isEmail from "validator/lib/isEmail";
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import * as actions from '../../store/actions/index';
import CrowdwriteLogo from '../ui/svg/CrowdwriteLogo';
import { Link } from 'react-router-dom';
import SignInHeader from '../SignIn/SignInHeader/SignInHeader';
import REQUEST_PASSWORD_RESET from './mutations/requestPasswordReset';

const ForgotPassword = ({

}) => {


    const [emailInput, setEmailInput] = useState('');

    const validEmailSignIn = isEmail(emailInput);

    const [resetError, setResetError] = useState(false);
    const [sentResetEmail, setSentResetEmail] = useState(false);

    const [requestPasswordReset, { loading, error, data }] = useMutation(REQUEST_PASSWORD_RESET,
        {
            variables: {
                email: emailInput
            },
            onCompleted: (data) => {
                if (data.requestPasswordReset === false) {
                    setResetError(true);
                    setSentResetEmail(false);
                }
                if (data.requestPasswordReset === true) {
                    setResetError(false);
                    setSentResetEmail(true);
                }
            }
        }
    );

    const onRequestPasswordReset = () => {
        requestPasswordReset();
    }

    const onChangeEmailInput = (e) => {
        if (sentResetEmail) {
            setSentResetEmail(false);
        }
        setEmailInput(e.target.value);
    }

    const onKeyDown = (e) => {
        if (isEmail(e.target.value) && e.key === 'Enter') {
            requestPasswordReset();
        }
    }


    return (
        <div className='h-screen flex flex-col justify-center'>
            <div className='w-96 mx-auto'>
                <SignInHeader />
                <p className='font-semibold tracking-tight -mt-2 mb-4'>
                    Reset your password
                </p>
                <FormGroup
                >
                    <InputGroup 
                        id='email'
                        large
                        aria-label='Enter your email'
                        placeholder='Enter your email'
                        value={emailInput}
                        onChange={onChangeEmailInput}
                        onKeyDown={onKeyDown}
                        type='email' 
                    />
                </FormGroup>
                {sentResetEmail && (
                    <Card className='border-0 bg-gray-100 px-1 py-2 my-3 text-center shadow-none text-xs'>
                        If your account exists, a reset link is on its way
                    </Card>
                )}
                <div className='mt-5'>
                    <Button 
                        intent='primary'
                        text={sentResetEmail ? 'Resend' : 'Reset password'}
                        disabled={!validEmailSignIn}
                        onClick={onRequestPasswordReset}
                        loading={loading}
                    />
                </div>
                <div className='mt-8 text-xs text-center'>
                    Changed your mind? <Link to='/sign-in' className='font-semibold hover:no-underline'>Sign in</Link>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;