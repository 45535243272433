import gql from "graphql-tag";

const REQUEST_INVITE = gql`
    mutation RequestInvite($responseToken: String, $email: Email) {
        requestInvite(responseToken: $responseToken, email: $email) {
            success
            challenge_ts
            hostname
            error_codes
        }
    }
`

export default REQUEST_INVITE;