import React from 'react';
import { getListItemIndex } from '../../../../DocText/TextBlock/textBlockUtils/listItemUtils';
import BlockDiff from '../BlockDiff/BlockDiff';

export const getBlockEntities = (entityRanges = [], entities = []) => {
    if (entityRanges.length === 0 || entities.length === 0) { 
        return [];
    }
    const entityRangeKeys = entityRanges.map(range => range.key);
    return entities.filter(entity => entityRangeKeys.includes(entity.key));
}

const ChangedSection = ({
    blocks,
    comments,
    entities,
    diffData,
    canComment
}) => {

    const comparisonBlocks = blocks.map((block, i) => {
        const blockComments = comments ? comments.filter(comment => comment.blockID === block._id) : null;
        const indexInFullDiff = diffData.findIndex(diffBlock => diffBlock._id === block._id);
        const blockEntityRanges = block.entityRanges ? block.entityRanges : [];
        const blockEntities = getBlockEntities(blockEntityRanges, entities);
        return (
            <BlockDiff 
                canComment={canComment} 
                key={i} 
                tempBlockKey={i} 
                listItemIndex={getListItemIndex(diffData, indexInFullDiff)}
                comments={blockComments}
                entities={blockEntities}
                {...block} 
            />
        )
    });

    return (
        <div className='ChangedSection'>
            {comparisonBlocks}
        </div>
    )
}

export default ChangedSection;