import { useMutation } from '@apollo/client';
import { Button, Menu, MenuItem } from '@blueprintjs/core';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import React, { Fragment, useState } from 'react';
import { getFullDate, getReadableDate, getRelativeDate } from '../../../../../../../ui/FormattedDate/dateUtils';
import './Comment.css';
import CommentReplies from './CommentReplies/CommentReplies';
import EditComment from './EditComment/EditComment';
import DELETE_COMMENT from './mutations/deleteComment';
import Avatar from '../../../../../../../ui/Avatar/Avatar';
import { DateTime } from 'luxon';
import { useParams } from 'react-router';

const Comment = ({
    _id,
    threadID,
    parentID,
    text,
    author,
    created,
    edited,
    activeUser,
    replies,
    lastReplyDate,
    lastReplyUser,
    referenceText
}) => {

    const { docID, prID } = useParams();

    const [isEditing, toggleEditing] = useState(false);
    const onEdit = () => toggleEditing(true);
    const onCloseEdit = () => toggleEditing(false);

    const [showingReplies, toggleReplies] = useState(false);
    const onToggleReplies = () => toggleReplies(!showingReplies);

    const [showingFullReferenceText, toggleReferenceText] = useState(false);
    const onToggleReferenceText = () => toggleReferenceText(!showingFullReferenceText); 

    const fullDateString = getFullDate(created);
    const editedDateString = getFullDate(edited);
    const relativeDate = getRelativeDate(created);

    const userIsAuthor = activeUser === author._id;

    // useState(() => {
    //     if (replies > 0) {
    //         toggleReplies(true);
    //     }
    // }, [])

    const [deleteComment, { loading: loadingDelete, error, data }] = useMutation(DELETE_COMMENT)

    const onDeleteComment = () => {
        deleteComment({
            variables: {
                commentID: _id
            },
            update(cache) {
                const normalizedID = cache.identify({ _id, __typename: 'Comment' });
                cache.evict({ id: normalizedID });
                if (threadID) {
                    const threadID = `BlockThread:${threadID}`;
                    cache.modify({
                        id: threadID,
                        fields: {
                            commentCount(value) {
                                return value - 1;
                            }
                        }
                    });
                }

                // only update the number of doc comments if the comment isn't related to a suggestion
                if (!prID) {
                    const docCacheID = cache.identify({ docID: docID, __typename: 'Doc' });
                    cache.modify({
                        id: docCacheID,
                        fields: {
                            totalComments(value) {
                                return value - 1
                            },
                            totalTopLevelComments(value) {
                                return value - 1
                            }
                        }
                    })
                }
                cache.gc();
            }
        })
    }

    const lastReplyRelativeDate = lastReplyDate ? getRelativeDate(lastReplyDate) : null;

    return (
        <div className='Comment' id={_id}>
            <Avatar 
                {...author}
            />
            {isEditing && (
                <EditComment
                    text={text}
                    userId={activeUser}
                    commentID={_id}
                    onClose={onCloseEdit}
                />
            )}
            {!isEditing && (
                <div className='text'>
                    {referenceText && (
                        <div 
                            className={showingFullReferenceText ? 'ReferenceTextContainer expanded' : 'ReferenceTextContainer'}
                            onClick={onToggleReferenceText}
                        >
                            <div className='ReferenceLine'></div>
                            <p className='ReferenceText'>{referenceText}</p>
                        </div>
                    )}
                    <div className='CommentMeta no-margin line-height-1-1 margin-bottom-2'>
                        <div>
                            <span className='font600'>{author ? author.username : 'anonymous'}</span>
                            <span className='margin-left-8 half-opaque' title={fullDateString}>
                                {relativeDate}
                            </span>
                        </div>
                        {userIsAuthor && (
                            <div className='CommentMenu'>
                                <Popover2 
                                    minimal
                                    interactionKind='click'
                                    placement='bottom-end'
                                    portalClassName='CommentMenuPopover'
                                    popoverClassName='inline-menu'
                                    content={
                                        <Menu>
                                            <MenuItem 
                                                text='Edit'
                                                onClick={onEdit} 
                                            />
                                            <MenuItem 
                                                text='Delete' 
                                                onClick={onDeleteComment}
                                            />
                                        </Menu>
                                    }
                                >
                                    <Button 
                                        small minimal
                                        icon='more'
                                    />
                                </Popover2>
                            </div>
                        )}
                    </div>
                    <p className='no-margin margin-top-4 pre-wrap'>
                        {text}
                        {edited && (
                            <span 
                                className='margin-left-4 half-opaque'
                                title={editedDateString}
                            >
                                (edited)
                            </span>
                        )}
                    </p>
                    {replies > 0 && (
                        <Fragment>
                        <div className='CommentActions'>
                            <span
                                // small
                                className='text-xs cursor-pointer opacity-100'
                                // active={showingReplies}
                                onClick={onToggleReplies}
                            >
                                <span className='font-semibold accent-text tracking-tight'>
                                    {replies} repl{replies > 1 ? 'ies' : 'y'}
                                </span>
                                <span className='half-opaque margin-left-8'>
                                    {lastReplyUser.username} {lastReplyRelativeDate}
                                </span>
                            </span>
                        </div>
                        {showingReplies && (
                            <CommentReplies 
                                parentType={threadID ? 'BLOCK' : 'COMMENT'}
                                parentID={threadID ? threadID : parentID}
                                activeUser={activeUser}
                            />
                        )}
                        </Fragment>
                    )}
                </div> 
            )}
        </div>
    )
}

export default Comment;