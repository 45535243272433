import { gql } from "@apollo/client";

const CLOSE_PROPOSAL = gql`
    mutation CloseProposal($prID: String, $docID: String) {
        closeProposal(prID: $prID, docID: $docID) {
            error
            proposal {
                _id
                title
                description
                closed
                closedBy {
                    _id
                    username
                }
                accepted
                acceptedBy {
                    _id
                    username
                }
                votes {
                    for
                    against
                    net
                    total
                }
                status
                number
                author {
                    _id
                    username
                }
                created
                text { 
                    key
                    text
                    _id
                    type
                    depth
                    inlineStyleRanges {
                        length
                        offset
                        style
                    }
                }
                diff {
                    _id
                    text
                    contextualID
                    key
                    diffStatus
                    textDiff {
                        value
                        added
                        removed
                    }
                    type
                    depth
                    entityRanges {
                        length
                        offset
                        key
                    }
                    inlineStyleRanges {
                        length
                        offset
                        style
                    }
                }
                changes {
                    _id
                    contextualID
                    text
                    key
                    type
                    diffStatus
                    inlineStyleRanges {
                        length
                        offset
                        style
                    }
                    entityRanges {
                        length
                        offset
                        key
                    }
                    textDiff {
                        value
                        added
                        removed
                    }
                }
            }
        } 
    }
`

export default CLOSE_PROPOSAL;