import { Button } from '@blueprintjs/core';
import React, { useState } from 'react';
import './GentleOnboardGate.css';
import { useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import EntryModal from '../EntryModal/EntryModal';
import { getCurrentUser } from '../../store/selectors/authSelectors';

const GentleOnboardGate = ({
    children
}) => {

    const currentUser = useSelector(getCurrentUser);

    const { teamSlug, username } = useParams();
    const [entryModalOpen, toggleEntryModal] = useState(false);
    const [entryWay, setEntryWay] = useState('');

    if (currentUser && currentUser._id) {
        return children;
    }

    const onLogin = () => {
        setEntryWay('LOG_IN');
        toggleEntryModal(true);
    }
    const onSignUp = () => {
        setEntryWay('SIGN_UP');
        toggleEntryModal(true);
    }

    const onCloseEntryModal = () => toggleEntryModal(false);

    let mutual;
    if (teamSlug) { mutual = teamSlug; }
    if (username ) { mutual = username; }
    if (username === 'crowdwrite' || teamSlug === 'crowdwrite') { mutual = null; }

    return (
        <div>
            {/* <div className='bg-offwhite-hover shadow-lg fixed bottom-0 z-30 inset-x-0 pt-2 pb-5 sm:pt-3 sm:pb-3 border-t border-white'>
                <div className='sm:justify-center pl-20 pr-16 inset-x-0 flex flex-col gap-0 items-center sm:gap-8 sm:flex-row'>
                    <div className='sm:max-w-lg md:max-w-2xl'>
                        <h2 className='text-2xl tracking-tight font-medium overflow-clip text-center sm:text-left'>
                            Join
                            {mutual && <span> {mutual} on Crowdwrite</span>}
                            {!mutual && <span> crowdwrite</span>}
                        </h2>
                    </div>
                    <div className='flex gap-2 min-w-max'>
                        <Button 
                            className='bg-white hover:bg-offwhite'
                            intent='white'
                            large
                            text='Log in' 
                            onClick={onLogin}
                        />
                        <Button 
                            large
                            intent='primary'
                            text='Sign up' 
                            onClick={onSignUp}
                        />
                    </div>
                </div>
            </div> */}
            <EntryModal
                isOpen={entryModalOpen}
                handleClose={onCloseEntryModal}
                entryWay={entryWay}
                setEntryWay={setEntryWay}
            />
            {children}
        </div>
    )

}

export default GentleOnboardGate;