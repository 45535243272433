import validator from "validator";

// remove entity
// when given an entity key, identify the range that the entity applies to
// turn it into a selection

import { convertFromRaw, convertToRaw, EditorState, Modifier } from "draft-js";
import decorator from "./decorator/decorator";
import { addHTTP } from "./LinkEditorModal/LinkEditorModal";

export const isEmptyObject = (obj) => {
    return !obj || (Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype);
}

// remove __typename from data to post to server
export const getObjWithoutTypename = (obj) => {
    if (!obj) return null;
    const keys = Object.keys(obj).filter(dataKey => dataKey !== '__typename');
    let filteredObj = {};
    keys.map(dataKey => { 
        filteredObj[dataKey] = obj[dataKey]
    });
    return filteredObj;
}

export const sanitizeLinkData = (obj) => {
    if (!obj) return null;
    return {
        url: obj.url
    }
}

export const getEntityMapFromArray = (entities) => {
    if (!entities || entities.length === 0) return {};
    let entityMap = {};
    entities.map(entity => {
        // remove __typename from entity data
        const entityDataKeys = Object.keys(entity.data).filter(key => key !== '__typename');
        let entityData = {};
        entityDataKeys.map(key => { 
            entityData[key] = entity.data[key]
        });
        entityMap[entity.key] = {
            type: entity.type,
            mutability: entity.mutability,
            data: entityData
        }
    });
    return entityMap;
}

export const getEntityArrayFromMap = (entityMap) => {
    if (!entityMap || isEmptyObject(entityMap)) return [];
    let entityArray = [];
    for (const entityKey in entityMap) {
        // filter out __typename property
        let entityData = getObjWithoutTypename(entityMap[entityKey].data);
        if (entityMap[entityKey].type === 'LINK') entityData = sanitizeLinkData(entityData);
        const entity = {
            type: entityMap[entityKey].type,
            mutability: entityMap[entityKey].mutability,
            data: entityData,
            key: entityKey
        }
        entityArray.push(entity);
    };
    return entityArray;
}

// use rich utils to remove the entity for the selection
export const removeLink = (entityKey, editorState, setEditorState, decorator) => {
    
    const currentContentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const newContentState = currentContentState.replaceEntityData(entityKey, null);

    const newEditorState = EditorState.push(
        editorState,
        newContentState,
        'apply-entity'
    );

    // convert contentState to raw and back again to remove entity from entityMap
    const rawContent = convertToRaw(newEditorState.getCurrentContent());
    const rawEntities = rawContent.entityMap;
    const rawEntityArray = getEntityArrayFromMap(rawEntities);
    const filteredEntityArray = rawEntityArray.filter(entity => entity.data !== null);
    const filteredEntityMap = getEntityMapFromArray(filteredEntityArray);
    const filteredRawContentState = {
        blocks: rawContent.blocks,
        entityMap: filteredEntityMap
    }
    const filteredContentState = convertFromRaw(filteredRawContentState);
    const finalEditorState = EditorState.create({
        allowUndo: true,
        currentContent: filteredContentState,
        decorator: decorator,
        selection: selection
    })

    setEditorState(finalEditorState);
}


// update link entity
// when given an entity key and a new url,
// update the url for the entity 
export const setSelectionToEditLink = (entityKey, blockKey, editorState, setEditorState, decorator) => {
    
    if (!entityKey) return null;

    const contentBlock = editorState.getCurrentContent().getBlockForKey(blockKey);
    const selectionState = editorState.getSelection();
    let entitySelection = null;
    contentBlock.findEntityRanges(
        (character) => character.getEntity() === entityKey,
        (start, end) => {
        entitySelection = selectionState.merge({
            anchorOffset: start,
            focusOffset: end
        });
        }
    );

    const newEditorState = EditorState.forceSelection(editorState, entitySelection);
    const newEditorStateWithDecorator = EditorState.set(newEditorState, { decorator: decorator });
    setEditorState(newEditorStateWithDecorator);
}

export const getSelectedText = (editorState) => {
    const selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const currentContent = editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(anchorKey);
    const start = selectionState.getStartOffset();
    const end = selectionState.getEndOffset();
    return currentContentBlock.getText().slice(start, end);
}

export const isSelectionLink = (editorState) => {
    const selectedText = getSelectedText(editorState);
    return validator.isURL(selectedText);
}

export const applyLinkToLinkString = (editorState, setEditorState) => {
    const selectedText = getSelectedText(editorState);
    if (!validator.isURL(selectedText)) return null;
    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const linkURL = addHTTP(selectedText);
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
        url: linkURL,
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const contentStateWithLink = Modifier.applyEntity(
        contentStateWithEntity,
        selectionState,
        entityKey,
    );
    const newEditorState = EditorState.push(
        editorState,
        contentStateWithLink,
        'apply-entity'
    );

    const newCursorPosition = selectionState.getEndOffset();
    const selectionStateWithNewAnchor = selectionState.set('anchorOffset', newCursorPosition);
    const selectionWithNewFocus = selectionStateWithNewAnchor.set('focusOffset', newCursorPosition);
    const editorStateWithSelection = EditorState.forceSelection(newEditorState, selectionWithNewFocus);
    const theEditorState = EditorState.set(editorStateWithSelection, {
        decorator: decorator
    })
    setEditorState(theEditorState);
}