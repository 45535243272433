import { Popover2 } from '@blueprintjs/popover2';
import React from 'react';
import Avatar from '../../../../ui/Avatar/Avatar';
import ThreadTooltip from './ThreadTooltip/ThreadTooltip';
import './ThreadSidebar.css';
import { Icon } from '@blueprintjs/core';

const ThreadSidebar = ({
    _id,
    setOpenThreadContext,
    threads
}) => {

    const onOpenThread = (e, threadID) => {
        e.preventDefault();
        if (threadID) {
            setOpenThreadContext({
                threadID: threadID,
                x: e.clientX,
                y: e.clientY
            })
        }
    }

    let totalComments = 0;
    threads.map(thread => totalComments += thread.commentCount);

    return (
        <div>
            <div className='ThreadSidebar'>
                <div onClick={onOpenThread}>
                    <Popover2
                        minimal
                        placement='top-start'
                        interactionKind='hover'
                        popoverClassName='ThreadTooltipPopover'
                        content={(
                            <ThreadTooltip
                                firstCommenter={threads[0].firstCommenter}
                                firstComment={threads[0].firstComment}
                                commentCount={threads[0].commentCount}
                                created={threads[0].created}
                                threads={threads}
                                onOpenThread={onOpenThread}
                            />
                    )}>
                        <button 
                            className='opacity-90 hover:opacity-100'
                        >
                            <Icon
                                icon='chat'
                                color='rgb(170, 170, 170)'
                                size={12}
                            />
                            <span className='text-sm relative top-px font-semibold text-gray-400 ml-0.5'>{totalComments}</span>
                        </button>
                    </Popover2>
                </div> 
            </div>
        </div>
    ) 
}

export default ThreadSidebar;