import React from 'react';
import './Avatar.css';
import { Link } from 'react-router-dom';

const Avatar = ({
    username,
    profilePicPath,
    size = 'SMALL',
    huddled,
    noLink
}) => {

    let classes = ['avatar border border-gray-200 shadow-sm', size];
    if (!username) { 
        classes.push('not-logged-in');
    }
    if (huddled) {
        classes.push('huddled');
    }
    if (profilePicPath) {
        classes.push('image-avatar');
    }

    const classesList = classes.join(' ');

    let avatar = (
        <div className={classesList}>
            <span className='initial'>
                {username ? username[0].toUpperCase() : '?'}
            </span>
        </div>
    )

    // if (profilePicPath) {
    //     const pfpURL = `${process.env.REACT_APP_IMAGE_CDN_BASE_URL}${profilePicPath}`
    //     avatar = (
    //         <div className={classesList}>
    //             <img src={pfpURL} alt={username} />
    //         </div>
    //     )
    // }

    let avatarContainer = (
        <Link to={`/user/${username}`}>
           {avatar}
        </Link>
    )

    if (noLink) {
        avatarContainer = (
            <div className='cursor-default'>
                {avatar}
            </div>
        )
    }

    return avatarContainer
}

export default Avatar