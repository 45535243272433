import React from 'react';
import { SteleToaster } from '../../Toaster/Toaster';

const NewProposalToast = ({
    prID,
    prNumber,
    handleLink
}) => {

    const onClick = (e) => {
        e.preventDefault();
        SteleToaster.dismiss(prID);
        handleLink()
    }

    return (
        <div>
            <span>Created </span>
            <a href='#'
                onClick={onClick}
            >
                proposal 
                {/* #{prNumber} */}
            </a>
        </div>
    )
}

export default NewProposalToast;