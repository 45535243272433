import { gql } from "@apollo/client";

const SIGN_UP_FROM_INVITE = gql`
    mutation SignUpFromInvite($input: SignUpFromInviteInput) {
        signUpFromInvite(input: $input) {
            user {
                _id
                username
            }
            jwt
            error
        }
    }

`

export default SIGN_UP_FROM_INVITE;