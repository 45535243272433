import React, { useEffect, useRef, useState } from 'react';
import ThreadAddComment from '../ThreadAddComment/ThreadAddComment';
import ThreadComments from '../ThreadComments/ThreadComments';

const ThreadContent = ({
    comments,
    threadID,
    docTextID,
    docID,
    textContainerRef,
    xPos,
    leftPos,
    yPos
}) => {

    const [height, setHeight] = useState(0);

    const containerRef = useRef(null);

    useEffect(() => {
        setHeight(containerRef.current.scrollHeight);
    });

    let threadStyles = {
        position: 'fixed',
        top: yPos - 40,
        left: leftPos
    }
    const overflow = yPos + height - window.innerHeight;
    if (overflow > 0) {
        threadStyles = {
            position: 'fixed',
            top: yPos - overflow - 120,
            left: leftPos
        }
    }

    return (
        <div className='ThreadContainer'
            ref={containerRef}
            style={threadStyles}
        >
            <ThreadComments 
                comments={comments}
                threadID={threadID} 
            />
            <ThreadAddComment
                threadID={threadID}
                docTextID={docTextID}
                docID={docID}
            />
        </div>
    )
}

export default ThreadContent;