import { gql } from "@apollo/client";

const SIGN_UP_WITH_EMAIL = gql`
    mutation SignUpWithEmail($input: SignUpWithEmailInput) {
        signUpWithEmail(input: $input) {
            user {
                _id
                username
                emailVerified
            }
            jwt
            error
        }
    }
`

export default SIGN_UP_WITH_EMAIL;