import React from 'react';
import Avatar from '../../../ui/Avatar/Avatar';
import { Button } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';

const ApproverList = ({
    approvers = [],
    onRemoveApprover
}) => {

    if (approvers.length === 0) {
        return null;
    }

    const approverBlocks = approvers.map((approver, i) => {
        const canRemove = approvers.length > 1;

        const handleRemove = () => {
            onRemoveApprover(approver.username);
        }

        const approverHandle = approver.name ? approver.name : approver.username;

        return (
            <div 
                key={i}
                className='UserPermission flex space-between align-center margin-top-16 margin-bottom-16'
            >
                <div className='flex align-center gap-2 space-between fill'>
                    <div className='flex align-center gap-2'>
                        <Avatar username={approver.username} />
                        <span>{approverHandle}</span>
                    </div>
                    <Tooltip2
                        minimal
                        placement='top'
                        popoverClassName='small-tooltip'
                        hoverOpenDelay={300}
                        content={(
                            <span className='font-size-12'>
                                Remove {approverHandle}
                            </span>
                        )}
                    >
                        <Button 
                            icon='trash' 
                            minimal 
                            small 
                            disabled={!canRemove}
                            onClick={handleRemove}
                        />
                    </Tooltip2>
                </div>
            </div>
        )
    })

    return (
        <div className='margin-top-2'>
            {approverBlocks}
        </div>
    )
}

export default ApproverList;