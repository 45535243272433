import { Icon } from '@blueprintjs/core';
import React from 'react';
import PositionedIcon from '../../../ui/PositionedIcon/PositionedIcon';
import { NEUTRAL_DARK, NEUTRAL_LIGHT } from '../../../../utils/colors';
import { Link } from 'react-router-dom';

const VotingStatus = ({
    votes,
    voters,
    supportNeeded,
    prStatus
}) => {

    const votersFor = voters.filter(v => v.stance === 'FOR');
    const votersAgainst = voters.filter(v => v.stance === 'AGAINST');
    const sortedVoters = [...votersFor, ...votersAgainst];
    const voterList = sortedVoters.map((v, i) => {
        return (
            <div key={i} className='mb-1'>
                <PositionedIcon 
                    top={v.stance === 'FOR' ? -1 : 0}
                    icon={v.stance === 'FOR' ? 'thumbs-up' : 'thumbs-down'} 
                    color={v.stance === 'FOR' ? NEUTRAL_DARK : NEUTRAL_LIGHT}
                    iconSize={12}
                /> 
                <span className='ml-2'>
                    <Link to={`/user/${v.user.username}`}>
                        {v.user.username}
                    </Link>
                </span>
            </div>
        )
    })

    return (
        <div className='VotingStatus'>
            {voters.length > 0 ? voterList : (
                <div className='text-gray-500'>
                    No votes yet
                </div>
            )}
        </div>
    )
}

export default VotingStatus;