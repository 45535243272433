import React from 'react';
import { Spinner } from '@blueprintjs/core';
import './GlobalSpinner.css';

const GlobalSpinner = (props) => {

    return (
        <div className='GlobalSpinner'>
            {/* <h1>Crowdwrite</h1> */}
            <Spinner size={24}/>
        </div>
    )
}

export default GlobalSpinner;