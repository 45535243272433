import React from 'react';
import './TeamError.css';

const TeamError = ({
    message
}) => {
    
    let content = `Something went wrong`;
    if (message.includes('UNAUTHORIZED')) content = `You don't have access`;

    return (
        <div className='TeamError'>
            <h1>{content}</h1>
        </div>
    );
}

export default TeamError;