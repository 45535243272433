import api from './apiClient';
import jwt_decode from 'jwt-decode';
import store from '../store/store';
import * as actions from '../store/actions/index';
import { apolloCache } from '..';
import { gql } from '@apollo/client';

export const localStorageKey = `__stele-token__`;

export const setLocalToken = (token) => {
    window.localStorage.setItem(localStorageKey, token);
}

export const getToken = () => {
    return window.localStorage.getItem(localStorageKey);
}

const getUser = () => {

    // check if the URL contains an invite token
    const queryParams = new URLSearchParams(window.location.search);
    
    const localToken = getToken();
    const decodedLocalToken = localToken ? jwt_decode(localToken) : null;
    
    const inviteToken = queryParams.get('invite');
    const decodedInviteToken = inviteToken ? jwt_decode(inviteToken) : null;

    let userID, tokenExpiry;
    const isNewUser = (decodedLocalToken && decodedLocalToken.newUser === true) || (inviteToken && !localToken);

    if (localToken && decodedLocalToken) {
        userID = decodedLocalToken.id;
        tokenExpiry = decodedLocalToken.exp;
    }

    if (inviteToken && !localToken) {
        userID = decodedInviteToken.id
        tokenExpiry = decodedInviteToken.exp;
    }

    
    const now = new Date();
    const expires = new Date(tokenExpiry * 1000);
    const hasExpired = expires < now;

    // if the user is new, encourage them to complete their account'
    if (isNewUser) {
        store.dispatch(actions.setUser({ _id: userID, newUser: true }));
        setLocalToken(inviteToken ? inviteToken : localToken);
        return false;
    }

    // -------------------------
    // if there is a valid token, refresh the user's session
    // otherwise do nothing

    if (!localToken) {
        return false;
    }

    // if the token has expired
    // the user will have to log in again
    if (hasExpired) {
        return false;
    }

    if (!isNewUser) {

        // otherwise, refresh the token and retrieve user data
        const refreshTokenAndGetUser = `
            mutation {
                refreshSignIn(userId: "${userID}") {
                    jwt
                    user {
                        _id
                        email
                        username
                        emailVerified
                        profilePicPath
                    }
                }
            }
        `

        return api(refreshTokenAndGetUser).then(handleSignInResponse);

    }

}

const handleSignInResponse = async ({ data, errors }) => {

    if (errors) {
    }

    if (data.refreshSignIn) {
        setLocalToken(data.refreshSignIn.jwt);
        store.dispatch(actions.setUser(data.refreshSignIn.user));
        apolloCache.writeFragment({
            id: `User:${data.refreshSignIn.user._id}`,
            fragment: gql`
                fragment loggedInUser on User {
                    __typename
                    _id
                    email
                    username
                    emailVerified
                }
            `,
            data: {
                __typename: 'User',
                _id: data.refreshSignIn.user._id,
                email: data.refreshSignIn.user.email,
                username: data.refreshSignIn.user.username,
                emailVerified: data.refreshSignIn.user.emailVerified
            }
        });
        return data.refreshSignIn.user;
    }

    // if nothing comes back, provide a general server error message
    return null;

}

const signOut = () => {
    window.localStorage.removeItem(localStorageKey);
    store.dispatch(actions.setUser(null));
}

export { getUser, signOut }