import React, { useState } from 'react';
import jwt_decode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import * as actions from '../../store/actions/index';
import { useNavigate, useParams } from 'react-router';
import { Button, Divider, FormGroup, InputGroup } from '@blueprintjs/core';
import GoogleLogin from 'react-google-login';
import GoogleLogo from '../ui/svg/GoogleLogo';
import { useMutation } from '@apollo/client';
import SIGN_UP_WITH_EMAIL from './mutations/signUpWithEmail';
import GOOGLE_SIGN_UP from './mutations/googleSignUp';
import { getToken } from '../../utils/authClient';
import JOIN_TEAM from './mutations/joinTeam';
import './Join.css';

const Join = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const setUser = (user) => dispatch(actions.setUser(user));

    const { token } = useParams(); // TODO: improve the name of this
    const { teamName, teamSlug, email, docID, docTitle } = jwt_decode(token);

    const [passwordInput, setPassword] = useState('');
    const changePasswordInput = (e) => setPassword(e.target.value);

    const [signUpWithEmail, { loading: signUpLoading }] = useMutation(SIGN_UP_WITH_EMAIL, {
        onCompleted: (data) => {
            if (data.signUpWithEmail.jwt) {
                window.localStorage.setItem(`__stele-token__`, data.signUpWithEmail.jwt);
                setUser(data.signUpWithEmail.user);
                const redirect = docID ? `/t/${teamSlug}/d/${docID}` : `/t/${teamSlug}`;
                navigate(redirect);
            }
        }
    });

    const [googleSignUp] = useMutation(GOOGLE_SIGN_UP, {
        onCompleted: (data) => {
            if (data.googleSignUp && data.googleSignUp.jwt) { 
                const { jwt } = data.googleSignUp; 
                // TODO: error handling
                window.localStorage.setItem(`__stele-token__`, jwt);
                setUser(data.googleSignUp.user);
                const redirect = docID ? `/t/${teamSlug}/d/${docID}` : `/t/${teamSlug}`;
                navigate(redirect);
            }
        }
    });

    const [signedInUserJoinTeam, { loading: signedInUserJoining }] = useMutation(JOIN_TEAM, {
        onCompleted: (data) => {
            const redirect = docID ? `/t/${teamSlug}/d/${docID}` : `/t/${teamSlug}`;
            if (data.joinTeam.error === `ALREADY_MEMBER`) navigate(redirect);
            if (data.joinTeam.jwt) {
                window.localStorage.setItem(`__stele-token__`, data.joinTeam.jwt);
                navigate(redirect);
            }
        }
    });
    
    const onSignUpWithEmail = () => {
        signUpWithEmail({
            variables: {
                input: {
                    invite: token,
                    email: email,
                    password: passwordInput
                }
            }
        })
    }

    const onGoogleSignUp = (res) => {
        googleSignUp({
            variables: {
                inviteToken: token,
                id_token: res.tokenObj.id_token,
                teamSlug: teamSlug
            }
        })
    }

    const onExistingUserJoin = () => {
        signedInUserJoinTeam({
            variables: {
                invite: token
            }
        });
    }

    const signedInUserToken = getToken();

    // TODO: add negative flows for...
    //  - expired invites

    return (
        <div className='Join'>
            <h1
                style={{
                    marginBottom: 20
                }}
            >
                Join <span>{teamName} <br/></span>
                {docTitle && (
                    <span style={{fontWeight:400}}>
                        to collaborate on<br/> <span className='primary-color'>{docTitle}</span>
                    </span>
                )}
            </h1>
            {!signedInUserToken && (
                <div className='NewUserJoinTeam'>
                    <div className='SingleSignOnOptions'>
                        <GoogleLogin 
                            clientId="512210275616-1t12n26rdgskev45mb76upv2e0m5p2bl.apps.googleusercontent.com"
                            buttonText="Join with Google"
                            onSuccess={onGoogleSignUp}
                            // onFailure={_onGoogleRejection}
                            cookiePolicy={'single_host_origin'}
                            responseType='id_token'
                            className='GoogleLogin'
                            render={renderProps => {
                                return (
                                    <Button
                                        onClick={renderProps.onClick}
                                        disabled={renderProps.disabled}
                                    >
                                        <GoogleLogo
                                            width={20}
                                            top={2}
                                            marginRight={7}
                                        />
                                        <span style={{
                                            position: 'relative',
                                            fontWeight: 400,
                                            fontSize: 16,
                                            top: -3
                                        }}>
                                            Join with Google
                                        </span>
                                    </Button>
                                )
                            }}
                        />
                    </div>
                    <p className='bp3-text-large'
                        style={{
                            fontSize: 16
                        }}
                    >
                        Or sign up with email and password
                    </p>
                    <p style={{
                        margin: '20px 0 5px'
                    }}>
                        <b style={{ fontWeight: 600 }}>Email:</b> {email}
                    </p>
                    <FormGroup
                        label='Password'
                        labelFor='password'
                    >
                        <InputGroup 
                            large 
                            id='password'
                            value={passwordInput}
                            onChange={changePasswordInput}
                            type='password'
                        />
                    </FormGroup>
                </div>
            )}
            <Button
                large
                intent='primary'
                text={signedInUserToken ? `Join` : `Join ${teamName}`}
                disabled={!signedInUserToken && passwordInput.length === 0}
                loading={signUpLoading || signedInUserJoining}
                onClick={signedInUserToken ? onExistingUserJoin : onSignUpWithEmail}
            />
        
        </div>
    )
}

export default Join;