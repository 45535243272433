import { gql } from "@apollo/client";

const CREATE_COMMENT = gql`
    mutation CreateComment($input: CreateCommentInput) {
        createComment(input: $input) {
            _id
            author {
                _id
                username
            }
            created
            blockID
            prID
            docID
            docTextID
            text
            highlightStart
            highlightEnd
        }
    }
`

export default CREATE_COMMENT;