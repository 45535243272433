import React, { useCallback, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import SignInHeader from "../../SignIn/SignInHeader/SignInHeader";
import { Button, Card, FormGroup, InputGroup, Spinner } from "@blueprintjs/core";
import isEmail from "validator/lib/isEmail";
import { useLazyQuery, useMutation } from "@apollo/client";
import SIGN_UP_WITH_EMAIL from "./mutations/signUpWithEmail";
import { setLocalToken } from "../../../utils/authClient";
import { useDispatch } from "react-redux";
import * as actions from '../../../store/actions/index';
import { setUser } from "../../../store/actions";
import UNIQUE_USERNAME from "../../SignUpModal/queries/uniqueUsername";
import _ from "lodash";
import { getUnacceptedInvites } from "../../../utils/localStorage";
import { apolloClient } from "../../../index";

const getErrorMessage = (error) => {
    let message;
    switch (error) {
        case 'EXISTING_USER': message = 'That email is already in use'; break;
        default: message = 'Sorry, we could not create an account';
    }
    return message;
}

const SignUp = ({
    toggleSignIn,
    handleClose
}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { docID } = useParams();

    const unacceptedInvites = getUnacceptedInvites();
    const inviteDocIDs = unacceptedInvites ? unacceptedInvites.map(id => id.split('-')[0]) : null;
    const userHasDocInvite = inviteDocIDs ? inviteDocIDs.includes(docID) : false;

    let activeDocInviteID;
    if (userHasDocInvite) {
        activeDocInviteID = unacceptedInvites.map(id => {
            return {
                docID: id.split('-')[0],
                inviteID: id.split('-')[1]
            }
        }).find(idObj => idObj.docID === docID).inviteID;
    }

    const handleToggleSignIn = () => {
        if (toggleSignIn) {
            toggleSignIn();
        }
        else {
            navigate('/sign-in');
        }
    }

    const [emailInput, setEmailInput] = useState('');
    const onSetEmail = (e) => setEmailInput(e.target.value);

    const [username, setUsername] = useState('');
    const [usernameError, setUsernameError] = useState('');

    const [passwordInput, setPasswordInput] = useState('');
    const onSetPasswordInput = (e) => setPasswordInput(e.target.value);

    const [confirmPasswordInput, setConfirmPasswordInput] = useState('');
    const onSetConfirmPasswordInput = (e) => setConfirmPasswordInput(e.target.value);

    const [signUpError, setSignUpError] = useState(false);

    const canSubmit = isEmail(emailInput) 
                        && passwordInput !== '' 
                        && passwordInput === confirmPasswordInput
                        && usernameError === '';

    const [checkUsername, { called, loading: checkingUsername, data: usernameStatus }] = useLazyQuery(UNIQUE_USERNAME,
        {
            fetchPolicy: 'network-only'
        }
    );

    const debouncedCheckUsername = useCallback(_.debounce(checkUsername, 500), []);
    
    const onChangeUsername = (e) => {
        const regex = /^[a-zA-Z0-9_]{1,32}$/;
        if (!regex.test(e.target.value) && e.target.value !== '') {
            return;
        }

        setUsername(e.target.value.trim());
        // setUsernameError('');
        debouncedCheckUsername({
            variables: {
                username: e.target.value
            },
            onCompleted: (data) => {
                if (data && data.uniqueUsername === true) {
                    setUsernameError('');
                }
                if (data && data.uniqueUsername === false) {
                    setUsernameError('TAKEN')
                }
            },
        });
    }


    const [signUpWithEmail, { loading, error, data }] = useMutation(SIGN_UP_WITH_EMAIL, {
        variables: {
            input: {
                email: emailInput,
                password: passwordInput,
                username: username
            }
        },
        onCompleted: (data) => {
            
            if (data && data.signUpWithEmail && data.signUpWithEmail.error) {
                setSignUpError(getErrorMessage(data.signUpWithEmail.error));
            }

            if (data && data.signUpWithEmail && data.signUpWithEmail.user) {
                setLocalToken(data.signUpWithEmail.jwt);
                dispatch(setUser(data.signUpWithEmail.user));

                if (userHasDocInvite) {
                    dispatch(actions.setInviteRedirect(location.pathname));
                    navigate(`/invite/${activeDocInviteID}`);
                }

                if (!userHasDocInvite) {
                    navigate(`/crowdwrite/clvzlyqus002yh8vi26e1gl26/text`);
                }

                if (handleClose) {
                    handleClose();
                }
            }

        }
    });

    const onSignUpWithEmail = () => {
        signUpWithEmail();
    }

    const onKeyDown = (e) => {
        if (canSubmit && e.key === 'Enter') {
            signUpWithEmail();
        }
    }

    let inputIcon;
    if (checkingUsername) { inputIcon = <Spinner size={18} /> }

    return (
        <div className='w-96 mx-auto'>
            <SignInHeader />
            <h3 className="text-xl mb-4 font-medium -mt-4 tracking-tight">Create an account</h3>
            {signUpError && (
                <Card className='border-0 bg-warning px-1 py-2 my-3 text-center shadow-none text-xs font-semibold tracking-tight'>
                    {signUpError}
                </Card>
            )}
            <FormGroup
            >
                <InputGroup 
                    id='email'
                    large
                    aria-label='Email'
                    placeholder='Email'
                    value={emailInput}
                    onChange={onSetEmail}
                    onKeyDown={onKeyDown}
                    type='email'
                />
            </FormGroup>

            <FormGroup
            >
                <InputGroup 
                    id='username'
                    large
                    aria-label='Username'
                    placeholder='Username'
                    value={username}
                    onChange={onChangeUsername}
                    onKeyDown={onKeyDown}
                    intent={usernameError !== '' ? 'danger' : null}
                    rightElement={inputIcon}
                    type='string'
                />
                {usernameError === 'TAKEN' && (
                    <p className='bp3-text-small margin-top-4 font-semibold tracking-tight text-warning'>
                        That username is taken
                    </p>
                )}
            </FormGroup>

            <FormGroup
            >
                <InputGroup 
                    id='password'
                    large
                    aria-label='Password'
                    placeholder='Password'
                    value={passwordInput}
                    onChange={onSetPasswordInput}
                    onKeyDown={onKeyDown}
                    type='password'
                />
            </FormGroup>

            <FormGroup
            >
                <InputGroup 
                    id='password'
                    large
                    aria-label='Confirm password'
                    placeholder='Confirm password'
                    value={confirmPasswordInput}
                    onChange={onSetConfirmPasswordInput}
                    onKeyDown={onKeyDown}
                    type='password'
                />
            </FormGroup>

            <div className='mt-5'>
                <Button
                    intent='primary'
                    text='Sign up'
                    disabled={!canSubmit}
                    onClick={onSignUpWithEmail}
                    loading={loading}
                />
            </div>

            <div className='mt-8 text-xs text-center'>
                Have an account? <span onClick={handleToggleSignIn}
                    className='font-semibold hover:no-underline cursor-pointer'>
                    Sign in
                </span>
            </div>
        </div>
    )
}

export default SignUp;