import React, { Fragment } from 'react';
import { Button } from '@blueprintjs/core';
import './EditorToolbar.css'; 
import BlockTypeDropdown from './BlockTypeDropdown/BlockTypeDropdown';
import { Tooltip2 } from '@blueprintjs/popover2';

const EditorToolbar = ({
    textControls,
    editorState,
    setEditorState,
    userIsAdmin,
    showLinkEditor
}) => {

    const _onClickBold = (e) => {
        e.preventDefault();
        textControls.bold.toggle()
    }

    const _onClickItalic = (e) => {
        e.preventDefault();
        textControls.italic.toggle()
    }

    const _onClickUnderline = (e) => {
        e.preventDefault();
        textControls.underline.toggle()
    }

    const _onClickCode = (e) => {
        e.preventDefault();
        textControls.code.toggle()
    }

    const _onClickLink = (e) => {
        e.preventDefault();
        showLinkEditor();
    }

    const _onClickLock = (e) => {
        e.preventDefault();
        textControls.locked.toggle()
    }

    const _onClickBlockquote = (e) => {
        e.preventDefault();
        textControls.blockType.toggle('blockquote');
    }

    const _onClickUnorderedList = (e) => {
        e.preventDefault();
        textControls.blockType.toggle('unordered-list-item');
    }

    const _onClickOrderedList = (e) => {
        e.preventDefault();
        textControls.blockType.toggle('ordered-list-item');
    }

    const _onChangeBlockType = (e, value) => {
        e.preventDefault();
        textControls.blockType.toggle(value);
    }

    return ( 
        <div className='EditorToolbar'>
            <div className='BlockTypeControls ControlGroup'>
                <BlockTypeDropdown
                    currentBlockType={textControls.currentBlockType}
                    onChangeBlockType={_onChangeBlockType}
                    editorState={editorState}
                    setEditorState={setEditorState}
                    disabled={!userIsAdmin && textControls.locked.status}
                />
            </div>
            <div className='InlineStyleControls ControlGroup'>
                <Button 
                    minimal small 
                    icon='bold' 
                    active={textControls.bold.status}
                    disabled={!userIsAdmin && textControls.locked.status} 
                    onMouseDown={_onClickBold}
                />
                <Button
                    minimal small 
                    icon='italic' 
                    active={textControls.italic.status} 
                    disabled={!userIsAdmin && textControls.locked.status} 
                    onMouseDown={_onClickItalic}
                />
                <Button 
                    minimal small 
                    icon='underline'
                    active={textControls.underline.status} 
                    disabled={!userIsAdmin && textControls.locked.status} 
                    onMouseDown={_onClickUnderline}
                />
            </div>
            <div className='ListControls ControlGroup'>
                <Button 
                    minimal small 
                    icon='properties' 
                    active={textControls.currentBlockType === `unordered-list-item`} 
                    disabled={!userIsAdmin && textControls.locked.status} 
                    onMouseDown={_onClickUnorderedList}
                />
                <Button
                    minimal small 
                    icon='numbered-list' 
                    active={textControls.currentBlockType === `ordered-list-item`} 
                    disabled={!userIsAdmin && textControls.locked.status} 
                    onMouseDown={_onClickOrderedList}
                />
            </div>

            <div className='ControlGroup'>
                <Tooltip2
                    placement='top'
                    content={(
                        <Fragment>
                            <p>Link</p>
                            <p className='tooltip-caption'>K</p>
                        </Fragment>
                    )}
                >
                    <Button 
                        minimal small 
                        icon='link' 
                        active={textControls.locked.status} 
                        onMouseDown={_onClickLink}
                    />
                </Tooltip2>
                {/* {userIsAdmin && (
                    <Tooltip2
                        placement='top'
                        content={(
                            <Fragment>
                                <p>Lock text</p>
                                <p className='tooltip-caption'>Others won't be able to edit these parts</p>
                            </Fragment>
                        )}
                    >
                        <Button 
                            minimal small 
                            icon='lock' 
                            active={textControls.locked.status} 
                            onMouseDown={_onClickLock}
                        />
                    </Tooltip2>
                )} */}
            </div>

        </div>
    )
}

export default EditorToolbar;