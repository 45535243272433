import gql from "graphql-tag";

const GOOGLE_SIGN_IN = gql`
    mutation GoogleSignIn($id_token: String, $docID: String) {
        googleSignIn(id_token: $id_token, docID: $docID) {
            jwt
            user {
                _id
                email
            }
            error
        }
    }
`

export default GOOGLE_SIGN_IN;