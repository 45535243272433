import gql from "graphql-tag";

const GOOGLE_SIGN_UP = gql`
    mutation GoogleSignUp($inviteToken: String, $id_token: String, $teamSlug: String) {
        googleSignUp(inviteToken: $inviteToken, id_token: $id_token, teamSlug: $teamSlug) {
            jwt
            user {
                _id
                email
            }
            error
        }
    }
`

export default GOOGLE_SIGN_UP;