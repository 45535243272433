import { gql } from "@apollo/client";

const UPDATE_PROFILE = gql`
    mutation UpdateProfile($input: UpdateProfileInput) {
        updateProfile(input: $input) {
            _id
            username
            name
            title
            location
            link
            bio
            profilePicPath
        }
    }
`

export default UPDATE_PROFILE;