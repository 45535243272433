import { useMutation } from '@apollo/client';
import { Button, Dialog, Divider, Icon } from '@blueprintjs/core';
import React, { Fragment, useState } from 'react';
import DocSettingsEditor from '../../SnippetEditor/DocSettingsEditor/DocSettingsEditor';
import { getProcessInput } from '../../SnippetEditor/DocSettingsEditor/processInputUtils';
import Modal from '../../ui/Modal/Modal';
import { SteleToaster } from '../../ui/Toaster/Toaster';
import DeadlineCallout from './DeadlineCallout/DeadlineCallout';
import UPDATE_DOC_SETTINGS from './mutations/updateDocSettings';
import './ProtocolCard.css';
import DELETE_DOC from './mutations/deleteDoc';
import { useNavigate } from 'react-router';
import ModalTopBar from '../../ui/ModalTopBar/ModalTopBar';

const getApprovalsPhrase = (control) => {
    // TODO: redesign this to handle multiple approvers
    let semanticApproversList = '';
    if (control.approvers.length === 1) {
        semanticApproversList = control.approvers[0].username;
    }

    if (control.approvers.length === 2) {
        semanticApproversList = `${control.approvers[0].username} & ${control.approvers[1].username}`
    }

    if (control.approvers.length > 2) {
        control.approvers.map((approver, i) => {
            if (i < control.approvers.length - 2) {
                semanticApproversList = semanticApproversList.concat(`${approver.username}, `)
            }
            if (i === control.approvers.length - 2) {
                semanticApproversList = semanticApproversList.concat(`${approver.username} `)
            }
            if (i === control.approvers.length - 1) {
                semanticApproversList = semanticApproversList.concat(`and ${approver.username}`)
            }
        })
    }

    return (
        <span>
            Suggestions can be approved by {semanticApproversList}
            <span className='half-opaque'> ({control.approvalCount} approval{control.approvalCount > 1 ? 's' : ''} needed)</span>
        </span>
    );
}

const getVotingPhrase = (control) => {
    return `Suggestions get accepted when they have ${control.supportNeeded} more upvotes than downvotes`
}

// TODO: redesign this for multiple controls when multi-control support is built out
const getProtocolPhrase = (controls) => {
    const control = controls[0];
    switch (control.processType) {
        case 'VOTING': return getVotingPhrase(control);
        case 'APPROVALS': return getApprovalsPhrase(control);
        case 'OPEN': 
        default: return 'Anyone can edit this document';
    }
}

const ProtocolCard = ({
    docID,
    process,
    deadline,
    userCanEdit,
    userId,
    docTitle,
    owner,
    participateAccess,
    inviteID
}) => {

    const navigate = useNavigate();

    const [showingProtocolModal, toggleProtocolModal] = useState(false);
    const showProtocolModal = () => toggleProtocolModal(true);
    const hideProtocolModal = () => toggleProtocolModal(false);
    
    const protocolPhrase = getProtocolPhrase(process.controls);

    const [supportNeeded, setSupportNeeded] = useState(5);
    const onChangeSupportNeeded = (supportNeeded) => setSupportNeeded(supportNeeded);

    const [timerOption, setTimerOption] = useState(deadline ? 'UNTIL_DATE' : 'FOREVER');
    const onSetTimerOption = (value) => setTimerOption(value);

    const existingDeadline = deadline ? new Date(deadline) : null;
    const currentDate = new Date();
    const defaultDeadlineDate = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
    const [deadlineDate, setDeadlineDate] = useState(existingDeadline ? existingDeadline : defaultDeadlineDate);
    const onSetDeadlineDate = (date) => setDeadlineDate(date);

    const [viewAccess, setViewAccess] = useState('ANYONE_WITH_LINK');
    const onSetViewAccess = (value) => setViewAccess(value);

    const [suggestAccess, setSuggestAccess] = useState('ANYONE_WITH_LINK');
    const onSetSuggestAccess = (value) => setSuggestAccess(value);


    const [deleteDoc, { loading: deletingDoc, error: deleteError, data: deleteData }] = useMutation(DELETE_DOC, {
        onCompleted: () => {
            SteleToaster.show({ message: 'Document deleted' });
            hideProtocolModal();
            navigate('/docs');
        }
    });

    if (deleteError) {
        SteleToaster.show({
            message: `Sorry, something's not right — please try again later`,
            intent: 'danger'
        })
    }

    const onDeleteDoc = () => {
        deleteDoc({
            variables: {
                docID: docID
            }
        })
    }

    return (
        <Fragment>
            <div className='ProtocolCard'>
                <div className='EditingProtocolSummary ProtocolCallout'>
                    <Icon icon='changes' />
                    <div className='CalloutInfo'>
                        <p className='no-margin bold'>How changes get made</p>
                        <p className='no-margin'>{protocolPhrase}</p>
                        {participateAccess === 'INVITE_LINK' && <p className='no-margin'>Anyone with an invite can make suggestions</p>}
                        {(!participateAccess || participateAccess === 'PUBLIC') && <p className='no-margin'>Anyone can make suggestions</p>}
                    </div>
                    {userCanEdit && owner._id === userId && (
                        <Button small
                            onClick={showProtocolModal}
                            className='primary-neutral-btn font600 EditProtocolBtn'
                            text='Edit'
                        />
                    )}
                </div>
                {deadline && <DeadlineCallout deadline={deadline}/>}
            </div>
            <Dialog
                isOpen={showingProtocolModal}
                onClose={hideProtocolModal}
                transitionName='fade'
                className='cw-modal'
            >
                <DocSettingsEditor
                    supportNeeded={supportNeeded}
                    onChangeSupportNeeded={onChangeSupportNeeded}
                    deadline={deadlineDate}
                    viewAccess={viewAccess}
                    onSetViewAccess={onSetViewAccess}
                    suggestAccess={suggestAccess}
                    onSetSuggestAccess={onSetSuggestAccess}
                    timerOption={timerOption}
                    onSetTimerOption={onSetTimerOption}
                    deadlineDate={deadlineDate}
                    onSetDeadlineDate={onSetDeadlineDate}
                    docTitle={docTitle}
                    owner={owner}
                    docProcess={process}
                    onClose={hideProtocolModal}
                    participateAccess={participateAccess}
                    inviteID={inviteID}
                    docID={docID}
                    userId={userId}
                />
            </Dialog>
        </Fragment>
    )

}

export default ProtocolCard;