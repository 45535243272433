import gql from "graphql-tag";

const SNIPPET_DOC = gql`
    query SnippetDoc($docID: String, $teamSlug: String) {
        doc(docID: $docID) {
            _id
            docID
            docTextID
            team
            title
            description
            creator {
                _id
                username
                name
                profilePicPath
            }
            contributors {
                _id
                username
                orbDeg
                profilePicPath
            } 
            created
            lastEdited
            lastEditor {
                _id
                username
            }
            activeProposals
            totalProposals
            totalComments
            totalTopLevelComments
            versionCount
            arweaveID
            deadline
            participateAccess
            userAccess
            inviteID
            text { 
                key
                text
                _id
                depth
                type
                inlineStyleRanges {
                    length
                    offset
                    style
                }
                entityRanges {
                    length
                    offset
                    key
                }
            }
            entityMap {
                type
                mutability
                key
                data {
                    url
                }
            }
            process {
                creatorCanEdit
                creatorCanChangeSettings
                controls {
                    processType
                    supportNeeded
                    approvers {
                        _id
                        username
                        name
                        profilePicPath
                    }
                    approvalCount
                }
            }
        }
        team(slug: $teamSlug) {
            _id
            name
            slug
            members {
                email
            }
        }
    }
`

export default SNIPPET_DOC;