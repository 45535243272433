import { Dialog } from '@blueprintjs/core';
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import SignUpModal from '../../SignUpModal/SignUpModal';
import EntryModal from '../../EntryModal/EntryModal';

const SignInPrompter = ({
    children,
    onClick
}) => {

    const [signInModalOpen, toggleSignInModal] = useState(false);
    const onOpenModal = () => toggleSignInModal(true);
    const onCloseModal = () => toggleSignInModal(false);

    const [entryWay, setEntryWay] = useState('SIGN_UP');

    const user = useSelector(state => state.auth.user);
    if (user && user._id && user.username) {
        return children;
    }

    const handleClick = () => {
        onOpenModal()
    }

    return (
        <Fragment>
            { React.cloneElement( children, { onClick: handleClick } ) }
            <EntryModal
                user={user}
                isOpen={signInModalOpen}
                handleClose={onCloseModal}
                entryWay={entryWay}
                setEntryWay={setEntryWay}
            />
        </Fragment>
    )
}

export default SignInPrompter;