import gql from "graphql-tag"

// TODO: refactor or eliminate duplicate PROPOSAL query in ProposalModal

const DECISION_MAKER_VOTES = gql`
    query UserVotes($prID: String, $users: [String]) {
        userVotes(prID: $prID, users: $users) {
            prID
            user {
                _id
                username
            }
            stance
        }
    }
`

export default DECISION_MAKER_VOTES;