import { Tag } from "@blueprintjs/core";
import React from "react";

const StatusTag = ({
    status
}) => {

    let label;
    let intent;
    switch (status) {
        case 'ACCEPTED': 
            label = 'Accepted'; 
            intent = 'success';
            break;
        case 'CLOSED': 
            label = 'Closed'; 
            intent = 'danger';
            break;
        case 'ACTIVE': 
        default: 
            label = 'Active'; 
            break;
    }

    return <Tag intent={intent}>{label}</Tag>

}

export default StatusTag;