import { gql } from "@apollo/client";

const DOC_REACTIONS = gql`
    query DocReactions($docID: String) {
        reactions(docID: $docID) {
            contextID
            emoji
            count
        }
    }
`

export default DOC_REACTIONS;