import React from 'react';

const TwitterLogo = ({
    width = 16,
    height = 16
}) => {
    return (
        <svg 
            width={width} 
            height={height} 
            viewBox="0 0 136 136" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M117.602 41.3608C117.682 42.5096 117.682 43.6584 117.682 44.8179C117.682 80.1455 90.6436 120.889 41.2021 120.889V120.868C26.5969 120.889 12.2952 116.728 0 108.882C2.12371 109.136 4.25807 109.263 6.39774 109.268C18.5013 109.279 30.2589 105.24 39.781 97.8014C28.2789 97.5843 18.1926 90.1249 14.669 79.2349C18.6982 80.0079 22.8498 79.849 26.8045 78.7743C14.2645 76.2543 5.24274 65.2955 5.24274 52.5684C5.24274 52.452 5.24274 52.3408 5.24274 52.2296C8.97919 54.2996 13.1627 55.4484 17.4421 55.5755C5.63129 47.7243 1.99065 32.0961 9.1229 19.8773C22.77 36.5802 42.9053 46.7343 64.5203 47.809C62.354 38.5232 65.3134 28.7926 72.2966 22.2649C83.1227 12.1426 100.15 12.6614 110.326 23.4243C116.346 22.2437 122.116 20.0467 127.396 16.9337C125.389 23.1226 121.19 28.3796 115.58 31.7202C120.908 31.0955 126.113 29.6767 131.015 27.5114C127.407 32.8902 122.861 37.5755 117.602 41.3608Z" 
                fill="#1D9BF0"
            />
        </svg>
    )
}

export default TwitterLogo;