import { gql } from "@apollo/client";

const CACHED_VOTE = gql`
    fragment MyVote on Vote {
        _id
        user {
            _id
        }
        prID
        stance
    }
`

const getCachedVote = (cache, prID, userID) => {
    const cachedVote = cache.readFragment({
        id: cache.identify({
            __typename: 'Vote',
            prID: prID,
            user: {
                __ref: `User:${userID}`
            }
        }),
        fragment: CACHED_VOTE
    });
    if (!cachedVote || !cachedVote.stance) return false;
    return cachedVote;
}

export default getCachedVote;