import React from 'react';
import { Link } from 'react-router-dom';
import './DocCard.css';
import { DateTime } from 'luxon';

const DocCard = ({
    team,
    title,
    docID,
    activeProposals,
    created,
    lastEdited,
    versionCount,
    contributors
}) => {

    const dateToUse = lastEdited ? lastEdited : created;
    const lastEditedDate = DateTime.fromISO(dateToUse);
    let formattedDate = lastEditedDate.toRelativeCalendar();
    
    const recentRelativeStrings = [
        'today',
        'yesterday',
        '2 days ago'
    ]

    if (!recentRelativeStrings.includes(formattedDate)) {
        formattedDate = lastEditedDate.toLocaleString(DateTime.DATE_FULL);
    }

    const contributorCount = contributors.length;
    return (
        <Link to={`/${team}/${docID}`} className='no-deco DocCard'>
            <div className='DocCardContent'>
                <div className='UpperCardGroup'>
                    <h3 className='margin-bottom-0'>
                        {title}
                    </h3>
                </div>
                <div className='LowerCardGroup'>
                    <p>Last edited {formattedDate}</p>
                    <p>{versionCount > 0 ? versionCount : 1} edit{versionCount > 1 && 's'} by {contributorCount > 0 ? contributorCount : 1} contributor{contributorCount > 1 && 's'}</p>
                </div>
            </div>
        </Link>
    );

}

export default DocCard;