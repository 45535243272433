import React from 'react';
import { useAsync } from 'react-async';
import bootstrapAppData from '../utils/bootstrapAppData';
import * as authClient from '../utils/authClient';
import GlobalSpinner from '../components/ui/GlobalSpinner/GlobalSpinner';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import SignIn from '../components/SignIn/SignIn';

const AuthContext = React.createContext();

const AuthProvider = (props) => {

    const signOut = () => authClient.signOut();
    const location = useLocation();
    console.log('location', location);

    const activeUser = useSelector(state => state.auth.user);

    const {
        data,
        // error,
        // isRejected,
        isPending,
        // isSettled,
        // reload
    } = useAsync({
        promiseFn: bootstrapAppData
    })

    if (location.pathname === '/') {
        return null;
    }

    if (location.pathname === '/forgot'
        || location.pathname.includes(`/reset-password`)
        || location.pathname.includes(`/sign-in`)
        || location.pathname.includes(`/sign-up`)) {
        return <AuthContext.Provider value={{data, isPending, signOut}} {...props} />
    }

    if (isPending) {
        return <GlobalSpinner />
    }

    console.log('activeUser', activeUser);
    // if (activeUser && !activeUser._id) {
    //     return <SignIn />
    // }

    return <AuthContext.Provider value={{data, isPending, signOut}} {...props} />

}

const useAuth = () => React.useContext(AuthContext)

export { AuthProvider, useAuth }
