import React, { Fragment } from 'react';
import UserMultiSelect from '../../../ui/UserMultiSelect/UserMultiSelect';
import { Divider, NumericInput } from '@blueprintjs/core';
import ApproverList from '../ApproverList/ApproverList';

const ApprovalsEditor = ({
    approversToAdd,
    setApproversToAdd,
    onAddApprovers,
    editableApprovers,
    editableApprovalCount,
    onChangeApprovalCount,
    onRemoveApprover,
    isOpen
}) => {

    if (!isOpen) {
        return null;
    }

    return (
        <div>
            <div className='modal-padded-section'>
                <p className='font-size-12 font600'>Approvals</p>
                <p className='margin-bottom-4 font-size-12'>Any edits need to be approved by</p>
                <div className='margin-bottom-16'>
                    <UserMultiSelect
                        label=''
                        labelFor='approver-select'
                        actionLabel='Add'
                        selectedUsers={approversToAdd}
                        setSelectedUsers={setApproversToAdd}
                        onAction={onAddApprovers}
                    />
                </div>
                {editableApprovers.length > 1 && (
                    <div className='flex align-center gap-2 margin-top-16'>
                        <NumericInput
                            value={editableApprovalCount}
                            onValueChange={onChangeApprovalCount}
                            buttonPosition='none'
                            className='inline-numeric 2digit'
                        />
                        <span>of the following</span>
                    </div>
                )}
                <ApproverList
                    approvers={editableApprovers}
                    onRemoveApprover={onRemoveApprover}
                />
            </div>
            <Divider />
        </div>
    )
}

export default ApprovalsEditor;