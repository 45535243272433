import { gql } from "@apollo/client";

const JOIN_TEAM = gql`
    mutation JoinTeam($invite: String) {
        joinTeam(invite: $invite) {
            jwt
            error
        }
    }
`

export default JOIN_TEAM;