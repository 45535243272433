import React from 'react';
import VotingStatus from './VotingStatus/VotingStatus';
import './CurrentResults.css';
import ApprovalStatus from './ApprovalStatus/ApprovalStatus';

const listUsers = (voters) => {
    if (voters.length === 1) return voters[0].user.username;
    if (voters.length === 2) return `${voters[0].user.username} & ${voters[1].user.username}`
    if (voters.length === 3) return `${voters[0].user.username}, ${voters[1].user.username} & ${voters[2].user.username}`
    if (voters.length > 3) {
        const votersExceptLast = voters.slice(0,voters.length-1).map(voter => voter.user.username).join(', ');
        return `${votersExceptLast} & ${voters[voters.length-1].user.username}`;
    }
}

const CurrentResults = ({
    prID,
    process,
    prStatus,
    votes,
    voters,
    supportNeeded
}) => {

    // TODO: revisit this when adding support for combining controls

    const controlResults = process.controls.map((control, i) => {
        if (control.processType === 'VOTING') {
            return (
                <VotingStatus 
                    key={i}
                    votes={votes}
                    voters={voters}
                    supportNeeded={control.supportNeeded}
                    prStatus={prStatus}
                />
            )
        }
        if (control.processType === 'APPROVALS') {
            const approverIDs = control.approvers.map(approver => approver._id);
            return (
                <ApprovalStatus 
                    key={i}
                    prID={prID}
                    votes={votes}
                    decisionMakers={approverIDs}
                    approvalCount={control.approvalCount}
                    prStatus={prStatus}
                />
            )
        }
    })

    return (
        <div className={prStatus === 'ACTIVE' ? 'CurrentResults' : 'CurrentResults' }>
            {prStatus === 'ACTIVE' ? (
                <div className='mt-1'></div>
            ) : (
                <h5 className='mt-0 mb-1'>
                    Voters
                </h5>
            )}
            {controlResults}
        </div>
        
    )
}

export default CurrentResults;