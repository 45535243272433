import fetch from 'isomorphic-fetch';

const api = (query, ...customConfig) => {

    const token = window.localStorage.getItem('__stele-token__');
    const headers = { 'content-type': 'application/json' };
    
    if (token) {
        headers.authorization = `Bearer ${token}`
    }

    const config = {
        method: 'POST',
        ...customConfig,
        headers: {
            ...headers,
            ...customConfig.headers
        }
    }
    
    if (query) config.body = JSON.stringify({query: query});
    return fetch(`${process.env.REACT_APP_API_URL}`, config)
        .then(res => res.json())

}

export default api;
