import { CompositeDecorator } from "draft-js";
import DraftLink from "../DraftLink/DraftLink";

function findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        return (
          entityKey !== null &&
          contentState.getEntity(entityKey).getType() === 'LINK'
        );
      },
      callback
    );
}

const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: DraftLink,
    }
]);

export default decorator;