import { Tooltip2 } from '@blueprintjs/popover2';
import React from 'react';
import { Link } from 'react-router-dom';
import './Authorship.css';
import Avatar from '../../ui/Avatar/Avatar';

const makeRadialGradient = () => {
    // const colours = [`#a8eeca`, `#391196`, `#3f3fe0`];
    // const colours = ['#f5e89b', '#3056fc', '#e03f77', `#1812c1`, `#673fe0`]
    const colours = [
        `#f5e89b`,
        `#1812c1`,
        `#673fe0`,
        `#3056fc`,
        `#06103a`,
        `#e03f77`
    ]
    const firstColour = colours[Math.floor(Math.random()*colours.length)];
    const secondColour = colours[Math.floor(Math.random()*colours.length)];
    const thirdColour = colours[Math.floor(Math.random()*colours.length)];
    const firstPos = Math.floor(Math.random()*40);
    const secondPos = Math.floor(Math.random()*40) + 40;
    const linear = `linear-gradient(to bottom left, ${firstColour} ${firstPos}%, ${secondColour} ${secondPos}%, ${thirdColour} 100%)`;
    // const gradient = `radial-gradient(circle at ${xPos}px ${yPos}px, ${firstColour}, ${secondColour}, ${thirdColour})`;
    return linear;
}

const Authorship = ({
    team,
    contributors
}) => {

    const avatars = contributors.map((contributor, i) => {

        const style = { 
            position: 'relative',
            zIndex: contributors.length - i,
            // transform: `rotate(${rotation}deg)`
        }

        return (
            <Tooltip2 
                className='small-tooltip'
                content={contributor.username} 
                placement='top'
                key={i}
            >
                <div style={style}>
                    <Avatar
                        size='SMALL'
                        huddled
                        username={contributor.username}
                        profilePicPath={contributor.profilePicPath}
                    />
                </div>
            </Tooltip2>
        )
    })

    return (
        <div className='margin-left-20 margin-right-20 Authorship'>
            <Link to={`/user/${team.slug}`}>
                <p className='large-text font600'>
                    {team.name}
                </p>
            </Link>
            <div className='margin-left-8 avatars'>
                {avatars}
            </div>
        </div>
    )
}

export default Authorship;