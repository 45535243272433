import React, { useState } from 'react';
import { Button, TextArea } from '@blueprintjs/core';
import './EditComment.css';
import { useMutation } from '@apollo/client';
import EDIT_COMMENT from '../mutations/editComment';

const EditComment = ({
    text,
    userId,
    commentID,
    onClose
}) => {

    const [editedText, setEditedText] = useState(text);
    const onChangeText = (e) => setEditedText(e.target.value);

    const [ editComment, { loading, error, data }] = useMutation(EDIT_COMMENT);

    const onEditComment = () => {
        editComment({
            variables: {
                input: {
                    commentID: commentID,
                    text: editedText
                }
            },
            onCompleted: () => {
                onClose();
            }
        })
    }

    const noContent = editedText === '' || editedText.trim().length === 0;
    const onKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (!noContent) onEditComment();
        }
    }

    return (
        <div className='EditComment'>
            <TextArea 
                fill
                growVertically={true}
                value={editedText}
                onChange={onChangeText}
                onKeyDown={onKeyDown}
            />
            <div className='CommentControls'>
                <Button 
                    minimal
                    className='margin-right-4 opacity-80'
                    small
                    text='Cancel'
                    onClick={onClose}
                />
                <Button
                    small
                    intent='primary'
                    text='Save'
                    onClick={onEditComment}
                    loading={loading}
                />
            </div>
        </div>
    )
}

export default EditComment;