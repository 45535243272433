import { Spinner } from '@blueprintjs/core';
import React, { useEffect, useRef, useState } from 'react';
import ThreadAddComment from '../ThreadAddComment/ThreadAddComment';

const ThreadContentLoading = ({
    threadID,
    docTextID,
    docID,
    xPos,
    leftPos,
    yPos
}) => {

    const [height, setHeight] = useState(0);

    const containerLoadingRef = useRef(null);

    useEffect(() => {
        setHeight(containerLoadingRef.current.scrollHeight);
    });

    let threadStyles = {
        position: 'fixed',
        top: yPos - 40,
        left: leftPos
    }
    const overflow = yPos + height - window.innerHeight;
    if (overflow > 0) {
        threadStyles = {
            position: 'fixed',
            top: yPos - overflow - 40,
            left: leftPos
        }
    }

    return (
        <div className='ThreadContainer'
            ref={containerLoadingRef}
            style={threadStyles}
        >
            <div className='padding-top-32 padding-bottom-32'>
                <Spinner 
                    size={25}
                />
            </div>
            <ThreadAddComment
                threadID={threadID}
                docTextID={docTextID}
                docID={docID}
            />
        </div>
    )
}

export default ThreadContentLoading;