import { useQuery } from '@apollo/client';
import { Spinner } from '@blueprintjs/core';
import React from 'react';
import Comment from '../../Doc/DocProposals/ProposalModal/DocTextDiff/BlockDiff/BlockFeedback/Comment/Comment';
import CommentEditor from '../../Doc/DocProposals/ProposalModal/DocTextDiff/BlockDiff/CommentEditor/CommentEditor';
import DOC_COMMENTS from './queries/docComments';
import './DocComments.css';
import InfiniteScroll from 'react-infinite-scroller';

const DocComments = ({
    docID,
    totalTopLevelComments,
    activeUser
}) => {

    const { loading, error, data, fetchMore } = useQuery(DOC_COMMENTS, {
        variables: {
            docID: docID,
            offset: 0,
            limit: 10
        },
        nextFetchPolicy: 'cache-only'
    });

    const onLoadMore = () => {
        fetchMore({ 
            variables: { 
                offset: data.doc.comments.length 
            }
        })
    };

    if (error) return 'error';
    if (loading) return null;

    let commentFeed = <Spinner size={24} />
    if (data && data.doc.comments) {
        commentFeed = data.doc.comments.map((comment, i) => {
            return (
                <Comment 
                    key={i}
                    activeUser={activeUser}
                    {...comment}
                />
            )
        })
    }

    return (
        <div className='DocComments'>
            <CommentEditor 
                refetchQueries={{query: DOC_COMMENTS}}
            />
            <InfiniteScroll 
                pageStart={0}
                loadMore={onLoadMore}
                hasMore={data && data.doc.comments && data.doc.comments.length < totalTopLevelComments}
                loader={<Spinner size={25} />}
            >
                {commentFeed}
            </InfiniteScroll>
        </div>
    )
}

export default DocComments;