import * as actionTypes from '../actions/actionTypes';
import { updateObject, addToArray } from '../utility';

const initialState = {
    user: {
        _id: null,
        email: "",
    },
}

const setUser = (state, action) => {
    return updateObject(state, { user: action.user });
}

const setLoginRedirect = (state, action) => {
    return updateObject(state, { loginRedirect: action.path });
}

const setInviteRedirect = (state, action) => {
    return updateObject(state, { inviteRedirect: action.path });
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_USER: return setUser(state, action);
        case actionTypes.SET_LOGIN_REDIRECT: return setLoginRedirect(state, action);
        case actionTypes.SET_INVITE_REDIRECT: return setInviteRedirect(state, action);
        default: return state;
    }
}

export default reducer;