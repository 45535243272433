export const cacheOptions = {
    typePolicies: {
        Doc: {
            keyFields: ['docID'],
            fields: {
                comments: {
                    // keyArgs is necessary here for managing apollo cache
                    // this tells the cache to keep separately paginated comment data all in one array per doc
                    // without it, each page of comments would be stored in a separately cached array
                    // (hard to access)
                    keyArgs: ['docID'],
                    merge(existing = [], incoming = []) {
                        return [...existing, ...incoming];
                    }
                }
            }
        },
        Vote: {
            keyFields: ['user', 'prID']
        },
        DocDiffBlock: {
            keyFields: ['_id', 'key', 'contextualID']
        },
        Query: {
            fields: {
                proposals: {
                    keyArgs: ['sort', 'docID'],
                    merge(existing, incoming, { args: { offset = 0 }}) {
                        const merged = existing ? existing.slice(0) : [];
                        for (let i = 0; i < incoming.length; ++i) {
                            merged[offset + i] = incoming[i];
                        }
                        return merged;
                    },
                },
                comments: {
                    keyArgs: ['prID'],
                    merge(existing, incoming, { args: { offset = 0 }}) {
                        const merged = existing ? existing.slice(0) : [];
                        for (let i = 0; i < incoming.length; ++i) {
                            merged[offset + i] = incoming[i];
                        }
                        return merged;
                    }
                },
                activity: {
                    keyArgs: ['user'],
                    merge(existing, incoming, { args: { offset = 0 }}) {
                        const merged = existing ? existing.slice(0) : [];
                        for (let i = 0; i < incoming.length; ++i) {
                            merged[offset + i] = incoming[i];
                        }
                        return merged;
                    },
                },
            }
        }
    }
}