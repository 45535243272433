import gql from "graphql-tag";

const CREATE_VOTE = gql`
    mutation CreateVote($input: VoteInput) {
        createVote(input: $input) {
            _id
            prID
            user {
                _id
            }
            stance
            prStatus
        }
    }
`

export default CREATE_VOTE