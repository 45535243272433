import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation, useParams } from 'react-router';
import getCachedProposal from '../../../cache/readUtils/getCachedProposal';
import { SteleToaster } from '../../ui/Toaster/Toaster';
import getCachedVote from '../../../cache/readUtils/getCachedVote';
import StringDiff from '../../Doc/DocProposals/ProposalModal/DocTextDiff/BlockDiff/StringDiff/StringDiff';
import ProposalTabs from './ProposalTabs/ProposalTabs';
import ProposalHeader from './ProposalHeader/ProposalHeader';
import ProposalSkeleton from './ProposalSkeleton';
import ProposalBody from './ProposalBody/ProposalBody';
import './SnippetProposal.css'

import PROPOSAL from '../../Proposal/queries/proposal';
import CLOSE_PROPOSAL from './mutations/closeProposal';
import ACCEPT_PROPOSAL from './mutations/acceptProposal';
import CREATE_VOTE from '../../Proposal/mutations/createVote';
import DELETE_VOTE from '../../Proposal/mutations/deleteVote';

const getCanApproveProposal = (doc, currentUser) => {

    if (doc.process.controls[0].processType === 'OPEN' && currentUser._id) {
        return true;
    }

    if (currentUser._id === doc.creator._id) {
        return true;
    }

    else return false;

}

const getCanCloseProposal = (doc, currentUser, proposalAuthorId) => {

    if (doc.process.controls[0].processType === 'OPEN' && currentUser._id) {
        return true;
    }

    if (currentUser._id === proposalAuthorId || currentUser._id === doc.creator._id) {
        return true;
    }

    else return false;

}

const SnippetProposal = ({
    canParticipate
}) => {

    const { teamSlug, docID, prID, prTab } = useParams();

    const location = useLocation();
    const user = useSelector(state => state.auth.user);
    const { loading, error, previousData, data = previousData } = useQuery(PROPOSAL, {
        variables: {
            docID: docID,
            prID: prID
        }
    });

    const [closeProposal, { 
        loading: closingProposal, 
        error: closePRError, 
        data: closedProposal 
    }] = useMutation(CLOSE_PROPOSAL);
    const onCloseProposal = () => {
        closeProposal({
            variables: {
                prID: prID,
                docID: docID
            },
            onCompleted: () => {
                SteleToaster.show({
                    className: 'dark-toast',
                    message: `This proposal is closed`,
                })
            }
        })
    } 

    const [acceptProposal, { 
        loading: acceptingProposal, 
        error: acceptPRError, 
        data: acceptedProposal 
    }] = useMutation(ACCEPT_PROPOSAL);
    const onAcceptProposal = () => {
        acceptProposal({
            variables: {
                prID: prID,
                docID: docID
            },
            update(cache, response) {
                cache.modify({
                    id: cache.identify({ __typename: 'Doc', docID: docID }),
                    fields: {
                        title(textRefs, { DELETE }) { return DELETE },
                        text(textRefs, { DELETE }) { return DELETE }
                    }
                });
            },
            onCompleted: () => {
                SteleToaster.show({
                    className: 'dark-toast',
                    message: `This proposal has been accepted`,
                })
            }
        })
    } 

    const [createVote, { loading: voteIsLoading }] = useMutation(CREATE_VOTE, {
        update(cache, response) {
            const proposalID = `Proposal:${prID}`;
            cache.modify({
                id: proposalID,
                fields: {
                    votes(cachedVotes) { 
                        const { stance } = response.data.createVote
                        if (stance === 'AGAINST') return {
                            ...cachedVotes,
                            against: cachedVotes.against + 1,
                            net: cachedVotes.net - 1,
                            total: cachedVotes.total + 1
                        };
                        return {
                            ...cachedVotes,
                            for: cachedVotes.for + 1,
                            net: cachedVotes.net + 1,
                            total: cachedVotes.total + 1
                        }
                    }
                }
            });
            const cachedProposal = getCachedProposal(cache, prID);
            // if this vote causes the proposal to pass, update the doc text in the cache
            if (response.data.createVote.prStatus === 'ACCEPTED') {
                // updateDocProposalCount(cache, docID, 'DOWN');
                cache.modify({
                    id: proposalID,
                    fields: {
                        status(cachedStatus) { return 'ACCEPTED' },
                        changes(cachedChanges) { 
                            return cachedProposal.diff 
                        }
                    }
                });
                cache.modify({
                    id: cache.identify({ __typename: 'Doc', docID: docID }),
                    fields: {
                        text(textRefs, { DELETE }) {
                            return DELETE
                        }
                    }
                });
                SteleToaster.show({
                    message: `Proposal #${cachedProposal.number} has been accepted`,
                    icon: 'tick'
                })
            }
        }
    });

    const [deleteVote, { loading: deleteVoteIsLoading }] = useMutation(DELETE_VOTE, {
        update(cache, response) {
            const proposalID = `Proposal:${prID}`;
            const cachedVote = getCachedVote(cache, prID, user._id);
            cache.modify({
                id: proposalID,
                fields: {
                    votes(cachedVotes) { 
                        if (cachedVote.stance === 'AGAINST') return {
                            ...cachedVotes,
                            against: cachedVotes.against - 1,
                            net: cachedVotes.net + 1,
                            total: cachedVotes.total - 1
                        }
                        return {
                            ...cachedVotes,
                            for: cachedVotes.for - 1,
                            net: cachedVotes.net - 1,
                            total: cachedVotes.total - 1
                        }
                    }
                }
            });
            cache.modify({
                id: cache.identify({
                    __typename: 'Vote',
                    user: {
                        __ref: `User:${user._id}`
                    },
                    prID: prID
                }),
                fields: {
                    stance() { return 'NO_VOTE' }
                }
            })
        }
    })

    const onCreateVote = (stance) => {
        createVote({
            variables: {
                input: { 
                    prID: prID,
                    docID: docID,
                    stance: stance
                }
            }
        })
    }

    const onDeleteVote = (stance) => {
        deleteVote({
            variables: {
                prID: prID,
                stance: stance
            }
        })
    }

    if (loading) return <ProposalSkeleton />;
    if (error) return 'error';

    const proposalTitle = data.proposal.title ? data.proposal.title : `Untitled suggestion`;
    const prURL = `${process.env.REACT_APP_SITE_URL}${location.pathname}`;

    const canApproveProposal = getCanApproveProposal(data.doc, user);
    const canCloseProposal = getCanCloseProposal(data.doc, user, data.proposal.author._id);

    let titleDiff;
    if (data.proposal.docTitleDiff && data.proposal.docTitleDiff.length > 1) {
        titleDiff = <StringDiff parts={data.proposal.docTitleDiff} />
    }

    return (
        <div className='SnippetHorizontalContainer SnippetProposal'>
            <Helmet>
                <title>{`${proposalTitle} | Crowdwrite`}</title>
                <meta name="description" content={data.proposal.description} />
                <meta property="og:title" content={`${proposalTitle} | Crowdwrite`} />
                <meta property="og:url" content={prURL} />
                <meta property="og:description" content={data.proposal.description} />
                <meta property="og:site_name" content={`Crowdwrite`} />
            </Helmet>
            <ProposalHeader {...data.proposal} />
            {data.proposal.description && (
                <div className='ProposalDescription'>
                    <p>{data.proposal.description}</p>
                </div>
            )}
            {/* <ProposalTabs 
                activeTab={prTab} 
                teamSlug={teamSlug}
                commentCount={data.proposal.totalComments}
                docID={docID}
                prID={prID}
            /> */}
            <Routes>
                {/* <Route
                    path='/:prTab'
                    element={<h2>Comments</h2>}
                /> */}
                <Route 
                    path='/:prTab' 
                    element={(
                        <ProposalBody
                            prID={prID}
                            data={data}
                            onCreateVote={onCreateVote}
                            onDeleteVote={onDeleteVote}
                            voteIsLoading={voteIsLoading}
                            deleteVoteIsLoading={deleteVoteIsLoading}
                            canApprove={canApproveProposal}
                            canClose={canCloseProposal}
                            user={user}
                            acceptingProposal={acceptingProposal}
                            onAcceptProposal={onAcceptProposal}
                            closingProposal={closingProposal}
                            onCloseProposal={onCloseProposal}
                            canParticipate={canParticipate}
                        />
                    )}
                />
            </Routes>
            
        </div>
    )
}

export default SnippetProposal;