import * as actionTypes from '../actions/actionTypes';
import { updateObject, addToArray } from '../utility';

const initialState = {
    titleChanges: null,
    topMenuContext: null,
    focusedTextBlock: null,
    mutual: null
}

const setFocusedTextBlock = (state, action) => {
    return updateObject(state, { focusedTextBlock: action.blockKey });
}

const setTitleChanges = (state, action) => {
    return updateObject(state, { titleChanges: action.titleChanges });
}

const setDraftLinkAction = (state, action) => {
    return updateObject(state, { draftLinkAction: action.draftAction });
}

const setTopMenuContext = (state, action) => {
    return updateObject(state, { topMenuContext: action.topMenuContext });
}

const setMutual = (state, action) => {
    return updateObject(state, { mutual: action.mutual });
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_FOCUSED_TEXT_BLOCK: return setFocusedTextBlock(state, action);
        case actionTypes.SET_TITLE_CHANGES: return setTitleChanges(state, action);
        case actionTypes.SET_DRAFT_LINK_ACTION: return setDraftLinkAction(state, action);
        case actionTypes.SET_TOP_MENU_CONTEXT: return setTopMenuContext(state, action);
        case actionTypes.SET_MUTUAL: return setMutual(state, action);
        default: return state;
    }
}

export default reducer;