import { gql } from "@apollo/client";

const USERNAME_SIGN_IN = gql`
    mutation UsernameSignIn($username: String, $password: String) {
        usernameSignIn(username: $username, password: $password) {
            user {
                _id
                username
            }
            jwt
            error
        }
    }
`

export default USERNAME_SIGN_IN;