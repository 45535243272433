import gql from "graphql-tag";

const DOC = gql`
    query Doc($docID: String, $teamSlug: String) {
        doc(docID: $docID) {
            _id
            docID
            team
            title
            creator {
                _id
                username
            }
            created
            activeProposals
            totalProposals
            arweaveID
            text { 
                key
                text
                _id
                depth
                type
                inlineStyleRanges {
                    length
                    offset
                    style
                }
            }
            process {
                creatorCanEdit
                creatorCanChangeSettings
                controls {
                    processType
                    supportNeeded
                    approvers
                    approvalCount
                }
            }
        }
        team(slug: $teamSlug) {
            _id
            name
            members {
                email
            }
        }
    }
`

export default DOC;