import React from 'react';
import { DateTime } from 'luxon';
import CreateDocEvent from './CreateDocEvent/CreateDocEvent';
import CreateProposalEvent from './CreateProposalEvent/CreateProposalEvent';
import UpdateDocEvent from './UpdateDocEvent/UpdateDocEvent';
import AcceptProposalEvent from './AcceptProposalEvent/AcceptProposalEvent';
import { useParams } from 'react-router';
import CloseProposalEvent from './CloseProposalEvent/CloseProposalEvent';
import CommentEvent from './CommentEvent/CommentEvent';
import VoteEvent from './VoteEvent/VoteEvent';
import { Button } from '@blueprintjs/core';
import './ActivityFeed.css';

const onlyUnique = (value, index, array) => {
    return array.indexOf(value) === index;
}

const getEventBlocks = (events, context, username) => {
    const eventBlocks = events.map((eventData, i) => {
        let eventBlock = null;
        switch (eventData.__typename) {
            case 'CreateDocEvent': eventBlock = <CreateDocEvent key={i} context={context} {...eventData} />; break;
            case 'CreateProposalEvent': eventBlock = <CreateProposalEvent key={i} context={context} {...eventData} />; break;
            case 'UpdateDocEvent': eventBlock = <UpdateDocEvent key={i} context={context} {...eventData} />; break;
            case 'AcceptProposalEvent': eventBlock = <AcceptProposalEvent key={i} context={context} username={username} {...eventData} />; break;
            case 'CloseProposalEvent': eventBlock = <CloseProposalEvent key={i} context={context} username={username} {...eventData} />; break;
            case 'CommentEvent': eventBlock = <CommentEvent key={i} context={context} {...eventData} />; break;
            case 'VoteEvent': eventBlock = <VoteEvent key={i} context={context} {...eventData} />; break;
            default: eventBlock = <p>event</p>;
        }
        return eventBlock;
    });
    return eventBlocks;
}

const ActivityFeed = ({
    events,
    context = 'GLOBAL_FEED',
    onLoadMore,
    loading,
    totalEvents
}) => {

    const { username } = useParams();

    const dates = events.map(e => e.date.substring(0, 10)).filter(onlyUnique);
    const dateBlocks = dates.map(date => {

        const dateEvents = events.filter(e => e.date.substring(0,10) === date);
        const dateEventBlocks = getEventBlocks(dateEvents, context, username);

        const dateObj = new Date(date);
        const eventDateTime = DateTime.fromJSDate(dateObj);
        const delta = eventDateTime.diffNow('days').days;
        
        let dateString = eventDateTime.toRelative();
        if (delta < -0.5 && delta >= -1) {
            dateString = `Today`
        } 
        if (delta < -1 && delta >= -2) {
            dateString = `Yesterday`
        } 
        if (delta < -3) {
            dateString = eventDateTime.toLocaleString(DateTime.DATE_FULL)
        }

        return (
            <div className='DateBlock'>
                <h3 className='font-size-12 margin-bottom-12'>{dateString}</h3>
                <div className='DateEvents'>
                    {dateEventBlocks}
                </div>
            </div>
        )
    })

    return (
        <div className='ActivityFeed'>
            {dateBlocks}
            {/* {activityBlocks} */}

            {events.length < totalEvents && (
                <Button  
                    onClick={onLoadMore}  
                    text='Load more'
                    loading={loading}
                />
            )}
        </div>
    )
}

export default ActivityFeed;