import React from 'react';
import HistoryCard from '../HistoryCard/HistoryCard';

const DocHistorySkeleton = ({

}) => {
    return (
        <div className='DocHistory'>
            <div className='TimelineCardContainer new-date'>
                <div className='TimelineMarker'></div>
                <p 
                    className='bp3-skeleton skeleton width-25-percent'
                >
                    September 1st, 2022
                </p>
                <div className='bp3-skeleton skeleton content-card'>
                    <h3 className='ellipsis width-75-percent'>Skeleton title</h3>
                    <p>skeleton description</p>
                </div>
            </div>
            <div className='TimelineCardContainer'>
                <div className='bp3-skeleton skeleton content-card'>
                    <h3 className='ellipsis width-75-percent'>Skeleton title</h3>
                    <p>skeleton description</p>
                </div>
            </div>
            <div className='TimelineCardContainer new-date'>
                <div className='TimelineMarker'></div>
                <p 
                    className='bp3-skeleton skeleton width-25-percent'
                >
                    September 1st, 2022
                </p>
                <div className='bp3-skeleton skeleton content-card'>
                    <h3 className='ellipsis width-75-percent'>Skeleton title</h3>
                    <p>skeleton description</p>
                </div>
            </div>
            <div className='TimelineCardContainer'>
                <div className='bp3-skeleton skeleton content-card'>
                    <h3 className='ellipsis width-75-percent'>Skeleton title</h3>
                    <p>skeleton description</p>
                </div>
            </div>
            <div className='TimelineCardContainer new-date'>
                <div className='TimelineMarker'></div>
                <p 
                    className='bp3-skeleton skeleton width-25-percent'
                >
                    September 1st, 2022
                </p>
                <div className='bp3-skeleton skeleton content-card'>
                    <h3 className='ellipsis width-75-percent'>Skeleton title</h3>
                    <p>skeleton description</p>
                </div>
            </div>
            <div className='TimelineCardContainer'>
                <div className='bp3-skeleton skeleton content-card'>
                    <h3 className='ellipsis width-75-percent'>Skeleton title</h3>
                    <p>skeleton description</p>
                </div>
            </div>
        </div>
    )
}

export default DocHistorySkeleton;