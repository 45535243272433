import { useQuery } from '@apollo/client';
import { Divider, Icon, Spinner } from '@blueprintjs/core';
import React from 'react';
import DECISION_MAKER_VOTES from './queries/decisionMakerVotes';
import PositionedIcon from '../../../ui/PositionedIcon/PositionedIcon';
import { DANGER, NEUTRAL_DARK, NEUTRAL_LIGHT, SUCCESS } from '../../../../utils/colors';
import { Link } from 'react-router-dom';

const ApprovalStatus = ({
    prID,
    decisionMakers,
    approvalCount
}) => {

    const { error, loading, data } = useQuery(DECISION_MAKER_VOTES, {
        variables: {
            prID: prID,
            users: decisionMakers
        }
    });

    if (error) return 'error';
    if (loading) return 'loading...';

    const approvalsProvided = data.userVotes.filter(vote => vote.stance === 'FOR');
    const approvalsProvidedCount = approvalsProvided ? approvalsProvided.length : 0;
    const approvalsLeft = approvalCount - approvalsProvidedCount;

    const sortedList = [
        ...data.userVotes.filter(vote => vote.stance === 'FOR'),
        ...data.userVotes.filter(vote => vote.stance === 'AGAINST'),
        ...data.userVotes.filter(vote => vote.stance === 'NO_VOTE')
    ]

    const decisionMakerList = sortedList.map((vote, i) => {
        let icon;
        switch (vote.stance) {
            case 'FOR': icon = (
                    <PositionedIcon 
                        icon='thumbs-up' 
                        color={NEUTRAL_DARK}
                        iconSize={13}
                    />
                ); break;
            case 'AGAINST': icon = (
                <PositionedIcon 
                    icon='thumbs-down' 
                    color={NEUTRAL_LIGHT}
                    iconSize={13}
                />
            ); break;
            case 'NO_VOTE':
            default: icon = (
                <span style={{ 
                    opacity: 0.3,
                    fontSize: '7px',
                    marginRight: 6,
                    position: 'relative',
                    top: 1,
                    left: 3
                }}>
                    ●
                </span>
            )
        }

        const userSpan = vote.user.username.includes('@') ? (
            <span className='ml-1 truncate w-48 block'>
                {vote.user.username}
            </span>
        ) : (
            <span className='ml-1 truncate w-48 block'>
                <Link to={`/user/${vote.user.username}`}>
                    {vote.user.username}
                </Link>
            </span>
        )

        return (
            <div key={i} className='Respondent text-sm flex mb-1 items-center gap-1'>
                {icon} {userSpan}
            </div>
        )
    })

    return (
        <div className='VotingStatus'>
            {decisionMakerList}
        </div>
    )
}

export default ApprovalStatus;