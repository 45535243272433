import { useQuery } from '@apollo/client';
import React, { Fragment, useEffect } from 'react';
import { useParams } from 'react-router';
import DocTextDiff from '../../Doc/DocProposals/ProposalModal/DocTextDiff/DocTextDiff';
import { getFullDate, getReadableDate, getShortDateAndMonth } from '../../ui/FormattedDate/dateUtils';
import DOC_VERSION from './queries/docVersion';
import './DocVersion.css';
import VotingSummary from '../../ui/VotingSummary/VotingSummary';
import { Link } from 'react-router-dom';
import DateWithTooltip from '../../ui/DateWithTooltip/DateWithTooltip';
import VersionContextCard from './VersionContextCard/VersionContextCard';
import StaticVersion from './StaticVersion/StaticVersion';
import PositionedIcon from '../../ui/PositionedIcon/PositionedIcon';
import VersionSkeleton from './VersionSkeleton/VersionSkeleton';
import ScrollToTopOnMount from '../../ui/ScrollToTopOnMount/ScrollToTopOnMount';
import { useDispatch } from 'react-redux';
import * as actions from '../../../store/actions/index';
import StringDiff from '../../Doc/DocProposals/ProposalModal/DocTextDiff/BlockDiff/StringDiff/StringDiff';
import { Tooltip2 } from '@blueprintjs/popover2';
import ArweaveLogo from '../../ui/ArweaveLogo/ArweaveLogo';

const DocVersion = (props) => {
    
    const dispatch = useDispatch();
    const { teamSlug, docID, vNum } = useParams();
    const versionNumber = parseInt(vNum);
    const { loading, error, data } = useQuery(DOC_VERSION, {
        variables: {
            docID: docID,
            vNum: versionNumber,
            prev: versionNumber - 1,
            next: versionNumber + 1
        }
    }); 

    if (error) return 'err';
    if (loading) return <VersionSkeleton />;
    // return <VersionSkeleton />

    const {
        changeMessage,
        changeDescription,
        creator,
        proposal,
        titleChanges,
        changes,
        text,
        created,
        arweaveID
    } = data.mainDoc;

    
   
    const createdString = getReadableDate(created);
    const fullCreated = getFullDate(created);
    const prCreated = proposal ? getShortDateAndMonth(proposal.created) : null;
    const prCreatedFull = proposal ? getFullDate(proposal.created) : null;

    return (
        <div className='DocVersion'>
            <div className='VersionContext'>
                {data.prevDoc && (
                    <VersionContextCard 
                        prev
                        docID={docID}
                        teamSlug={teamSlug}
                        {...data.prevDoc} 
                    />
                )}
                {data.nextDoc && (
                    <VersionContextCard 
                        next
                        docID={docID}
                        teamSlug={teamSlug}
                        {...data.nextDoc} 
                    />
                )}
            </div>

            <div className='VersionHeader'>
                <h2>
                    {vNum !== "1" ? changeMessage : (
                        <span>
                            <PositionedIcon
                                icon='clean'
                                marginRight={3}
                                top={-3}
                            /> First draft
                        </span>
                    )}
                </h2>
                <p className='font-size-16'>
                    {changeDescription}
                </p>

                <div className='no-margin margin-bottom-8'>
                    <span className='font600 margin-right-8'>
                        {creator.username} 
                        {proposal && (
                            <span className='font400 margin-left-8'>
                                <DateWithTooltip
                                    noStyle
                                    date={proposal.created}
                                    text={prCreated}
                                />
                            </span>
                        )}
                        {!proposal && (
                            <span className='font400 margin-left-8'>
                                <DateWithTooltip
                                    noStyle
                                    date={created}
                                    text={createdString}
                                />
                            </span>
                        )}
                    </span>
                    {/* {arweaveID && (
                        <Tooltip2
                            content={`Permanently archived on Arweave`}
                            placement='top'
                        >
                            <a href={`https://arweave.net/${arweaveID}`} 
                                target="_blank" 
                                className='margin-right-8 margin-left-4 ArweaveLogo'>
                                <ArweaveLogo width={16} />  
                            </a>
                        </Tooltip2>
                    )} */}
                    {proposal && (
                        <Link to={`/${teamSlug}/${docID}/suggestions/${proposal._id}/changes`}>               
                            {proposal && !proposal.acceptedBy && (
                                <span className='font-size-12 half-opaque margin-right-4'>
                                    accepted
                                </span>
                            )}
                            {proposal && proposal.acceptedBy && proposal.acceptedBy.username && (
                                <span className='font-size-12 half-opaque margin-right-4'>
                                    approved by <span className='font600'>
                                        {proposal.acceptedBy.username}
                                    </span>
                                </span>
                            )}
                            <span className='font-size-12 half-opaque margin-right-4'>
                                {createdString}
                            </span>
                        </Link>
                    )}
                    {!proposal && vNum !== "1" && (
                        <Fragment>
                            <span className='font-size-12 half-opaque margin-right-4'>
                                approved by <span className='font600'>
                                    {creator.username}
                                </span>
                            </span>
                            <span className='font-size-12 half-opaque margin-right-4'>
                                {createdString}
                            </span>
                        </Fragment>
                    )}
                </div>

                {proposal && <VotingSummary voters={proposal.voters} />}

            </div>
        
            {vNum !== "1" && changes && (
                <Fragment>
                    {titleChanges && titleChanges.length > 1 && (
                        <h1 className='margin-bottom-24 padding-bottom-24 soft-border-dotted-bottom'>
                            <StringDiff parts={titleChanges} />
                        </h1>
                    )}
                    <DocTextDiff 
                        canComment={false}
                        diffData={changes} 
                        expandAll={true}
                    />
                </Fragment>
               
            )}

            {vNum === "1"  && (
                <StaticVersion text={text} />
            )}

        </div>
    )
}

export default DocVersion;