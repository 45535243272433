import React from 'react';
import { Link } from 'react-router-dom';
import PositionedIcon from '../../../ui/PositionedIcon/PositionedIcon';
import { Tag } from '@blueprintjs/core';
import StatusTag from '../../../ui/StatusTag/StatusTag';
import DocTag from '../DocTag/DocTag';

const CreateProposalEvent = ({
    doc,
    proposal,
    user,
    context
}) => {

    let summary;
    
    if (context === 'GLOBAL_FEED'){
        summary = (
            <span>
                <Link to={`/user/${user.username}`} className='font500'>{user.username}</Link> made a suggestion
            </span>
        )
    } 

    if (context === 'PROFILE') {
        summary = (
            <span>Made a suggestion</span>
        )
    }
    
    return (
        <div className='EventBlock'>
            <div className='EventIcon'>
                <PositionedIcon icon='lightbulb' top={3} iconSize={14} />
            </div>
            <div className='EventInfo'>
                <p className='margin-bottom-12 font-size-12'>
                    {summary}
                    <span className='margin-left-8'>
                        <DocTag
                            link={`/${doc.team}/${doc.docID}`}
                            title={doc.title}
                        />
                    </span>
                </p>
                <Link to={`/${doc.team}/${doc.docID}/suggestions/${proposal._id}/changes`}>
                    <div className='event-card inline-block'>
                        <p className='margin-bottom-0'>
                            <span className='font600'>{proposal.title ? proposal.title : 'Untitled suggestion'}</span>
                            <span className='margin-left-8 up1'>
                                <StatusTag status={proposal.status} />
                            </span>
                        </p>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default CreateProposalEvent;
