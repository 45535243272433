import { gql } from "@apollo/client";

const BLOCK_REPLIES = gql`
    query BlockCommentReplies {
        replies: comments(blockID: "clc9exjy0000408md0rywgr2h", sort: "OLDEST_FIRST") {
            _id
            author {
                _id
                username
            }
            created
            edited
            text
        }
    }
`

export default BLOCK_REPLIES;