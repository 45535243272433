import { gql } from "@apollo/client";

const USER = gql`
    query User($username: String) {
        user(username: $username) {
            _id
            username
            name
            bio
            location
            link
            created
            profilePicPath
        }
    }
`

export default USER;