import { DateTime } from 'luxon';
import React from 'react';
import Avatar from '../../../../../ui/Avatar/Avatar';
import './ThreadTooltip.css';
import ThreadPreview from './ThreadPreview/ThreadPreview';

const ThreadTooltip = ({
    threads,
    onOpenThread
}) => {

    const threadPreviews = threads.map((thread, i) => {
        return (
            <ThreadPreview 
                key={i} 
                onOpenThread={onOpenThread}
                threadID={thread._id} 
                {...thread} 
            />
        )
    })

    return (
        <div className='flex flex-col gap-1 text-sm w-72 cursor-pointer rounded-lg border border-gray-200 shadow-lg px-2 py-2 max-h-96 overflow-scroll'>
            {threadPreviews}
        </div>
    )
}

export default ThreadTooltip;