import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './SideNavBtn.css';

const SideNavBtn = ({
    label,
    icon,
    link
}) => {

    return (
        <NavLink to={link} className='SideNavBtn block hover:no-underline font-semibold text-sm text-left mx-2 my-0.5 rounded-lg py-1.5 px-5 opacity-80 hover:opacity-100 transition-all'>
            <button className='flex gap-3 items-center transition-all'>
                <FontAwesomeIcon
                    icon={icon}
                    opacity={0.6}
                />
                {/* <PositionedIcon
                    icon={icon}
                    iconSize={15}
                    top={0}
                /> */}
                {label}
            </button>
        </NavLink>   
    )
}

export default SideNavBtn;