import React from 'react';
import PositionedIcon from '../PositionedIcon/PositionedIcon';
import './VotingSummary.css';

const VotingSummary = ({
    voters
}) => {

    if (voters.length === 0) return null;

    const votesFor = voters.filter(voter => voter.stance === 'FOR').map((voter, i) => {
        return (
            <span 
                className='Voter margin-right-4 margin-bottom-4' 
                key={i}>
                    <PositionedIcon icon='thumbs-up' iconSize={10} top={-1} marginRight={2} /> {voter.user.username}
                </span>
            )
    });

    const votesAgainst = voters.filter(voter => voter.stance === 'AGAINST').map((voter, i) => {
        return (
            <span 
                className='Voter margin-right-4 margin-bottom-4' 
                key={i}>
                    <PositionedIcon icon='thumbs-down' iconSize={10} top={-1} marginRight={2} /> {voter.user.username}
                </span>
            )
    });

    return (
        <div className='VotingSummary'>
            {votesFor.length > 0 && (
                <div className='VotesFor VotingBlock'>
                    {votesFor}
                </div>
            )}
            {votesAgainst.length > 0 && (
                <div className='VotesAgainst VotingBlock'>
                    {votesAgainst}
                </div>
            )}
        </div>
    )
}

export default VotingSummary;