import { gql } from "@apollo/client";

const SIGN_UP_WITH_EMAIL = gql`
    mutation SignUpWithEmail($input: SignUpWithEmailInput) {
        signUpWithEmail(input: $input) {
            jwt
            user {
                _id
                email
                username
            }
            error
        }
    }
`

export default SIGN_UP_WITH_EMAIL;