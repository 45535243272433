import { gql } from "@apollo/client";

const ADD_REACTION = gql`
    mutation AddReaction($input: ReactionInput) {
        addReaction(input: $input) {
            blockID
            docID
            emoji
        }
    }
`

export default ADD_REACTION;