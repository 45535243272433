import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Comment from '../../../../../DocProposals/ProposalModal/DocTextDiff/BlockDiff/BlockFeedback/Comment/Comment';

const ThreadComments = ({
    comments,
    threadID
}) => {

    const commentsRef = useRef(null);
    const activeUser = useSelector(state => state.auth.user._id);

    const commentBlocks = comments.map((comment, i) => (
        <Comment 
            key={i} 
            activeUser={activeUser} 
            threadID={threadID}
            {...comment} 
        />
    ));

    useEffect(() => {
        if (commentsRef.current) {
            commentsRef.current.scrollTop = commentsRef.current.scrollHeight;
        }
    });

    return (
        <div className='ThreadComments' ref={commentsRef} id='comments-bottom'>
            {commentBlocks}
        </div>
    );
}

export default ThreadComments;