import React from 'react';
import { Button } from '@blueprintjs/core';
import './ModalTopBar.css';

const ModalTopBar = ({
    title,
    onClose,
    minimal
}) => {

    return (
        <div className={minimal ? 'ModalTopBar minimal' : 'ModalTopBar'}>
            <span className='ModalTitle'>{title}</span>
            <Button
                minimal small
                icon='cross'
                onClick={onClose}
            />
        </div>
    )
}

export default ModalTopBar;