import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../../../store/actions';
import DocEditorModal from './DocEditorModal/DocEditorModal';
import { useLocation, useNavigate } from 'react-router';
import TextBlock from './TextBlock/TextBlock';
import { Button, Dialog, NonIdealState } from '@blueprintjs/core';
import './DocText.css';
import { getListItemIndex } from './TextBlock/textBlockUtils/listItemUtils';
import formatDate from '../../../utils/formatDate';
import { Link } from 'react-router-dom';
import { getReadableDate } from '../../ui/FormattedDate/dateUtils';
import EmojiPalette from '../../ui/EmojiPalette/EmojiPalette';
import { useLazyQuery } from '@apollo/client';
import DOC_REACTIONS from '../queries/docReactions';
import AddComment from './AddComment/AddComment';
import DOC_BLOCK_THREADS from '../queries/docBlockThreads';
import ThreadContainer from './TextBlock/ThreadSidebar/ThreadContainer/ThreadContainer';
import ArweaveLogo from '../../ui/ArweaveLogo/ArweaveLogo';
import { Tooltip2 } from '@blueprintjs/popover2';
import Modal from '../../ui/Modal/Modal';
import DocSettingsEditor from '../../SnippetEditor/DocSettingsEditor/DocSettingsEditor';
import ForkDocModal from './ForkDocModal/ForkDocModal';
import SignUpModal from '../../SignUpModal/SignUpModal';
import SuggestionListModal from './SuggestionListModal/SuggestionListModal';
import EntryModal from '../../EntryModal/EntryModal';
import { getHasInvite } from '../../../utils/localStorage';

const DocText = ({
    docID,
    docTextID,
    teamID,
    teamSlug,
    lastEdited,
    text,
    entityMap,
    userCanSave,
    versionCount,
    arweaveID,
    title,
    creator,
    lastEditor,
    userId,
    userAccess,
    participateAccess,
    inviteID
}) => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const [showingEmojiPalette, toggleEmojiPalette] = useState(false);
    const [ emojiPos, setEmojiPos ] = useState(null);

    const [entryWay, setEntryWay] = useState('SIGN_UP');

    const [showingBlockSuggestionModal, toggleBlockSuggestionModal] = useState(false);
    const [blockSuggestionsKey, setBlockSuggestionsKey] = useState(null);
    const onShowBlockSuggestionsModal = (blockKey) => {
        setBlockSuggestionsKey(blockKey)
        toggleBlockSuggestionModal(true);
    }
    const onCloseBlockSuggestionModal = () => {
        setBlockSuggestionsKey(null);
        toggleBlockSuggestionModal(false);
    }

    const textContainerRef = useRef(null);

    const [showingSignUpModal, toggleSignUpModal] = useState(false);
    const openSignUpModal = () => toggleSignUpModal(true);
    const closeSignUpModal = () => toggleSignUpModal(false);

    const [showingAddComment, toggleAddComment] = useState(false);
    const [addCommentContext, setAddCommentContext] = useState({
        x: null,
        y: null,
        blockID: null
    })

    const [openThreadContext, setOpenThreadContext] = useState({
        threadID: null,
        x: null,
        y: null
    });

    const onShowAddComment = (e, blockID, start, end) => {

        if (!userId) {
            openSignUpModal();
        }

        if (userId) {
            setAddCommentContext({
                x: e.clientX,
                y: e.clientY,
                blockID: blockID,
                highlightStart: start,
                highlightEnd: end
            });
            toggleAddComment(true);
        }
        
    }
     
    const onHideAddComment = () => toggleAddComment(false);

    const [getReactions, { 
        loading: loadingReactions, 
        error: errorReactions, 
        data: reactionData 
    }] = useLazyQuery(DOC_REACTIONS);

    const [getBlockThreads, {
        loading: loadingThreads,
        error: errorThreads,
        data: threadData
    }] = useLazyQuery(DOC_BLOCK_THREADS);

    useEffect(() => {
        getReactions({
            variables: {
                docID: docID
            }
        });
        getBlockThreads({
            variables: {
                docID: docID
            }
        })
    }, []);
    
    const onShowEmojiPalette = (e) => {
        setEmojiPos({ x: e.clientX, y: e.clientY });
        toggleEmojiPalette(true);
    }
    
    const onHideEmojiPalette = () => toggleEmojiPalette(false);

    const [showingEditModal, toggleEditModal] = useState(false);
    const closeEditModal = () => toggleEditModal(false);
    const showEditModal = (blockKey) => {

        if (!userId) {
            openSignUpModal();
        }

        if (userId) {
            toggleEditModal(true);
            if (blockKey) {
                navigate(`${location.pathname}#${blockKey}`);
                dispatch(actions.setFocusedTextBlock(blockKey));
            }
        }

    }

    const [showingForkModal, toggleForkModal] = useState(false);
    const showForkModal = () => {
        if (userId) {
            toggleForkModal(true);
        }
    
        else if (!userId) {
            toggleSignUpModal(true);
        }
    };
    const closeForkModal = () => toggleForkModal(false);

    const hasInvite = getHasInvite(docID);

    const textBlocks = text.map((block, i) => {
        let reactions;
        if (reactionData && reactionData.reactions.find(r => r.contextID === block._id)) {
            reactions = reactionData.reactions.filter(r => r.contextID === block._id);
        }
        const blockEntityKeys = block.entityRanges.map(range => range.key);
        const entities = entityMap ? entityMap.filter(entity => blockEntityKeys.includes(entity.key)) : [];
        const threads = threadData ? threadData.blockThreads.filter(thread => thread.blockID === block._id) : null;
        const proposals = threadData ? threadData.proposals.filter(pr => pr.touchedBlockKeys.includes(block.key)) : null;
        return (
            <TextBlock key={i} {...block} 
                blockKey={block.key}
                showEditModal={() => showEditModal(block.key)}
                listItemIndex={getListItemIndex(text, i)}
                showAddComment={onShowAddComment}
                hideAddComment={onHideAddComment}
                showEmojiPalette={onShowEmojiPalette}
                hideEmojiPalette={onHideEmojiPalette}
                showingEmojiPalette={showingEmojiPalette}
                entities={entities}
                docID={docID}
                threads={threads}
                proposals={proposals}
                reactions={reactions}
                setOpenThreadContext={setOpenThreadContext}
                threadIsOpen={openThreadContext.blockID === block._id}
                onShowBlockSuggestions={onShowBlockSuggestionsModal}
                canParticipate={hasInvite || userAccess === 'PARTICIPATE'}
            />
        )});

    const noTextYet = (
        <NonIdealState
            icon='edit'
            title='Nothing yet'
            action={(
                <Button 
                    text='Start writing' 
                    intent='success'
                    onClick={() => showEditModal()}
                />
            )}
        />
    )

    const lastEditedDate = getReadableDate(lastEdited);

    return (
        <Fragment>
            <div className='EditBar flex items-center gap-3 mt-6 mb-6 ml-[20px]'>
                {(hasInvite || userAccess === 'PARTICIPATE') && (
                    <Button
                        small
                        text='Edit'
                        icon='edit'
                        intent='primary'
                        onClick={() => showEditModal()}
                    />
                )}
                {/* <Button
                    small
                    text='Fork'
                    icon='fork'
                    onClick={showForkModal}
                    className='ForkBtn primary-neutral-btn'
                /> */}
                {/* {arweaveID && (
                    <Tooltip2
                        content={`Permanently archived on Arweave`}
                        placement='top'
                    >
                         <a href={`https://arweave.net/${arweaveID}`} 
                            target="_blank" 
                            className='-mt-2 leading-none ArweaveLogo'>
                            <ArweaveLogo width={16} />  
                        </a>
                    </Tooltip2>
                   
                )} */}
                <Link 
                    to={`/${teamSlug}/${docID}/history`} 
                    className='text-xs opacity-60'
                >
                    Last edited {lastEditedDate} by {lastEditor.username}
                </Link>
            </div>
            {showingAddComment && (
                <AddComment 
                    isOpen={showingAddComment}
                    onClose={onHideAddComment}
                    docID={docID}
                    docTextID={docTextID}
                    setOpenThreadContext={setOpenThreadContext}
                    {...addCommentContext}
                />
            )}
            {openThreadContext.threadID && (
                <ThreadContainer
                    threadID={openThreadContext.threadID}
                    textContainerRef={textContainerRef}
                    docID={docID}
                    docTextID={docTextID}
                    xPos={openThreadContext.x}
                    yPos={openThreadContext.y}
                    setOpenThreadContext={setOpenThreadContext}
                />
            )}
            <div ref={textContainerRef}>
                {text.length > 0 ? textBlocks : noTextYet}
            </div>
            <DocEditorModal 
                isOpen={showingEditModal}
                docID={docID}
                teamID={teamID}
                teamSlug={teamSlug}
                userCanSave={userCanSave}
                onClose={closeEditModal}
                text={text}
                entityMap={entityMap}
                title={title}
                userId={userId}
                participateAccess={participateAccess}
                inviteID={inviteID}
            />
            <EntryModal
                entryWay={entryWay}
                setEntryWay={setEntryWay}
                isOpen={showingSignUpModal}
                handleClose={closeSignUpModal}
            />
            <SuggestionListModal 
                isOpen={showingBlockSuggestionModal}
                onClose={onCloseBlockSuggestionModal}
                docID={docID}
                teamSlug={teamSlug}
                blockKey={blockSuggestionsKey}
                proposals={threadData ? threadData.proposals : null}
            />
            {showingForkModal && (
                <ForkDocModal 
                    isOpen={showingForkModal}
                    onClose={closeForkModal}
                    docID={docID}
                />
            )}
        </Fragment>
    )
}

export default DocText;