import React from 'react';
import { Icon } from '@blueprintjs/core';
import './PositionedIcon.css';

const PositionedIcon = ({
    top = 0,
    left = 0,
    marginLeft = 0,
    marginRight = 0,
    opacity = 1,
    icon,
    iconSize,
    iconClasses,
    color = 'inherit'
}) => {

    const styles = {
        position: 'relative',
        top: top,
        left: left,
        marginLeft: marginLeft,
        marginRight: marginRight,
        opacity: opacity,
        color: color
    }

    return (
        <span className='PositionedIcon' style={styles}>
            <Icon icon={icon} iconSize={iconSize} className={iconClasses} />
        </span>
    )
}

export default PositionedIcon;