import React from "react";
import CrowdwriteLogo from "../../ui/svg/CrowdwriteLogo";

const SignInHeader = ({

}) => {

    return (
        <div className='flex gap-2 justify-start items-center mb-4 -mt-8'>
            <div className='-mt-1.5 mr-3'>
                <CrowdwriteLogo width={32} />
            </div>
            <div className='mb-4'>
                <p className='font-semibold tracking-tighter mb-0 ml-20 text-sm text-gray-500'>open beta</p>
                <h2 className='text-3xl -mt-1 font-medium text-center -ml-1 leading-none tracking-tight'>
                    Crowdwrite
                </h2>
            </div>
        </div>
    )
}

export default SignInHeader;