import React, { useState } from 'react';
import { Button, Dialog, FileInput, FormGroup, InputGroup, TextArea } from '@blueprintjs/core';
import ModalTopBar from '../../ui/ModalTopBar/ModalTopBar';
import * as Bytescale from "@bytescale/sdk";
import ModalActionBar from '../../ui/ModalActionBar/ModalActionBar';
import { useMutation } from '@apollo/client';
import UPDATE_PROFILE from './mutations/updateProfile';
import validator from 'validator';
import './EditProfileModal.css';
import CrowdwriteLogo from '../../ui/svg/CrowdwriteLogo';

const EditProfileModal = ({
    isOpen,
    onClose,
    name = '',
    title = '',
    link = '',
    location = '',
    bio = '',
    profilePicPath = ''
}) => {

    const uploadManager = new Bytescale.UploadManager({
        apiKey: "public_FW25btdhYEVKugwUmQkA3FuKjFT9", // This is your API key.
        maxFileCount: 1
    });

    const [editableName, setEditableName] = useState(() => name);
    const onSetEditableName = (e) => setEditableName(e.target.value);

    const [editableTitle, setEditableTitle] = useState(() => title);
    const onSetEditableTitle = (e) => setEditableTitle(e.target.value);

    const [editableLink, setEditableLink] = useState(() => link);
    const onSetEditableLink = (e) => setEditableLink(e.target.value);
    
    const [editableLocation, setEditableLocation] = useState(() => location);
    const onSetEditableLocation = (e) => setEditableLocation(e.target.value);

    const [editableBio, setEditableBio] = useState(() => bio);
    const onSetEditableBio = (e) => setEditableBio(e.target.value);

    const [editableProfilePicPath, setProfilePicPath] = useState(() => profilePicPath);
    const [editableProfilePicURL, setProfilePicURL] = useState(() => `${process.env.REACT_APP_IMAGE_CDN_BASE_URL}${profilePicPath}`);

    const [uploadIsLoading, toggleUploadIsLoading] = useState(false);

    const [updateProfile, { loading, error, data }] = useMutation(UPDATE_PROFILE, {
        variables: {
            input: {
                name: editableName,
                title: editableTitle,
                link: editableLink && validator.isURL(editableLink) ? editableLink : '',
                location: editableLocation,
                bio: editableBio,
                profilePicPath: editableProfilePicPath
            }
        },
        onCompleted: () => onClose()
    });

    const onUpdateProfile = () => {
        updateProfile();
    }

    const onFileSelected = async event => {
        toggleUploadIsLoading(true);
        const file = event.target.files[0];
        try {
          const { fileUrl, filePath } = await uploadManager.upload({ data: file });
          setProfilePicPath(filePath);
          setProfilePicURL(fileUrl);
          toggleUploadIsLoading(false);
        } catch (e) {
          alert(`Error:\n${e.message}`);
        }
    }

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            className='cw-modal'
            transitionName='fade'
        >
            <ModalTopBar 
                title='User settings'
                onClose={onClose}
            />
            <div className='EditProfileContent'>
                <div className='EditProfileFields'>
                    <FormGroup
                        label='Name'
                        labelFor='name'
                    >
                        <InputGroup 
                            id='name'
                            value={editableName}
                            onChange={onSetEditableName}
                        />
                    </FormGroup>
                    <FormGroup
                        label='Title'
                        labelFor='title'
                    >
                        <InputGroup 
                            id='title'
                            value={editableTitle}
                            onChange={onSetEditableTitle}
                        />
                    </FormGroup>
                    <FormGroup
                        label='Website'
                        labelFor='website'
                    >
                        <InputGroup 
                            id='website'
                            value={editableLink}
                            onChange={onSetEditableLink}
                        />
                    </FormGroup>
                    <FormGroup
                        label='Location'
                        labelFor='location'
                    >
                        <InputGroup 
                            id='location'
                            value={editableLocation}
                            onChange={onSetEditableLocation}
                        />
                    </FormGroup>
                    <FormGroup
                        label='Bio'
                        labelFor='bio'
                    >
                        <TextArea
                            fill
                            id='bio'
                            value={editableBio}
                            onChange={onSetEditableBio}
                        />
                    </FormGroup>
                </div>
                <div className='EditProfilePicture'>
                    <div className='ProfilePicturePreview'>
                        {editableProfilePicPath !== '' && editableProfilePicPath !== null && (
                            <img 
                                src={editableProfilePicURL} 
                                alt='profile picture' 
                            />
                        )}
                        {!editableProfilePicPath && (
                            <div className='flex justify-center'>
                                <CrowdwriteLogo width={64} fill='#fff' />
                            </div>
                        )}
                    </div>
                    <div>
                        <label className='file-upload-btn'>
                            <input type='file' 
                                onChange={onFileSelected}
                                accept="image/png, image/gif, image/jpeg"
                            />
                            {uploadIsLoading ? `Uploading...` : `Upload an image`} 
                        </label>
                        <br/>
                        <Button 
                            small minimal
                            text='Remove photo'
                            className='margin-top-4'
                        />
                    </div>
                </div>
            </div>
            <ModalActionBar>
                <Button 
                    intent='primary'
                    text='Save changes'
                    onClick={onUpdateProfile}
                    loading={loading}
                />
                <Button
                    minimal
                    text='Cancel'
                    onClick={onClose}
                />
            </ModalActionBar>
        </Dialog>
    )
}

export default EditProfileModal;