import React from 'react';
import { Link } from 'react-router-dom';
import PositionedIcon from '../../../ui/PositionedIcon/PositionedIcon';
import StatusTag from '../../../ui/StatusTag/StatusTag';
import './CommentEvent.css';
import ProposalTag from '../ProposalTag/ProposalTag';
import DocTag from '../DocTag/DocTag';

const CommentEvent = ({
    doc,
    user,
    proposal,
    comment,
    context
}) => {

    let text = (
        <p>
            {context === 'GLOBAL_FEED' && (
                <span><Link to={`/user/${user.username}`} className='font500'>{user.username}</Link> shared a comment</span>
            )}
            {context === 'PROFILE' && (
                <span>Shared a comment</span>
            )}
            <span className='margin-left-8'>
                <DocTag
                    link={`/${doc.team}/${doc.docID}`}
                    title={doc.title}
                />
            </span>
        </p>
    );
    
    if (proposal) {
        text = (
            <p className='margin-bottom-8'>
                <span className='font-size-12'>
                    Commented on <Link to={`/user/${proposal.author.username}`} className='font500'>
                        {proposal.author.username}'s
                    </Link> suggestion 
                </span>
                <span className='margin-left-8'>
                    <ProposalTag
                        link={`/${doc.team}/${doc.docID}/suggestions/${proposal._id}/changes`}
                        title={proposal.title}
                        status={proposal.status}
                    />
                </span>
            </p>
        )
    }

    return (
        <div className='EventBlock CommentEvent'>
            <div className='EventIcon'>
                <PositionedIcon icon='chat' top={0} left={3} iconSize={9} />
            </div>
            <div className='EventInfo margin-bottom-12'>
                {text}
                {/* <Link to={`/${data.doc.team}/${data.doc.docID}/history/${data.docVersion.version}`}> */}
                    <div className='event-quote asymmetric inline-block'>
                        <p className='margin-bottom-0'>
                            {comment.text}
                        </p>
                    </div>
                    {proposal && (
                        <div className='margin-top-8'>
                            <DocTag
                                link={`/${doc.team}/${doc.docID}`}
                                title={doc.title}
                            />
                        </div>
                    )}
                {/* </Link> */}
            </div>
        </div>
    )
}

export default CommentEvent;
