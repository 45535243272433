import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk'

import authReducer from './reducers/auth';
import uiReducer from './reducers/ui';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    auth: authReducer,
    ui: uiReducer
})

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

export default store;