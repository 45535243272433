import { SteleToaster } from "../../../ui/Toaster/Toaster";

export const isRangeSelected = (editorState) => {
    const selection = editorState.getSelection();
    const anchorKey = selection.getAnchorKey();
    const focusKey = selection.getFocusKey();
    const anchorOffset = selection.getAnchorOffset();
    const focusOffset = selection.getFocusOffset();
    return (anchorKey !== focusKey || anchorOffset !== focusOffset);
}

export const isLockedAtCursor = (editorState) => {
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const currentContent = editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(startKey);
    const prevCharStyles = currentContentBlock.getInlineStyleAt(startOffset - 1);
    const nextCharStyles = currentContentBlock.getInlineStyleAt(startOffset);
    return prevCharStyles.has('LOCKED') && nextCharStyles.has('LOCKED');
}

export const isLockedAtPrevChar = (editorState) => {
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const currentContent = editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(startKey);
    const prevCharStyles = currentContentBlock.getInlineStyleAt(startOffset - 1);
    return prevCharStyles.has('LOCKED');
}

export const isLockedAtNextChar = (editorState) => {
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const currentContent = editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(startKey);
    const nextCharStyles = currentContentBlock.getInlineStyleAt(startOffset);
    return nextCharStyles.has('LOCKED');
}

export const checkForLockedPrevChar = (editorState) => {
    
    const selection = editorState.getSelection();
    const anchorKey = selection.getAnchorKey();
    const focusKey = selection.getFocusKey();
    const anchorOffset = selection.getAnchorOffset();
    const focusOffset = selection.getFocusOffset();
    const currentContent = editorState.getCurrentContent();
    const currentToasts = SteleToaster.getToasts();

    // handle cases where there is no selection (anchor and focus are the same)
    if (anchorKey === focusKey && anchorOffset === focusOffset) {
        const currentContentBlock = currentContent.getBlockForKey(anchorKey);
        const characterStyles = currentContentBlock.getInlineStyleAt(anchorOffset - 1);
        const stylesArr = [...characterStyles]
        if (stylesArr.includes('LOCKED')) {    
            if (currentToasts.length > 0) { return 'handled' }
            SteleToaster.show({
                className: 'dark',
                message: `Sorry, that text is locked`
            });
            return 'handled';
        } else {
            return 'not-handled'
        }
    } 
}

export const isLockedTextInRange = (editorState) => {
    
    const selection = editorState.getSelection();
    const startOffset = selection.getStartOffset();
    const endOffset = selection.getEndOffset();
    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();
    const currentContent = editorState.getCurrentContent();
    const blockMap = [...currentContent.getBlockMap()];

    // check if any of the selected blocks include LOCKED characters
    const blockKeys = blockMap.map(block => block[0]);
    const startBlockIndex = blockKeys.indexOf(startKey);
    const endBlockIndex = blockKeys.indexOf(endKey);
    const selectedBlockKeys = blockKeys.filter((block, i) => i >= startBlockIndex && i <= endBlockIndex);
    const blocksLockedCharStatus = selectedBlockKeys.map(key => {
        const block = currentContent.getBlockForKey(key);
        let characterList = [...block.getCharacterList()];
        
        // handle selection range for start and end blocks
        if (key === startKey) {
            characterList = characterList.slice(startOffset);
        }
        if (key === endKey) {
            characterList = characterList.slice(0, endOffset)
        }

        const characterIsLocked = characterList.map(charMetadata => charMetadata.hasStyle('LOCKED'))
        return characterIsLocked.includes(true);
    });

    return blocksLockedCharStatus.includes(true);

}

export const checkForLockedTextInRange = (editorState) => {

    const currentToasts = SteleToaster.getToasts();
    const lockedTextInRange = isLockedTextInRange(editorState);

    if (lockedTextInRange) {
        if (currentToasts.length > 0) { return 'handled' }
        SteleToaster.show({
            className: 'dark',
            message: `You don't have permission to edit locked text`
        });
        return 'handled';
    } else {
        return 'not-handled';
    }
}

export const preventLockedTextEdit = (editorState) => {

    // handle editor state with a selection that includes locked text
    if (isRangeSelected(editorState) === true) {
        const lockedTextInRange = isLockedTextInRange(editorState);
        return lockedTextInRange;
    }
 
    // editor state is in the middle of a locked section
    // editor state is at the end of a locked section 
    return isLockedAtPrevChar(editorState);

}