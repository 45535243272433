import React from 'react';
import { Tag } from '@blueprintjs/core';
import { DateTime } from 'luxon';
import ProposalBalance from '../../../Proposal/ProposalBalance/ProposalBalance';
import FormattedDate from '../../../ui/FormattedDate/FormattedDate';

const ProposalHeader = ({
    title,
    status,
    author,
    created,
    votes,
    closedBy,
    closed,
    acceptedBy,
    accepted
}) => {

    let statusIntent;
    if (status === 'ACTIVE') statusIntent = '';
    if (status === 'ACCEPTED') statusIntent = 'success';
    if (status === 'CLOSED') statusIntent = 'danger';

    const createdDate = DateTime.fromISO(created); // TODO: componentize formatted date
    const formattedDate = createdDate.toLocaleString(DateTime.DATE_MED);

    return (
        <div className='SnippetProposalHeader'>
            <h2 className='text-xl tracking-tight'>{title ? title : 'Untitled suggestion'}</h2>
            <div className='ProposalMeta'>
                <Tag className={'ProposalStatus'} intent={statusIntent}>
                    {status.toLowerCase()}
                </Tag>  
                <ProposalBalance votes={votes} />
                <span className='font-size-12 margin-left-4'> Suggested by {author.username} on {formattedDate}</span>
                {status === 'CLOSED' && (
                    <span className='bp3-text-small margin-top-8 ml-2'>
                        <span className='mr-2'>•</span>
                        Closed by {closedBy.username} on <FormattedDate date={closed}/>
                    </span>
                )}
                {!acceptedBy && status === 'ACCEPTED' && (
                    <span className='bp3-text-small margin-top-8 ml-2'>
                        <span className='mr-2'>•</span>
                        Accepted on <FormattedDate date={accepted}/>
                    </span>
                )}
                {acceptedBy && status === 'ACCEPTED' && (
                    <span className='bp3-text-small margin-top-8 ml-2'>
                        <span className='mr-2'>•</span>
                        Accepted by {acceptedBy.username} on <FormattedDate date={accepted}/>
                    </span>
                )}
            </div>
        </div>
    )
}

export default ProposalHeader;