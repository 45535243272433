import { addToArray, removeArrayItem } from "../store/utility";

const recentDocsToken = `__cw-recent__`;
const invitesToken = `__cw-invites__`;

export const updateRecentDocs = (docID) => {
    let currentRecentDocs = window.localStorage.getItem(recentDocsToken);
    currentRecentDocs = currentRecentDocs ? JSON.parse(currentRecentDocs) : [];
    if (!currentRecentDocs.includes(docID)) {
        const newRecentDocs = [docID, ...currentRecentDocs].filter((id, i) => i < 6);
        window.localStorage.setItem(recentDocsToken, JSON.stringify(newRecentDocs));
    }  
}

export const getRecentDocs = () => {
    let recentDocs = window.localStorage.getItem(recentDocsToken);
    recentDocs = recentDocs ? JSON.parse(recentDocs) : [];
    return recentDocs;
}

export const addInvite = (inviteCompoundID) => { // docID-inviteID
    let currentInvites = window.localStorage.getItem(invitesToken);
    currentInvites = currentInvites ? JSON.parse(currentInvites) : [];

    if (!currentInvites.includes(inviteCompoundID)) {
        const newInvites = [...currentInvites, inviteCompoundID];
        window.localStorage.setItem(invitesToken, JSON.stringify(newInvites));
    }

}

export const removeInvite = (inviteID) => {
    let currentInvites = window.localStorage.getItem(invitesToken);
    currentInvites = currentInvites ? JSON.parse(currentInvites) : [];

    const compoundIDs = currentInvites.map(id => {
        return {
            docID: id.split('-')[0],
            inviteID: id.split('-')[1]
        }
    });

    const foundInviteIndex = compoundIDs.findIndex(id => id.inviteID === inviteID);
    const newInvites = removeArrayItem(currentInvites, foundInviteIndex);
    window.localStorage.setItem(invitesToken, JSON.stringify(newInvites));

}

export const getHasInvite = (docID) => {
    let currentInvites = window.localStorage.getItem(invitesToken);
    currentInvites = currentInvites ? JSON.parse(currentInvites) : [];
    
    const compoundIDs = currentInvites.map(id => {
        return {
            docID: id.split('-')[0],
            inviteID: id.split('-')[1]
        }
    });

    const foundInvite = compoundIDs.find(id => id.docID === docID);

    return foundInvite ? true : false;
}

export const getUnacceptedInvites = () => {
    return JSON.parse(window.localStorage.getItem(invitesToken));
}

