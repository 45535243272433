import { useQuery } from '@apollo/client';
import React from 'react';
import GET_ACTIVITY from './queries/activity';
import SnippetTopMenu from '../SnippetEditor/SnippetTopMenu/SnippetTopMenu';
import { useSelector } from 'react-redux';
import ActivityFeed from './ActivityFeed/ActivityFeed';

const ActivityPage = ({

}) => {

    const currentUser = useSelector(state => state.auth.user);

    const { loading, error, data, fetchMore, previousData } = useQuery(GET_ACTIVITY, {
        variables: {
            offset: 0,
            limit: 10
        },
        notifyOnNetworkStatusChange: true
    })

    if (loading && !data) return '...';
    if (error) return 'error';

    let hasMore = true;
    if (data.activity.length < 10 || (previousData && data.activity.length === previousData.activity.length)) {
        hasMore = false;
    }

    const loadMoreEvents = () => {
        fetchMore({
            variables: {
                offset: data.activity.length
            }
        })
    }

    return (
        <div className='SnippetDocContainer SnippetHorizontalContainer'>
            <SnippetTopMenu currentUser={currentUser} />
            <h1>Activity</h1>
            <div className='margin-left-20 margin-top-32'>
                <ActivityFeed 
                    events={data.activity} 
                    onLoadMore={loadMoreEvents}
                    loading={loading}
                    totalEvents={data.eventCount}
                />
            </div>
        </div>
    )
}

export default ActivityPage;