import { gql } from "@apollo/client";

const MY_DOCS = gql`
    query MyDocs($team: String, $docIDs: [String]) {
        recentDocs: docs(docIDs: $docIDs) {
            title
            team
            docID
            created
        }
        allDocs: docs(team: $team) {
            title
            docID
            created
        }
    }
`

export default MY_DOCS;