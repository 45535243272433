export const SET_USER = 'SET_USER';
export const SET_LOGIN_REDIRECT = 'SET_LOGIN_REDIRECT';
export const SET_INVITE_REDIRECT = 'SET_INVITE_REDIRECT';

export const SET_FOCUSED_TEXT_BLOCK = 'SET_FOCUSED_TEXT_BLOCK';
export const SET_TITLE_CHANGES = 'SET_TITLE_CHANGES';

export const SET_DRAFT_LINK_ACTION = 'SET_DRAFT_LINK_ACTION';

export const SET_TOP_MENU_CONTEXT = 'SET_TOP_MENU_CONTEXT';
export const SET_MUTUAL = 'SET_MUTUAL';