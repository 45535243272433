import { Dialog } from '@blueprintjs/core';
import React, { useState } from 'react';
import ModalTopBar from '../ui/ModalTopBar/ModalTopBar';
import SignUp from '../SignUpPage/SignUp/SignUp';
import SignIn from '../SignIn/SignIn';

const EntryModal = ({
    isOpen,
    handleClose,
    entryWay,
    setEntryWay
}) => {

    const toggleSignUp = () => setEntryWay('SIGN_UP');
    const toggleSignIn = () => setEntryWay('LOG_IN');

    return (
        <Dialog 
            className='cw-modal'
            isOpen={isOpen}
            onClose={handleClose}
            transitionDuration={300}
            transitionName='fade'
        >
            <ModalTopBar 
                minimal
                onClose={handleClose}
            />
            <div className='my-12'>
                {entryWay === 'SIGN_UP' && (
                    <SignUp 
                        toggleSignIn={toggleSignIn}
                        handleClose={handleClose}
                    />
                )}
                {entryWay === 'LOG_IN' && (
                    <SignIn 
                        toggleSignUp={toggleSignUp}
                        handleClose={handleClose}
                    />
                )}
            </div>
        </Dialog>
    )
}

export default EntryModal;