import { gql } from "@apollo/client";

const GET_USERS = gql`
    query GetUsers($query: String) {
        users(query: $query, limit: 10) {
            _id
            username
            profilePicPath
        }
    }
`

export default GET_USERS;