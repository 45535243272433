import React from 'react';
import { Link } from 'react-router-dom';
import DateWithTooltip from '../../../ui/DateWithTooltip/DateWithTooltip';
import { getFullDate, getReadableShortDate } from '../../../ui/FormattedDate/dateUtils';
import PositionedIcon from '../../../ui/PositionedIcon/PositionedIcon';

const VersionContextCard = ({
    prev,
    next,
    docID,
    teamSlug,
    version,
    changeMessage,
    creator,
    created
}) => {

    const dateString = getReadableShortDate(created);
    let message = changeMessage && changeMessage !== '' ? changeMessage : 'Untitled version';

    return (
        <Link to={`/${teamSlug}/${docID}/history/${version}`} className='no-deco'>
            <div className='content-card'>
                <p className='no-margin font-size-12 font600 ellipsis soft-text'>
                    <PositionedIcon
                        icon={prev ? 'arrow-left' : 'arrow-right'}
                        iconSize={10}
                        top={-2}
                        marginRight={6}
                    />
                    {version === 1 && 'First draft'}
                    {version !== "1" && changeMessage && changeMessage !== '' ? message : 'Untitled version'}
                </p>
                <p className='font-size-12 no-margin'>
                    <span className='margin-right-4 soft-text'>
                        {creator.username} 
                    </span>
                    <DateWithTooltip
                        date={created}
                        text={dateString}
                    />
                </p>
            </div>
        </Link>
    )
}

export default VersionContextCard;