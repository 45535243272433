import { DateTime } from "luxon";

export const getReadableDate = (date) => {
    const createdDate = new Date(date);
    const createdDateTime = DateTime.fromJSDate(createdDate);
    const createdTime = createdDateTime.toLocaleString(DateTime.TIME_SIMPLE);
    const formattedDate = createdDateTime.toFormat(`MMM d`);
    const relDate = createdDateTime.toRelativeCalendar();
    let dateString = `${formattedDate} at ${createdTime}`;
    
    if (relDate === 'today') {
        dateString = `${createdTime}`
    }
    
    if (relDate === 'yesterday') {
        dateString = `${relDate} at ${createdTime}`
    }

    return dateString;
}

export const getRelativeDate = (date) => {
    const createdDate = new Date(date);
    const createdDateTime = DateTime.fromJSDate(createdDate).toRelative();
    return createdDateTime;
}

export const getReadableShortDate = (date) => {
    const createdDate = new Date(date);
    const createdDateTime = DateTime.fromJSDate(createdDate);
    const formattedDate = createdDateTime.toFormat(`MMM d`);
    const relDate = createdDateTime.toRelativeCalendar();
    
    if (relDate === 'today') return relDate;
    if (relDate === 'yesterday') return relDate;
    return formattedDate
} 

export const getShortDateAndMonth = (date) => {
    const createdDate = new Date(date);
    const createdDateTime = DateTime.fromJSDate(createdDate);
    const formattedDate = createdDateTime.toFormat(`MMM d`);
    return formattedDate
}

export const getDateAndMonth = (date) => {
    const createdDate = new Date(date);
    const createdDateTime = DateTime.fromJSDate(createdDate);
    const formattedDate = createdDateTime.toFormat(`MMMM d`);
    return formattedDate
}

export const getFullDate = (date) => {
    const createdDate = new Date(date);
    const createdDateTime = DateTime.fromJSDate(createdDate);
    const createdTime = createdDateTime.toLocaleString(DateTime.TIME_SIMPLE);
    const formattedDate = createdDateTime.toLocaleString(DateTime.DATE_MED).substring(0, 5);
    let dateString = `${formattedDate} at ${createdTime}`;
    return dateString;
}

