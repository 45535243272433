import React from 'react';

const UsernameDisplay = ({ usernames }) => {
  // Take only the first three usernames
  const displayUsernames = usernames.slice(0, 3);

  // Join the usernames with commas
  const usernameString = displayUsernames.join(', ');

  // Add ellipsis if there are more than three usernames
  const displayString = usernames.length > 3 ? `${usernameString}...` : usernameString;

  return (
    <span className="username-display truncate ellipsis">
      {displayString}
    </span>
  );
};

export default UsernameDisplay;