import { Button, Icon, Tag } from '@blueprintjs/core';
import React from 'react';
import './ProposalBalance.css';
import PositionedIcon from '../../ui/PositionedIcon/PositionedIcon';
import { DANGER, SUCCESS } from '../../../utils/colors';

const ProposalBalance = ({
    votes
}) => {
    return ( 
        <div className='ProposalBalance'>
            {votes.for > 0 && (
                <div className='VoteIndicator text-white'>
                    <Tag className='neutral-dark'>
                        <PositionedIcon 
                            icon='thumbs-up' 
                            color={'#fff'}
                            iconSize={10}
                            marginRight={0}
                            top={-1}
                        /> {votes.for}
                    </Tag>
                </div>
            )}
            {votes.against > 0 && (
                <div className='VoteIndicator text-white'>
                    <Tag className='neutral-light'>
                        <PositionedIcon 
                            icon='thumbs-down' 
                            color={'#000'}
                            iconSize={10}
                            marginRight={0}
                            top={-1}
                        /> {votes.against}
                    </Tag>
                </div>
            )}
        </div>
    )
}

export default ProposalBalance;