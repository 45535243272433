import { Icon } from '@blueprintjs/core';
import React from 'react';
import UsernameDisplay from './UsernameDisplay/UsernameDisplay';
import { useNavigate, useParams } from 'react-router';

function sortUsernames(objects) {
    // Step 1: Extract usernames and count occurrences
    const usernameCounts = objects.reduce((counts, obj) => {
      const username = obj.author.username;
      counts[username] = (counts[username] || 0) + 1;
      return counts;
    }, {});
  
    // Step 2: Create an array of unique usernames
    const uniqueUsernames = Object.keys(usernameCounts);
  
    // Step 3: Sort usernames based on their count (descending order)
    uniqueUsernames.sort((a, b) => usernameCounts[b] - usernameCounts[a]);
  
    return uniqueUsernames;
}

const ProposalsSidebar = ({
    proposals,
    onOpenSuggestions
}) => {

    const navigate = useNavigate();
    const { teamSlug, docID } = useParams();

    if (proposals.length === 0) {
        return null;
    }

    const usernames = sortUsernames(proposals);

    return (
        <div>
            <div className='border border-gray-200 bg-white shadow-sm rounded-2xl px-2 py-0.5 flex items-center cursor-pointer'
                onClick={onOpenSuggestions}
            >
                <Icon
                    icon='lightbulb'
                    color='#aaa'
                    size={12}
                />
                <div className='text-xs font-medium'>
                    <span className='text-gray-500'>{proposals.length > 1 ? proposals.length : null}</span>
                    <span className='ml-1'>
                        <UsernameDisplay usernames={usernames} />
                    </span>
                </div>
            </div>
        </div>
    )

}

export default ProposalsSidebar;