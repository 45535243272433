import React from 'react';
import { DateTime } from 'luxon';

const FormattedDate = ({
    date,
    dateFormat = 'DATE_MED'
}) => {
    const theDate = DateTime.fromISO(date);
    const formattedDate = theDate.toLocaleString(DateTime[dateFormat]);
    return formattedDate;
}

export default FormattedDate;