import React from 'react';
import SnippetTopMenu from '../../SnippetEditor/SnippetTopMenu/SnippetTopMenu';
import CopyLink from '../../ui/CopyLink/CopyLink';
import ProposalsSidebarSkeleton from '../SnippetProposals/ProposalsSidebarSkeleton/ProposalsSidebarSkeleton';
import './DocSkeleton.css';

const DocSkeleton = ({
    currentUser
}) => {

    return (
        <div className='SnippetDoc DocSkeleton'>
            <SnippetTopMenu currentUser={currentUser} />
            <div className='ml-60 mt-20 pr-20 pl-16 mb-48'>
                <h3 className='bp3-skeleton skeleton'
                    style={{
                        width: 75,
                        marginLeft: 20
                    }}>
                        test
                </h3>
                <h1 
                    className='bp3-skeleton skeleton' 
                    style={{ width: '64%', fontSize: 36, marginBottom: 8, marginLeft:20 }}
                >
                    Skeleton
                </h1>
                <p 
                    className='bp3-skeleton skeleton'
                    style={{ 
                        margin: '0 20px 16px',
                        fontSize: 18,
                        width: '80%'
                    }}
                >
                    Skeleton
                </p>
                <h3 className='bp3-skeleton skeleton'
                    style={{
                        marginTop: 22,
                        width: 200,
                        marginLeft: 20,
                        marginBottom: 35
                    }}>
                        test
                </h3>
                <div 
                    className='ProtocolCard bp3-skeleton skeleton'
                    style={{ height: 74 }}
                >
                    skeleton
                </div>
                <div style={{
                    marginLeft: 20,
                    marginTop: 29
                }}>
                    <div className='SnippetDocNavItemContainer'>
                        <span className='bp3-skeleton skeleton margin-left-8 margin-right-4'>Text</span>
                    </div>
                    <div className='SnippetDocNavItemContainer'>
                        <span className='bp3-skeleton skeleton margin-left-4'>
                            Suggestions
                        </span>
                    </div>
                    <div className='SnippetDocNavItemContainer'>
                        <span className='bp3-skeleton skeleton margin-left-4'>
                            History
                        </span>
                    </div>
                </div>
                <div className='SnippetDocTextContainer'>
                    <div className='SnippetDocText sm:w-[672px]'>
                        <div className='EditBar'>
                            <h3 className='bp3-skeleton skeleton'
                                style={{
                                    marginTop: 34,
                                    width: 316,
                                    marginLeft: 20,
                                    marginBottom: 24
                                }}>
                                    test
                            </h3>
                        </div>
                        <p 
                            className='bp3-skeleton skeleton mb-3' 
                            style={{ 
                                marginLeft: 20,
                                marginRight: 10,
                                height: 100
                            }}
                        >
                            skeleton
                        </p>
                        <p 
                            className='bp3-skeleton skeleton mb-3' 
                            style={{ 
                                marginLeft: 20,
                                marginRight: 10,
                                height: 60
                            }}
                        >
                            skeleton
                        </p>
                        <p 
                            className='bp3-skeleton skeleton mb-3' 
                            style={{ 
                                marginLeft: 20,
                                marginRight: 10,
                                height: 40
                            }}
                        >
                            skeleton
                        </p>
                        <p 
                            className='bp3-skeleton skeleton mb-3' 
                            style={{ 
                                marginLeft: 20,
                                marginRight: 10,
                                height: 100
                            }}
                        >
                            skeleton
                        </p>
                        <p 
                            className='bp3-skeleton skeleton mb-3' 
                            style={{ 
                                marginLeft: 20,
                                marginRight: 10,
                                height: 40
                            }}
                        >
                            skeleton
                        </p>
                        <p 
                            className='bp3-skeleton skeleton mb-3' 
                            style={{ 
                                marginLeft: 20,
                                marginRight: 10,
                                height: 100
                            }}
                        >
                            skeleton
                        </p>
                        <p 
                            className='bp3-skeleton skeleton mb-3' 
                            style={{ 
                                marginLeft: 20,
                                marginRight: 10,
                                height: 100
                            }}
                        >
                            skeleton
                        </p>
                    </div>
                    <div 
                        className='SnippetDocSidebar'
                        style={{
                            marginTop: 34
                        }}
                    >
                        <ProposalsSidebarSkeleton />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocSkeleton;