import React, { useEffect, useState } from 'react';
import SnippetTopMenu from '../SnippetEditor/SnippetTopMenu/SnippetTopMenu';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../store/actions/index';   
import './UserProfile.css';
import { Button, Icon } from '@blueprintjs/core';
import { useLazyQuery, useQuery } from '@apollo/client';
import USER from './queries/user';
import { useParams } from 'react-router';
import USER_ACTIVITY from './queries/userActivity';
import UserContributions from './UserContributions/UserContributions';
import DocIndexItem from '../Dashboard/DocIndexItem/DocIndexItem';
import DocCard from '../DocCard/DocCard';
import ActivityFeed from '../ActivityPage/ActivityFeed/ActivityFeed';
import EditProfileModal from './EditProfileModal/EditProfileModal';
import CrowdwriteLogo from '../ui/svg/CrowdwriteLogo';
import GlobalSpinner from '../ui/GlobalSpinner/GlobalSpinner';
import SideNav from '../SideNav/SideNav';

const UserProfile = () => {

    const { username } = useParams();
    const dispatch = useDispatch();

    const currentUser = useSelector(state => state.auth.user);
    const userCanEditProfile = currentUser.username === username;

    const [editModalIsOpen, toggleEditModal] = useState(false);
    const onOpenEditModal = () => toggleEditModal(true);
    const onCloseEditModal = () => toggleEditModal(false);

    const { loading, error, data } = useQuery(USER, {
        variables: {
            username: username
        },
    });

    const [
        getUserActivity, 
        { 
            loading: activityLoading, 
            error: activityError, 
            data: activityData,
            fetchMore,
            previousData: prevActivityData
        }
    ] = useLazyQuery(USER_ACTIVITY, {
        notifyOnNetworkStatusChange: true
    });

    const loadMoreEvents = () => {
        fetchMore({
            variables: {
                offset: activityData.activity.length
            }
        })
    }    

    useEffect(() => {
        if (data && data.user._id) {
            if (data.user.name) {
                dispatch(actions.setMutual(data.user.name));
            }
            getUserActivity({
                variables: {
                    id: data.user._id,
                    offset: 0,
                    limit: 10
                }
            })
        }
    }, [data])

    if (loading) { return <GlobalSpinner /> }
    if (error) { return 'error' }

    let docCards;
    if (activityData) {
        docCards = activityData.docs.map((doc, i) => {
            return <DocCard key={i} {...doc} />
        })
    } 

    const profilePicURL = data.user.profilePicPath && data.user.profilePicPath !== '' ? `${process.env.REACT_APP_IMAGE_CDN_BASE_URL}${data.user.profilePicPath}` : null;

    return (
        <div className='UserProfile mt-24 ml-60 mt-20 pr-20 pl-16 mb-48'>
            <div className='ProfileHeader margin-bottom-32 margin-left-20'>
                <div className='ProfilePic'>
                    {profilePicURL && (
                        <img 
                            src={profilePicURL} 
                            alt='Profile Picture' 
                            className='border border-gray-200'
                        />
                    )}
                    {!profilePicURL && (
                        <div className='PlaceholderGraphic flex justify-center -mt-2'>
                            <CrowdwriteLogo width={88} fill='#fff'/>
                        </div>
                    )}
                </div>
                <div className='ProfileInfo margin-left-20'>
                    <div className='ProfileNameRow'>
                        <h1 className='margin-bottom-2'>
                            {data.user.name ? data.user.name : data.user.username}
                        </h1>
                        {userCanEditProfile && (
                            <div className='ProfileNameRowBtns'>
                                <Button 
                                    // className='primary-neutral-btn'
                                    text={'Edit profile'}
                                    onClick={onOpenEditModal}
                                />
                            </div>
                        )}
                    </div>
                    <p className='margin-top-2'>@{data.user.username}</p>
                    <div className='ProfileMetadata'>
                        {data.user.bio && (
                            <p className='my-2'>{data.user.bio}</p>
                        )}
                        {data.user.location && (
                            <p className='soft-text ProfileItem'>
                                <Icon icon='map-marker' size={11}/> {data.user.location}
                            </p>
                        )}
                        {data.user.created && (
                            <p className='soft-text ProfileItem'>
                                <Icon icon='calendar' size={11}/> Joined June 2022
                            </p>
                        )}
                        {data.user.link && (
                            <p className='soft-text ProfileItem'>
                                <Icon icon='link' size={11}/> <a href={data.user.link} target='_blank'>{data.user.link}</a>
                            </p>
                        )}
                    </div>
                    {/* {(activityData && activityData.userEdits && activityData.userEdits !== 0) ? (
                        <p className='soft-text ProfileItem'><b>{activityData.userEdits}</b> edit{activityData.userEdits > 1 ? 's' : null}</p>
                    ) : null} */}
                </div>
            </div>
            <EditProfileModal
                isOpen={editModalIsOpen}
                onClose={onCloseEditModal}
                name={data.user.name}
                title={data.user.title}
                link={data.user.link}
                location={data.user.location}
                bio={data.user.bio}
                profilePicPath={data.user.profilePicPath}
            />
            {activityData && (
                <div className='margin-left-20'>
                    <h2 className='margin-bottom-16 ProfileSectionHeader'>
                        <span>Documents</span>
                        <div className='line'></div>
                    </h2>
                    <div className='DocCards'>
                        {docCards}
                    </div>
                    <div className='Activity margin-top-48'>
                        <h2 className='margin-bottom-16 ProfileSectionHeader'>
                            <span>Activity</span>
                            <div className='line'></div>
                        </h2>
                        <ActivityFeed 
                            events={activityData.activity} 
                            context='PROFILE'
                            loading={activityLoading}
                            onLoadMore={loadMoreEvents}
                            totalEvents={activityData.eventCount}
                        />
                    </div>
                </div>
            )}
        </div>
    )

}

export default UserProfile;