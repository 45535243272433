import * as actionTypes from './actionTypes';

export const setFocusedTextBlock = (blockKey) => {
    return { 
        type: actionTypes.SET_FOCUSED_TEXT_BLOCK,
        blockKey
    }
}

export const setTitleChanges = (titleChanges) => {
    return {
        type: actionTypes.SET_TITLE_CHANGES,
        titleChanges
    }
}

export const setDraftLinkAction = (linkAction) => {
    return {
        type: actionTypes.SET_DRAFT_LINK_ACTION,
        draftAction: linkAction
    }
}

export const setTopMenuContext = (topMenuContext) => {
    return {
        type: actionTypes.SET_TOP_MENU_CONTEXT,
        topMenuContext
    }
}

export const setMutual = (mutual) => {
    return {
        type: actionTypes.SET_MUTUAL,
        mutual
    }
}