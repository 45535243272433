
const headingTypes = [
  'header-one',
  'header-two',
  'header-three',
  'header-four',
  'header-five',
  'header-six'
]

const headingLevels = {
  'header-one': 1,
  'header-two': 2,
  'header-three': 3,
  'header-four': 4,
  'header-five': 5,
  'header-six': 6
}

export const getHeadings = (text) => {
  const headings = text.filter(block => headingTypes.includes(block.type)).map(block => {
      return {
          _id: block._id,
          type: block.type,
          text: block.text,
          level: headingLevels[block.type]
      }
  });
  return headings;
} 

export const getSections = (headings, text) => {
  let sectionArray = headings.map(heading => {
      return {
          heading: heading._id,
          blocks: []
      }
  });
  
  let sectionIndex = 0;
  text.map(block => {
      if (headingTypes.includes(block.type) && block._id !== sectionArray[0].heading) {
          sectionIndex++;
      }
      sectionArray[sectionIndex].blocks.push(block._id);
  });
  return sectionArray;
}

export const getActiveHeading = (sections, activeSection) => {
  const activeHeadingObj = sections.find(section => section.blocks.includes(activeSection) || section.heading === activeSection); 
  return activeHeadingObj ? activeHeadingObj.heading : null;
} 

export const generateTOC = (headings, activeHeading) => {
    // Function to create a new TOC item
    const createTOCItem = (text, _id, level) => ({ 
      text, 
      _id, 
      level, 
      isActive: _id === activeHeading,
      items: [] 
    });
  
    // Helper function to find the parent for a given level
    const findParent = (stack, level) => {
      while (stack.length > 0 && stack[stack.length - 1].level >= level) {
        stack.pop();
      }
      return stack.length > 0 ? stack[stack.length - 1].items : toc;
    };
  
    const toc = [];
    const stack = [];
  
    headings.forEach(({ text, _id, level }) => {
      const item = createTOCItem(text, _id, level);
      const parent = findParent(stack, level);
      parent.push(item);
      stack.push(item);
    });
  
    return toc;
}