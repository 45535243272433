import React from 'react';
import { Link } from 'react-router-dom';
import StatusTag from '../../../ui/StatusTag/StatusTag';
import PositionedIcon from '../../../ui/PositionedIcon/PositionedIcon';

const DocTag = ({
    title,
    link,
    status
}) => {

    return (
        <Link 
            to={link}
            className='activity-tag-link'
        >
            <PositionedIcon icon='document' iconSize={9} top={-2} />
            <span className='margin-left-8'>{title ? title : 'Untitled document'}</span>
        </Link>
    )
}

export default DocTag;