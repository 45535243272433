import React, { Fragment, useState } from 'react';
import StringDiff from './StringDiff/StringDiff';
import { convertFromRaw } from 'draft-js'
import { convertToHTML } from 'draft-convert';
import parse, { attributesToProps, domToReact } from 'html-react-parser';
import { Icon } from '@blueprintjs/core';
import './BlockDiff.css';
import { addStartToOrderedList, formatNestedUnorderedList, StructuredOrderedList } from '../../../../DocText/TextBlock/textBlockUtils/listItemUtils';
import BlockActionMenu from './BlockActionMenu/BlockActionMenu';
import CommentEditor from './CommentEditor/CommentEditor';
import BlockFeedback from './BlockFeedback/BlockFeedback';
import { getEntityMapFromArray } from '../../../../DocText/DocEditorModal/entityUtils';

const BlockDiff = ({
    canComment,
    _id,
    diffStatus,
    text,
    textDiff,
    tempBlockKey,
    listItemIndex,
    depth,
    type,
    inlineStyleRanges,
    entityRanges,
    entities,
    comments
}) => {

    const [isHovering, toggleHovering] = useState(false);
    const onMouseEnter = () => {
        if (!canComment) return;
        // toggleHovering(true);
    }
    const onMouseLeave = () => {
        if (!canComment) return;
        // toggleHovering(false);
    }

    const [showingComments, toggleComments] = useState(false);
    const showComments = () => toggleComments(true);
    const hideComments = () => toggleComments(false);
    const onToggleComments = () => toggleComments(!showingComments);

    // TODO: deduplicate / standardize a function to format the content across BlockDiff and TextBlock
    const rawContent = {
        blocks: [{
            key: tempBlockKey,
            depth: depth,
            type: type,
            text: text,
            inlineStyleRanges: inlineStyleRanges,
            entityRanges: entityRanges
        }], 
        entityMap: getEntityMapFromArray(entities) // TODO: add link entities
    }

    const contentState = convertFromRaw(rawContent);
    const htmlContent = convertToHTML({
        entityToHTML: (entity, originalText) => {
            let htmlText;
            if (entity.type === 'LINK') {
                htmlText = <a href={entity.data.url} target="_blank">{originalText}</a>;
            } 
            return htmlText;
        }
    })(contentState);

    let htmlDomNode;
    let richText = parse(htmlContent, {
        replace: (domNode) => {
            if (type === 'ordered-list-item') {
                htmlDomNode = domNode;
                return addStartToOrderedList(domNode, depth, listItemIndex, attributesToProps, domToReact);
            }
            if (type === 'unordered-list-item') {
                return formatNestedUnorderedList(domNode, depth)
            }
            else return <span>{domToReact(domNode.children)}</span>
        }
    });

    let content;
    switch (diffStatus) {
        case 'MODIFIED': content = (
            <StringDiff 
                parts={textDiff} 
                styleRanges={inlineStyleRanges} 
                entityRanges={entityRanges}
                entities={entities}
                depth={depth} 
                listItemIndex={listItemIndex} 
                type={type}
            />
        ); break;
        case 'ADDED':
        case 'REMOVED': 
        case 'UNCHANGED':
        case 'CONFLICT':
        default: content = richText;
    }

    let contentBlock;
    switch (type) {
        case 'header-one': contentBlock = <h1><span className='Content'>{content}</span></h1>; break;
        case 'header-two': contentBlock = <h2><span className='Content'>{content}</span></h2>; break;
        case 'header-three': contentBlock = <h3><span className='Content'>{content}</span></h3>; break;
        case 'header-four': contentBlock = <h4><span className='Content'>{content}</span></h4>; break;
        case 'header-five': contentBlock = <h5><span className='Content'>{content}</span></h5>; break;
        case 'header-six': contentBlock = <h6><span className='Content'>{content}</span></h6>; break;
        case 'blockquote': contentBlock = <blockquote><span className='Content'>{content}</span></blockquote>; break;
        case 'unordered-list-item': 
            contentBlock = <ul>{content}</ul>; 
            if (diffStatus === 'MODIFIED') contentBlock = <ul><li>{content}</li></ul>;
            break;
        case 'ordered-list-item': 
            contentBlock = content; 
            if (diffStatus === 'MODIFIED') contentBlock = (
                <StructuredOrderedList
                    domNode={htmlDomNode}
                    depth={depth}
                    startIndex={listItemIndex}
                >
                    {content}
                </StructuredOrderedList>
            );
            break;
        case 'unstyled': 
        default: contentBlock = <p><span className='Content'>{content}</span></p>; break;
    }

    let classes = ['BlockDiff', type, diffStatus.toLowerCase()];

    return (
        <Fragment>
            <div className={classes.join(' ')}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                {diffStatus === 'CONFLICT' && <Icon icon='help' />}
                {contentBlock}
                {(isHovering || showingComments) && (
                    <BlockActionMenu 
                        diffStatus={diffStatus}
                        showingComments={showingComments}
                        onToggleComments={onToggleComments}
                    />
                )}
            </div>
            <BlockFeedback
                blockID={_id}
                comments={comments}
                showingComments={showingComments}
                toggleComments={toggleComments}
            />
        </Fragment>
    )
}

export default BlockDiff;