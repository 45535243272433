import { useMutation } from '@apollo/client';
import { Button, TextArea } from '@blueprintjs/core';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import SignUpModal from '../../../../../../SignUpModal/SignUpModal';
import Avatar from '../../../../../../ui/Avatar/Avatar';
import CREATE_COMMENT from '../../../../../DocProposals/ProposalModal/DocTextDiff/BlockDiff/CommentEditor/mutations/createComment';
import './ThreadAddComment.css';

const ThreadAddComment = ({
    threadID,
    docTextID,
    docID
}) => {

    const activeUser = useSelector(state => state.auth.user);
    const [blockID, highlightStart, highlightEnd] = threadID.split('-');

    const [showingSignUpModal, toggleSignUpModal] = useState(false);
    const showSignUpModal = () => toggleSignUpModal(true);
    const closeSignUpModal = () => toggleSignUpModal(false);

    const inputRef = useRef(null);
    const currentUser = useSelector(state => state.auth.user);

    const [commentVal, setCommentVal] = useState('');
    const onSetCommentVal = (e) => setCommentVal(e.target.value);

    const [createComment, { loading: sendingComment, error, data}] = useMutation(CREATE_COMMENT, {
        update(cache, response) {
            const updatedThreadID = `BlockThread:${threadID}`;
            cache.modify({
                id: updatedThreadID,
                fields: {
                    comments(cachedComments, { toReference }) {
                        return [
                            ...cachedComments,
                            toReference(response.data.createComment)
                        ];
                    },
                    commentCount(value) {
                        return value + 1;
                    }
                }
            });
        }
    });
    const onCreateComment = () => {
        if (!activeUser._id) {
            showSignUpModal();
        }
        if (activeUser._id) {
            createComment({
                variables: {
                    input: {
                        text: commentVal,
                        blockID: blockID,
                        highlightStart: Number(highlightStart),
                        highlightEnd: Number(highlightEnd),
                        docTextID: docTextID,
                        docID: docID,
                    }
                },
                onCompleted: () => {
                    setCommentVal('');
                }
            })
        }
    }

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const noContent = commentVal === '' || commentVal.trim().length === 0;
    const onKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (!noContent) onCreateComment();
        }
    }

    return (
        <Fragment>
            <div className='ThreadAddComment'>
                <Avatar {...currentUser} />
                <div className={commentVal !== '' ? 'ThreadAddCommentBox active' : 'ThreadAddCommentBox'}>
                    <TextArea fill
                        autoFocus={true}
                        growVertically={true}
                        placeholder='Add a comment'
                        value={commentVal}
                        onChange={onSetCommentVal}
                        onKeyDown={onKeyDown}
                    />
                    <div className='CommentControls'>
                        <Button 
                            className='SendCommentBtn'
                            small
                            intent='primary'
                            loading={sendingComment}
                            disabled={commentVal === ''}
                            onClick={onCreateComment}
                            icon='send-message'
                        />
                    </div>
                </div>
            </div>
            <SignUpModal
                initialPanel='SIGN_IN'
                isOpen={showingSignUpModal}
                onClose={closeSignUpModal}
            />
        </Fragment>
    )
}

export default ThreadAddComment;