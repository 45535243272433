import { Button, Callout, Card, Menu, MenuItem } from '@blueprintjs/core';
import React, { useState } from 'react';
import PositionedIcon from '../ui/PositionedIcon/PositionedIcon';
import SideNavBtn from './SideNavBtn/SideNavBtn';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../store/selectors/authSelectors';
import { Link } from 'react-router-dom';
import CrowdwriteLogo from '../ui/svg/CrowdwriteLogo';
import { faFile, faUser } from '@fortawesome/free-solid-svg-icons'
import EntryModal from '../EntryModal/EntryModal';

const SideNav = ({

}) => {

    // let currentUser;
    const currentUser = useSelector(getCurrentUser);
    const isLoggedIn = currentUser && currentUser._id !== null;

    const [showSignInModal, toggleEntryModal] = useState(false);
    const onOpenSignInModal = () => toggleEntryModal(true);
    const onCloseSignInModal = () => toggleEntryModal(false);
    const [entryWay, setEntryWay] = useState('SIGN_UP');

    return (
        <nav className='fixed top-0 left-0 w-60 h-full bg-white'>
            <div className='mt-6'>
                <div className='Logo mx-4 mb-6'>
                    <Link to={currentUser && currentUser._id ? '/docs' : '/'}>
                        <CrowdwriteLogo width={24} />
                    </Link>
                </div>
                {!isLoggedIn && (
                    <div className='px-3'>
                        <Button 
                            fill large
                            intent='offwhite-hover'
                            className='bg-offwhite-hover hover:opacity-80'
                            onClick={onOpenSignInModal}
                        >
                            Join Crowdwrite
                        </Button>
                    </div>
                )}
                {isLoggedIn && (
                    <div className='flex flex-col px-0 gap-0'>
                        <SideNavBtn
                            label='Docs'
                            icon={faFile}
                            link={`/docs`}
                        />
                        {/* <SideNavBtn
                            label='Profile'
                            icon={faUser}
                            link={`/user/${currentUser.username}`}
                        /> */}
                    </div>
                )}
            </div>
            <EntryModal
                entryWay={entryWay}
                setEntryWay={setEntryWay}
                isOpen={showSignInModal}
                handleClose={onCloseSignInModal} 
            />
        </nav>
    )

}

export default SideNav;