import React, { useState } from 'react';
import DocSettingsEditor from '../../../SnippetEditor/DocSettingsEditor/DocSettingsEditor';
import { Button, FormGroup, InputGroup } from '@blueprintjs/core';
import Modal from '../../../ui/Modal/Modal';
import { useMutation } from '@apollo/client';
import FORK_DOC from './mutations/forkDoc';
import { useNavigate } from 'react-router';

const ForkDocModal = ({
    onClose,
    isOpen,
    docID
}) => {

    const [docTitle, setDocTitle] = useState('');
    const onChangeDocTitle = (e) => setDocTitle(e.target.value);
    const navigate = useNavigate();

    const [onFork, { loading, error, data }] = useMutation(FORK_DOC, {
        onCompleted: (data) => {
            if (data && data.forkDoc && data.forkDoc.docID) {
                navigate(`/${data.forkDoc.team}/${data.forkDoc.docID}`)
            }
        }
    });

    const onForkDoc = () => {
        onFork({
            variables: {
                input: {
                    newTitle: docTitle,
                    docID: docID
                }
            }
        })
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
        >
            <h2>Fork this document</h2>
            <FormGroup 
                label='New title'
            >
                <InputGroup 
                    value={docTitle}
                    onChange={onChangeDocTitle}
                />
            </FormGroup>
            <div>
                <Button 
                    intent='primary'
                    text='Fork'
                    disabled={docTitle === ``}
                    loading={loading}
                    onClick={onForkDoc}
                />
            </div>
        </Modal>
    );
}

export default ForkDocModal;