import React from 'react';
import SignUp from './SignUp/SignUp';

const SignUpPage = ({

}) => {

    return (
        <div className='h-screen flex flex-col justify-center'>
            <SignUp />
        </div>
    )
}

export default SignUpPage;