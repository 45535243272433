import React from 'react';
import { useParams } from 'react-router';
import ProposalChanges from '../ProposalChanges/ProposalChanges';
import ProposalComments from '../ProposalComments/ProposalComments';

const ProposalBody = ({
    prID,
    data,
    onCreateVote,
    onDeleteVote,
    voteIsLoading,
    deleteVoteIsLoading,
    canApprove,
    canClose,
    user,
    acceptingProposal,
    onAcceptProposal,
    closingProposal,
    onCloseProposal,
    canParticipate
}) => {

    const { prTab } = useParams();

    let content;
    switch (prTab) {
        case 'comments': 
            content = (
                <div className='margin-left-20'>
                    <ProposalComments 
                        prID={prID}
                        activeUser={user ? user._id : null}
                        totalComments={data.proposal.totalComments}
                        status={data.proposal.status}
                        canParticipate={canParticipate}
                    />
                </div>
            ); break;
        case 'changes': 
        default:
            content = (
                <ProposalChanges 
                    prID={prID}
                    doc={data.doc}
                    onCreateVote={onCreateVote}
                    onDeleteVote={onDeleteVote}
                    voteIsLoading={voteIsLoading}
                    deleteVoteIsLoading={deleteVoteIsLoading}
                    canApprove={canApprove}
                    canClose={canClose}
                    user={user}
                    acceptingProposal={acceptingProposal}
                    onAcceptProposal={onAcceptProposal}
                    closingProposal={closingProposal}
                    onCloseProposal={onCloseProposal}
                    canParticipate={canParticipate}
                    {...data.proposal}
                />
            );
    }

    return (
        <div className='margin-top-32'>
            {content}
        </div>
    );
}

export default ProposalBody;