import { gql } from "@apollo/client";

const TEAM_MEMBERS = gql`
    query Team($team: String) {
        team(slug: $team) {
            _id
            members {
                _id
                username
            }
        }
    }
`

export default TEAM_MEMBERS;