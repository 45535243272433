import React, { useState } from 'react';
import BlockDiff from '../BlockDiff/BlockDiff';
import './UnchangedSection.css';
import { getListItemIndex } from '../../../../DocText/TextBlock/textBlockUtils/listItemUtils';
import { Button } from '@blueprintjs/core';
import { getBlockEntities } from '../ChangedSection/ChangedSection';

const UnchangedSection = ({
    blocks,
    canComment,
    diffData,
    comments,
    entities,
    nextIsRelevant,
    prevIsRelevant,
    expandAll = false
}) => {

    const [showBlocks, toggleShowBlocks] = useState(() => expandAll);
    const _onToggleShowBlocks = () => toggleShowBlocks(!showBlocks);

    const renderedBlocks = blocks.map((block, i) => {
        const blockComments = comments ? comments.filter(comment => comment.blockID === block._id) : null;
        const indexInFullDiff = diffData.findIndex(diffBlock => diffBlock._id === block._id);
        const blockEntityRanges = block.entityRanges ? block.entityRanges : [];
        const blockEntities = getBlockEntities(blockEntityRanges, entities);
        return (
            <BlockDiff 
                key={i} 
                canComment={canComment} 
                tempBlockKey={i} 
                listItemIndex={getListItemIndex(diffData, indexInFullDiff)}
                entities={blockEntities}
                comments={blockComments}
                {...block} 
            />
        )
    });

    let wordCount = 0;
    blocks.forEach(block => wordCount += block.text.length);

    let showMoreBtnClasses = 'primary-neutral-btn font-size-12 font600 px-6';
    if (nextIsRelevant) showMoreBtnClasses = 'primary-neutral-btn font-size-12 font600 pull-bottom px-6';
    if (prevIsRelevant) showMoreBtnClasses = 'primary-neutral-btn font-size-12 font600 pull-top px-6';
    if (nextIsRelevant && prevIsRelevant) showMoreBtnClasses = 'primary-neutral-btn font-size-12 font600 px-6';

    let totalChars = 0;
    blocks.map(block => {
        totalChars += block.text.length
    });


    if (blocks.length < 3 || totalChars < 150) {
        return (
            <div className='UnchangedSection'>
                {renderedBlocks}
            </div>
        )
    }

    return (
        <div className='UnchangedSection'>
            {!showBlocks && (
                <div className='UnchangedPreview'>
                    {prevIsRelevant && (
                        <div 
                            className='FadingBlocks prev'
                            onClick={_onToggleShowBlocks}
                        >
                            {renderedBlocks[0]}
                            <div className='FadePrev' />
                        </div>
                    )}
                    <div className='flex justify-center relative z-10 -top-3'>
                        <Button
                            className={showMoreBtnClasses}
                            onClick={_onToggleShowBlocks}
                        >
                            Show more
                        </Button>
                    </div>
                    {nextIsRelevant && (
                        <div 
                            className='FadingBlocks next'
                            onClick={_onToggleShowBlocks}
                        >
                            <div className='FadeNext' />
                            {renderedBlocks[renderedBlocks.length - 1]}
                        </div>
                    )}
                </div>
            )}
            {showBlocks ? renderedBlocks : null}
        </div>
    )
}

export default UnchangedSection;