import React, { useEffect, useState } from 'react';
import { generateTOC, getActiveHeading, getHeadings, getSections } from './tocUtils';
import TOCItem from './TOCItem/TOCItem';

const TOC = ({
    text,
    sectionRefs
}) => {

    const [activeSection, setActiveSection] = useState(null);
    const headings = getHeadings(text);
    const sections = getSections(headings, text);
    const activeHeading = getActiveHeading(sections, activeSection);
    const tocTree = generateTOC(headings, activeHeading);

    const onClickTOCItem = (item) => {
        setActiveSection(item._id);
    }

    const handleScroll = () => {
        let closestSection = null;
        let minDistance = Number.POSITIVE_INFINITY;
        const offset = 100;
    
        sectionRefs.current.forEach(section => {
          const rect = section.getBoundingClientRect();
          const distance = Math.abs(rect.top - offset);
    
          if (distance < minDistance) {
            minDistance = distance;
            closestSection = section;
          }
        });
    
        if (closestSection) {
            setActiveSection(closestSection.id);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);
    
        // Initial call to set the correct hash when the component mounts
        handleScroll();
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
          window.removeEventListener('resize', handleScroll);
        };
    }, []);

    return (
        <div>
            <nav className='sticky relative top-20 mt-12'>
                <ul className='list-none'>
                    {tocTree.map((item) => (
                        <TOCItem key={item._id} item={item} onClickTOCItem={onClickTOCItem} />
                    ))}
                </ul>
            </nav>
        </div>
    )
}

export default TOC;