import { gql } from "@apollo/client";

const BLOCK_COMMENTS = gql`
    query Thread($threadID: String) {
        thread(threadID: $threadID) {
            _id
            comments {
                _id
                author {
                    username
                    profilePicPath
                    _id
                }
                created
                edited
                text
            }
        }
    }
`

export default BLOCK_COMMENTS;