import gql from "graphql-tag"

const PROPOSALS = gql`
    query Proposals($docID: String, $teamID: String, $sort: ProposalSort, $offset: Int, $limit: Int) {
        proposals(docID: $docID, teamID: $teamID, sort: $sort, offset: $offset, limit: $limit) {
            _id
            title
            number
            description
            status
            author {
                username
            }
            created
            comments { 
                _id
            }
            voters {
                stance
                user {
                    _id
                    username
                }
            }
            votes {
                net
                for
                against
            }
        }
    }
`

export const PROPOSALS_WITH_STATUS = gql`
    query Proposals($docID: String, $teamID: String, $status: ProposalStatus, $sort: ProposalSort, $offset: Int, $limit: Int) {
        proposals(docID: $docID, teamID: $teamID, status: $status, sort: $sort, offset: $offset, limit: $limit) {
            _id
            title
            number
            description
            status
            author {
                username
            }
            created
            comments { 
                _id
            }
            voters {
                stance
                user {
                    _id
                    username
                }
            }
            votes {
                net
                for
                against
            }
        }
    }
`

export default PROPOSALS;