import { gql } from "@apollo/client";

const CACHED_PROPOSAL = gql`
    fragment MyProposal on Proposal {
        number
        votes
        text { 
            key
            text
            _id
        }
        diff {
            _id
            key
            contextualID
            text
            diffStatus
            textDiff {
                value
                added
                removed
            }
        }
    }
`

const getCachedProposal = (cache,prID) => {
    const proposal = cache.readFragment({
        id: cache.identify({
            __typename: 'Proposal',
            _id: prID
        }),
        fragment: CACHED_PROPOSAL
    });
    return proposal;
}

export default getCachedProposal;