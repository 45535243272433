import React from 'react';
import Avatar from '../../../../../../ui/Avatar/Avatar';
import { DateTime } from 'luxon';

const ThreadPreview = ({
    threadID,
    firstComment,
    firstCommenter,
    commentCount,
    created,
    onOpenThread
}) => {

    const createdDate = DateTime.fromISO(created);   
    const relativeDate = createdDate.toRelativeCalendar();

    const handleOpenThread = (e) => {
        onOpenThread(e, threadID)
    }

    return (
        <div className='ThreadPreview flex gap-2 hover:bg-offwhite transition-all px-3 py-2 rounded-lg'
            onClick={handleOpenThread}
        >
            <Avatar
                {...firstCommenter} 
                noLink
            />
            <div className='ThreadInfo'>
                <span className='font600'>
                    {firstCommenter.username}
                </span>
                <span className='half-opaque margin-left-12'>
                    {relativeDate}
                </span>
                <p className='text-xs no-margin'>
                    {firstComment}
                </p>
                {commentCount === 1 && (
                    <span className='ThreadReplies half-opaque text-xs'>Reply...</span>
                )}
                {commentCount > 1 && (
                    <span className='ThreadReplies half-opaque text-xs'>
                        {commentCount - 1} {commentCount > 2 ? 'replies' : 'reply'}
                    </span>
                )}
            </div>
        </div>
    )
}

export default ThreadPreview;