import React, { Fragment, useEffect, useState } from 'react';
import { Button, TextArea } from '@blueprintjs/core';
import './CommentEditor.css';
import { useMutation } from '@apollo/client';
import CREATE_COMMENT from './mutations/createComment';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import SignUpModal from '../../../../../../SignUpModal/SignUpModal';
import Avatar from '../../../../../../ui/Avatar/Avatar';
import SignInPrompter from '../../../../../../ui/SignInPrompter/SignInPrompter';

const CommentEditor = ({
    blockID,
    refetchQueries
}) => {

    const { docID, prID } = useParams();

    const currentUser = useSelector(state => state.auth.user);
    const notLoggedIn = !currentUser || !currentUser.username;

    const [showingSend, toggleSend] = useState(false);
    const onShowSend = () => toggleSend(true);

    const [commentText, setCommentText] = useState('');
    const onSetCommentText = (e) => {
        setCommentText(e.target.value);
    }

    const [isFocused, toggleFocused] = useState(false);
    const onFocus = () => {
        onShowSend();
        toggleFocused(true);
    }
    const onBlur = () => {
        if (commentText === '') toggleSend(false);
        toggleFocused(false);
    }

    const onCancel = () => {
        toggleSend(false);
        setCommentText('');
    }

    const noContent = commentText === '' || commentText.trim().length === 0;

    const [createComment, { 
        loading: creatingComment, 
        error: errorCommenting,
        data: commentData 
    }] = useMutation(CREATE_COMMENT, {
        update(cache, response) {
            const proposalID = `Proposal:${prID}`;

            // if there's no blockID, the comment is being added at the document level
            if (!blockID) {
                const cachedDocID = cache.identify({ docID: docID, __typename: 'Doc' });
                cache.modify({
                    id: cachedDocID,
                    fields: {
                        comments(cachedComments, { toReference }) {
                            return [
                                toReference(response.data.createComment),
                                ...cachedComments
                            ];
                        }
                    }
                });
            }   
            cache.modify({
                id: `BlockThread:${blockID}`,
                fields: {
                    comments(cachedComments, { toReference }) {
                        return [
                            ...cachedComments,
                            toReference(response.data.createComment),
                        ];
                    }
                }
            })
        }
    });

    const onCreateComment = () => {
        createComment({
            variables: {
                input: {
                    text: commentText,
                    blockID,
                    prID,
                    docID
                }
            },
            onCompleted: () => {
                setCommentText('');
                toggleSend(false);
            }
        })
    }

    const onKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (!noContent) onCreateComment();
        }
    }

    return (
        <Fragment>
            <div className={showingSend ? 'CommentEditor active' : 'CommentEditor'}>
                <div className='InputBar'>
                    <Avatar 
                        username={currentUser ? currentUser.username : null} 
                        profilePicPath={currentUser ? currentUser.profilePicPath : null}
                    />
                    <div className='CommentInputContainer'>
                        <TextArea
                            fill
                            growVertically={true}
                            placeholder='Add a comment'
                            value={commentText}
                            onChange={onSetCommentText}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onKeyDown={onKeyDown}
                            onSubmit={onCreateComment}
                        />
                        {(showingSend || isFocused) && (
                            <div className='CommentActions p-2'>
                                <SignInPrompter>
                                    <Button 
                                        small
                                        intent='primary'
                                        text={notLoggedIn ? 'Sign in to comment' : 'Send'}
                                        icon='send-message'
                                        loading={creatingComment}
                                        onClick={onCreateComment}
                                        disabled={noContent}
                                    />
                                </SignInPrompter>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CommentEditor