import React from 'react';
import TextBlock from '../../../Doc/DocText/TextBlock/TextBlock';
import { getListItemIndex } from '../../../Doc/DocText/TextBlock/textBlockUtils/listItemUtils';

const StaticVersion = ({
    text
}) => {

    const textBlocks = text.map((block, i) => (
        <TextBlock key={i} {...block} 
            blockKey={block.key}
            canInteract={false}
            listItemIndex={getListItemIndex(text, i)}
        />
    ));

    return (
        <div className='StaticVersion'>
            {textBlocks}
        </div>
    )
}

export default StaticVersion;