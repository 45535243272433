import { attributesToProps, domToReact } from "html-react-parser";

export const getListItemIndex = (blocks, index) => {
    if (blocks[index].type !== 'ordered-list-item') return null;
    let listItemIndex = 1;
    let n = index;

    // count backwards from current ordered-list-item 
    // to the first item in the list 
    // to determine the index of the current item
    while (n > 0 && blocks[n-1].type === 'ordered-list-item') {
        
        // only increment for blocks at the same depth 
        // (nested lists have distinct orders)
        // but continue decrementing, to maintain correct ordering of parent lists
        if (blocks[n-1].depth === blocks[index].depth) { 
            listItemIndex++ 
        };
        n--;
    }
    return listItemIndex;
}

export const StructuredOrderedList = ({
    domNode,
    depth, 
    startIndex, 
    children
}) => {
    let list = null;
    const props = attributesToProps(domNode.attribs);
    // return 'test';
    if (depth === 0) {
        return (
            <ol {...props} start={startIndex}>
                <li>
                    { children }
                </li>
            </ol>
        )
    }
    if (depth === 1) {
        return (
            <ol {...props} start={startIndex}>
                <ol {...props} start={startIndex}>
                    <li>
                        { children }
                    </li>
                </ol>
            </ol>
        )
    }
    if (depth === 2) {
        return (
            <ol {...props} start={startIndex}>
                <ol {...props} start={startIndex}>
                    <ol {...props} start={startIndex}>
                        <li>
                            { children }
                        </li>
                    </ol>
                </ol>
            </ol>
        )
    }
    if (depth === 3) {
        return (
            <ol {...props} start={startIndex}>
                <ol {...props} start={startIndex}>
                    <ol {...props} start={startIndex}>
                        <ol {...props} start={startIndex}>
                            { children }
                        </ol>
                    </ol>
                </ol>
            </ol>
        )
    }
    else return list;
}

export const formatNestedUnorderedList = (domNode, depth) => {
    let list;
    const props = attributesToProps(domNode.attribs);
    if (depth === 0) {
        list = (
            <ul {...props}>
                { domToReact(domNode.children) }
            </ul>
        )
    }
    if (depth === 1) {
        list = (
            <ul {...props}>
                <ul {...props}>
                    { domToReact(domNode.children[0].children) }
                </ul>
            </ul>
        )
    }
    if (depth === 2) {
        list = (
            <ul {...props}>
                <ul {...props}>
                    <ul {...props} >
                        { domToReact(domNode.children[0].children[0].children) }
                    </ul>
                </ul>
            </ul>
        )
    }
    return list;
}

export const addStartToOrderedList = (domNode, depth, startIndex, attributesToProps) => {
    if (domNode.name === 'ol') {
        const props = attributesToProps(domNode.attribs);

        let list;
        if (depth === 0) {
            list = (
                <ol {...props} start={startIndex}>
                    { domToReact(domNode.children) }
                </ol>
            )
        }
        if (depth === 1) {
            list = (
                <ol {...props} start={startIndex}>
                    <ol {...props} start={startIndex}>
                        { domToReact(domNode.children[0].children) }
                    </ol>
                </ol>
            )
        }
        if (depth === 2) {
            list = (
                <ol {...props} start={startIndex}>
                    <ol {...props} start={startIndex}>
                        <ol {...props} start={startIndex}>
                            { domToReact(domNode.children[0].children[0].children) }
                        </ol>
                    </ol>
                </ol>
            )
        }
        if (depth === 3) {
            list = (
                <ol {...props} start={startIndex}>
                    <ol {...props} start={startIndex}>
                        <ol {...props} start={startIndex}>
                            <ol {...props} start={startIndex}>
                                { domToReact(domNode.children[0].children[0].children[0].children) }
                            </ol>
                        </ol>
                    </ol>
                </ol>
            )
        }
        if (depth === 4) {
            list = (
                <ol {...props} start={startIndex}>
                    <ol {...props} start={startIndex}>
                        <ol {...props} start={startIndex}>
                            <ol {...props} start={startIndex}>
                                <ol {...props} start={startIndex}>
                                    { domToReact(domNode.children[0].children[0].children[0].children[0].children) }
                                </ol>
                            </ol>
                        </ol>
                    </ol>
                </ol>
            )
        }
        if (depth === 5) {
            list = (
                <ol {...props} start={startIndex}>
                    <ol {...props} start={startIndex}>
                        <ol {...props} start={startIndex}>
                            <ol {...props} start={startIndex}>
                                <ol {...props} start={startIndex}>
                                    { domToReact(domNode.children[0].children[0].children[0].children[0].children) }
                                </ol>
                            </ol>
                        </ol>
                    </ol>
                </ol>
            )
        }
        return list;
    }
    else return domNode;
}