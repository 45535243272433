import gql from "graphql-tag";

const DOC_VERSION = gql`
    query DocVersion($docID: String, $vNum: Int, $next: Int, $prev: Int) {
        prevDoc: docVersion(docID: $docID, vNum: $prev) {
            version
            changeMessage
            created
            creator {
                _id
                username
            }
        }
        nextDoc: docVersion(docID: $docID, vNum: $next) {
            version
            changeMessage
            created
            creator {
                _id
                username
            }
        }
        mainDoc: docVersion(docID: $docID, vNum: $vNum) {
            _id
            docID
            teamID
            version
            title
            arweaveID
            titleChanges {
                value
                added
                removed
            }
            changes {
                _id
                contextualID
                key
                pr
                text
                type
                depth
                textDiff {
                    value
                    added
                    removed
                }
                inlineStyleRanges {
                    length
                    offset
                    style
                }
                diffStatus
            }
            text {
                _id
                contentID
                data
                depth
                entityRanges {
                    length
                    offset
                    key
                }
                inlineStyleRanges {
                    length
                    offset
                    style
                }
                key
                text
                type
            }
            creator {
                _id
                username
            }
            created
            changeMessage
            changeDescription
            prID
            proposal {
                _id
                created
                author {
                    _id
                    username
                }
                acceptedBy {
                    _id
                    username
                }
                voters {
                    stance
                    user {
                        _id
                        username
                    }
                }
                votes {
                    for
                    against
                    net
                    total
                }
            }
        }
    }
`

export default DOC_VERSION;