import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Overlay, TextArea } from '@blueprintjs/core';
import './AddComment.css';
import { useMutation } from '@apollo/client';
import CREATE_COMMENT from '../../DocProposals/ProposalModal/DocTextDiff/BlockDiff/CommentEditor/mutations/createComment';
import SignUpModal from '../../../SignUpModal/SignUpModal';
import { useSelector } from 'react-redux';
import SignInPrompter from '../../../ui/SignInPrompter/SignInPrompter';

const AddComment = ({
    isOpen,
    onClose,
    blockID,
    docTextID,
    docID,
    x,
    y,
    highlightStart,
    highlightEnd,
    setOpenThreadContext
}) => {

    const activeUser = useSelector(state => state.auth.user);

    const [showingSignUpModal, toggleSignUpModal] = useState(false);
    const showSignUpModal = () => toggleSignUpModal(true);
    const closeSignUpModal = () => toggleSignUpModal(false);

    const inputRef = useRef(null);
    const [commentVal, setCommentVal] = useState('');
    const onSetCommentVal = (e) => setCommentVal(e.target.value);

    const [createComment, { loading: sendingComment, error, data}] = useMutation(CREATE_COMMENT, {
       refetchQueries: ['BlockThreads']
    });
    const onCreateComment = () => {
        if (!activeUser._id) {
            showSignUpModal();
        }
        if (activeUser._id) {
            createComment({
                variables: {
                    input: {
                        text: commentVal,
                        blockID: blockID,
                        docTextID: docTextID,
                        docID: docID,
                        highlightStart: highlightStart,
                        highlightEnd: highlightEnd
                    }
                },
                onCompleted: () => {
                    setOpenThreadContext({
                        blockID: blockID,
                        x: x,
                        y: y
                    });
                    onClose();
                }
            })
        }
    }

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [])

    const noContent = commentVal === '' || commentVal.trim().length === 0;
    const onKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (!noContent) onCreateComment();
        }
    }

    return (
        <Fragment>
            <div className='AddComment'>
                <Overlay
                    isOpen={isOpen}
                    onClose={onClose}
                    backdropClassName='transparent-bg'
                >
                    <div 
                        className={commentVal !== '' ? 'AddCommentInterface active' : 'AddCommentInterface'}
                        style={{
                            top: y - 14,
                            left: x + 16
                        }}
                    >
                        <TextArea fill
                            autoFocus={true}
                            growVertically={true}
                            placeholder='Add comment'
                            value={commentVal}
                            onChange={onSetCommentVal}
                            onKeyDown={onKeyDown}
                        />
                        <div className='CommentControls'>
                            <SignInPrompter>
                                <Button 
                                    className='SendCommentBtn'
                                    small
                                    intent='primary'
                                    loading={sendingComment}
                                    disabled={commentVal === ''}
                                    onClick={onCreateComment}
                                    icon='send-message'
                                />
                            </SignInPrompter>
                        </div>
                    </div>
                </Overlay>
            </div>
        </Fragment>
        
    )
}

export default AddComment;