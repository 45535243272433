import React, { Fragment } from "react";
import CrowdwriteLogo from "../../../ui/svg/CrowdwriteLogo";
import { Link } from "react-router-dom";
import AccountMenu from "../AccountMenu/AccountMenu";
import { Button } from "@blueprintjs/core";
import ViewToggle from "../ViewToggle/ViewToggle";

const ReadViewMenu = ({
    currentUser,
    onShowEditor,
    teamSlug,
    docID,
    children
}) => {

    return (
        <Fragment>
            <div className='fixed top-0 inset-x-0 z-40'>
                <div 
                    className='flex justify-between bg-transparent relative px-2 py-2' 
                >
                    <div className='flex space-between align-center gap-2'>
                        <div className='Logo mr-2 opacity-10'>
                            <Link to={currentUser && currentUser._id ? '/docs' : '/'}>
                                <CrowdwriteLogo width={20} />
                            </Link>
                        </div>
                    </div>
                    <div className='flex space-between align-center gap-2 RightMenu'>
                        <div className='vis-hidden flex'>
                            <AccountMenu currentUser={currentUser}/>
                            <div 
                                className='NewTextBtn'
                            >
                                <Button
                                    small
                                    intent='primary'
                                    // className='secondary-btn'
                                    onClick={onShowEditor}
                                >
                                    New
                                </Button>
                            </div>
                        </div>
                        <ViewToggle 
                            teamSlug={teamSlug}
                            docID={docID}
                        />
                    </div>
                </div>
            </div>
            {children}
        </Fragment>
    )
}

export default ReadViewMenu;