import { useQuery } from '@apollo/client';
import React from 'react';
import { getDateAndMonth, getReadableShortDate } from '../../ui/FormattedDate/dateUtils';
import HistoryCard from './HistoryCard/HistoryCard';
import DOC_VERSIONS from './queries/docVersions';
import './DocHistory.css';
import DocHistorySkeleton from './DocHistorySkeleton/DocHistorySkeleton';

const DocHistory = ({
    docID
}) => {

    const { loading, error, data } = useQuery(DOC_VERSIONS, {
        variables: {
            docID: docID
        },
        fetchPolicy: 'cache-and-network'
    });

    if (loading) return <DocHistorySkeleton />;
    if (error) return 'error';

    let uniqueDates = [];
    const historyCards = data.docVersions.map((version, i) => {
        
        const date = version.created.substring(0, 10);
        let cardContainer = (
            <div className='TimelineCardContainer'>
                <HistoryCard key={i} {...version} />
            </div>
        )

        if (!uniqueDates.includes(date)) {

            const dateString = getDateAndMonth(version.created);

            cardContainer = (
                <div className='TimelineCardContainer new-date'>
                    <div className='TimelineMarker'></div>
                    <p 
                        className='font600 no-margin font-size-12 timeline-label'
                    >
                        {dateString}
                    </p>
                    <HistoryCard key={i} {...version} />
                </div>
            )
            uniqueDates.push(date);
        }

        return cardContainer;
    })

    return (
        <div className='DocHistory'>
            <div className='TopLineCover'></div>
            {historyCards}
        </div>
    )

}

export default DocHistory;