import React, { useState } from 'react';
import { Tooltip2 } from '@blueprintjs/popover2';
import { useDispatch } from 'react-redux';
import * as actions from '../../../../../../store/actions/index';
import PositionedIcon from '../../../../../ui/PositionedIcon/PositionedIcon';
import './LinkToolbar.css';

const LinkToolbar = ({
    url,
    blockKey,
    entityKey,
}) => {

    const [editTooltipOpen, toggleEditTooltip] = useState(false);
    const onShowEditTooltip = () => toggleEditTooltip(true);
    const onHideEditTooltip = () => toggleEditTooltip(false);

    const dispatch = useDispatch();
    const onRemoveLink = () => {
        dispatch(actions.setDraftLinkAction({
            entityKey: entityKey,
            blockKey: blockKey,
            action: 'REMOVE'
        }));
    }
    const onEditLink = () => {
        dispatch(actions.setDraftLinkAction({
            entityKey: entityKey,
            blockKey: blockKey,
            action: 'EDIT'
        }));
    }
    
    return (
        <div className='InlineToolbar'>
            <a href={url} target="_blank" className='ContainedLink'>
                {url}<PositionedIcon icon='share' iconSize={9} top={-3} marginLeft={4} />
            </a>
            <Tooltip2
                content={'Edit link'}
                isOpen={editTooltipOpen}
                placement='top'
            >
                <button 
                    onClick={onEditLink}
                    onMouseEnter={onShowEditTooltip}
                    onMouseLeave={onHideEditTooltip}
                >
                    <PositionedIcon icon='edit' iconSize={10} top={-2}  />
                </button>
            </Tooltip2>
            <Tooltip2
                content={'Remove link'}
                placement='top'                
            >
                <button onClick={onRemoveLink}>
                    <PositionedIcon icon='cross' iconSize={10} top={-2} />
                </button>
            </Tooltip2>
        </div>
    )
}

export default LinkToolbar;