import { Icon } from '@blueprintjs/core';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import './DeadlineCallout.css';

const getRemainingTime = (deadlineDate) => {

    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;

    const now = new Date();
    const distance = deadlineDate - now;
    if (distance < 0) {
        return {
            expired: true
        };
    }
    const days = Math.floor(distance / day);
    const hours = Math.floor((distance % day) / hour);
    const minutes = Math.floor((distance % hour) / minute);
    const seconds = Math.floor((distance % minute) / second);
    return { 
        days,
        hours,
        minutes,
        seconds
    }

}

const DeadlineCallout = ({
    deadline
}) => {

    const deadlineDate = new Date(deadline);

    const [time, setTime] = useState(Date.now());
    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 1000 * 60);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const createdDate = DateTime.fromISO(deadline);
    const formattedDate = createdDate.toLocaleString(DateTime.DATE_FULL);

    const { expired, days, hours, minutes, seconds } = getRemainingTime(deadlineDate);
    if (expired) {
        return (
            <div className='ProtocolCallout'>
                <Icon icon='stopwatch' />
                <div className='CalloutInfo'>
                    No longer open for feedback (ended {formattedDate})
                </div>
            </div>
        )
    }

    return (
        <div className='ProtocolCallout soft-text'>
            <Icon icon='stopwatch' />
            <div className='CalloutInfo'>
                Open for feedback until <span className='bold'>{formattedDate}</span>
            </div>
        </div>
    )
}

export default DeadlineCallout