import React from 'react';
import { Popover2 } from '@blueprintjs/popover2';
import LinkToolbar from './LinkToolbar/LinkToolbar';

const DraftLink = (props) => {

    // if the entity key is vestigial, return a plain span
    let entityData = props.contentState.getEntity(props.entityKey).getData();
    if (!entityData) {
        return props.children;
    }

    const { url } = entityData;
    
    return (
        <Popover2
            minimal
            interactionKind='click'
            placement='top'
            portalClassName='InlineToolbarPortal'
            popoverClassName='LinkToolbarPopover'
            content={(
                <LinkToolbar 
                    url={url} 
                    blockKey={props.blockKey}
                    entityKey={props.entityKey} 
                />
            )}
            children={(
                <a 
                    href={url} 
                    className='draft-link'
                    id={props.entityKey}
                >
                    {props.children}
                </a>
            )}
        >
        </Popover2>
    );
};

export default DraftLink;