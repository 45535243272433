import React from 'react';
import { convertFromRaw } from 'draft-js'
import { convertToHTML } from 'draft-convert';
import parse, { domToReact } from 'html-react-parser';
import { StructuredOrderedList } from '../../../../../DocText/TextBlock/textBlockUtils/listItemUtils';
import { getEntityMapFromArray } from '../../../../../DocText/DocEditorModal/entityUtils';

/*
    - create a character counter starting at 0
    - within each part (of the diff)...
        - filter for styleRanges that apply within the part
            - include cases that...
                - start and end within the part, 
                - start in the part and end after the part,
                - start before the part and end in the part
        - apply styles using the draft convert utilities  
        - increment the counter
*/

const getStyleRangesForPart = (ranges, part, counter) => {
    
    const filteredRanges = ranges.filter(range => {
        if (range.offset >= counter + part.length) return false;
        if (range.offset + range.length < counter) return false;
        else return true;
    });

    // map ranges to the part
    const mappedRanges = filteredRanges.map(range => {
        return {
            ...range,
            offset: range.offset - counter
        }
    });

    return mappedRanges;
}

const StringDiff = ({
    parts,
    type,
    listItemIndex,
    depth,
    styleRanges = [],
    entityRanges = [],
    entities
}) => {

    let counter = 0;

    const stringDiffParts = parts.map((part, i) => {

        const partStyleRanges = getStyleRangesForPart(styleRanges, part, counter);
        const rawContent = {
            blocks: [{
                key: i,
                depth: 0,
                type: `unstyled`,
                text: part.value,
                inlineStyleRanges: partStyleRanges,
                entityRanges: entityRanges
            }], 
            entityMap: getEntityMapFromArray(entities) // TODO: add link entities
        }
    
        const contentState = convertFromRaw(rawContent);
        const htmlContent = convertToHTML({
            entityToHTML: (entity, originalText) => {
                let htmlText;
                if (entity.type === 'LINK') {
                    htmlText = <a href={entity.data.url} target="_blank">{originalText}</a>;
                } 
                return htmlText;
            }
        })(contentState);
        const richText = parse(htmlContent, {
            replace: (domNode) => {
                return <span>{domToReact(domNode.children)}</span>
            }
        });
        
        let classedPart;
        if (part.added) classedPart = <span className='added' key={i}>{richText}</span>;
        if (part.removed) classedPart = <span className='removed' key={i}>{richText}</span>;
        else if (!part.added && !part.removed) classedPart = <span className='unchanged' key={i}>{richText}</span>;

        counter = part.length;
        return classedPart;

    })

    return  (
        <span className='StringDiff'>
            {stringDiffParts}
        </span>
    )
}

export default StringDiff;