const isNextOrPrevUnchanged = (block, i, blocks) => {
       
    // handle the first element in the case that there is only one element
    if (i === 0 && blocks.length === 1) {
        if (block.diffStatus === 'UNCHANGED') return { ...block, relevant: false };
        return { ...block, relevant: true };
    }

    // handle the first element
    if (i === 0) {
        if (block.diffStatus === 'UNCHANGED' 
            && blocks.length > 1 
            && blocks[i+1].diffStatus === 'UNCHANGED') {
                return { ...block, relevant: false };
        } 
        return { ...block, relevant: true };
    }
    
    // handle the last element
    if (i === blocks.length - 1) {
        if (block.diffStatus === 'UNCHANGED' && blocks[i-1].diffStatus === 'UNCHANGED') {
            return { ...block, relevant: false };
        } 
        return { ...block, relevant: true };
    }

    // handle non-first, non-last elements
    if (block.diffStatus === 'UNCHANGED' && blocks[i+1].diffStatus === 'UNCHANGED' && blocks[i-1].diffStatus === 'UNCHANGED') {
        return { ...block, relevant: false };
    }
    return { ...block, relevant: true };

}

export const createRelevanceSections = (blocks) => {
        
    const blocksWithRelevance = blocks.map((block, i) => isNextOrPrevUnchanged(block, i, blocks));

    let sections = [];
    let currentSection = [blocksWithRelevance[0]]
    let n = 1;

    while (n < blocksWithRelevance.length) {
        if (blocksWithRelevance[n].relevant === currentSection[0].relevant) {
            currentSection.push(blocksWithRelevance[n]);
            n++;
        } else {
            sections.push(currentSection);
            currentSection = [blocksWithRelevance[n]];
            n++;
        }
    }
    // include the last section (if there are any blocks with relevance) 
    if (currentSection[0] !== undefined) sections.push(currentSection);
    // tag each section as relevant or not
    const taggedSections = sections.map(section => {
        return {
            relevant: section[0].relevant,
            blocks: section
        }
    });

    const sectionsWithContext = taggedSections.map((section, i) => {
        return {
            blocks: section.blocks,
            relevant: section.relevant,
            prevIsRelevant: i > 0 && taggedSections[i-1].relevant === true,
            nextIsRelevant: i < taggedSections.length - 1 && taggedSections[i+1].relevant === true
        }
    })

    return sectionsWithContext;
}