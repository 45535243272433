import React from 'react';
import './LinkButton.css';

const LinkButton = ({
    text,
    onClick,
    small
}) => {
    return (
        <button 
            className={small ? 'LinkButton small' : 'LinkButton'}
            onClick={onClick}
        >
            {text}
        </button>
    )
}

export default LinkButton;