import { useQuery } from '@apollo/client';
import { Overlay } from '@blueprintjs/core';
import React from 'react';
import BLOCK_COMMENTS from './queries/blockComments';
// TODO: reorganize where Comment sits in the file structure
import ThreadContent from './ThreadContent/ThreadContent';
import ThreadContentLoading from './ThreadContent/ThreadContentLoading';
import './ThreadContainer.css';

const ThreadContainer = ({
    threadID,
    docTextID,
    docID,
    textContainerRef,
    xPos,
    yPos,
    setOpenThreadContext
}) => {

    const { loading, error, data } = useQuery(BLOCK_COMMENTS, {
        variables: {
            threadID: threadID
        },
        fetchPolicy: 'network-only'
    });

    const textContainerRect = textContainerRef.current.getBoundingClientRect();
    const leftPos = textContainerRect.left + 672;

    const onClose = () => {
        setOpenThreadContext({
            threadID: null,
            x: null,
            y: null
        })
    }

    if (error) return 'error';
    if (loading) return (
        <Overlay
            backdropClassName='transparent-bg'
            isOpen={threadID}
            onClose={onClose}
        >
            <ThreadContentLoading
                threadID={threadID}
                docTextID={docTextID}
                docID={docID}
                xPos={xPos}
                leftPos={leftPos}
                yPos={yPos}
            />
        </Overlay>
    );

    return (
        <Overlay
            backdropClassName='transparent-bg'
            isOpen={threadID}
            onClose={onClose}
        >
            <ThreadContent 
                threadID={threadID}
                docTextID={docTextID}
                textContainerRef={textContainerRef}
                docID={docID}
                comments={data.thread.comments}
                xPos={xPos}
                leftPos={leftPos}
                yPos={yPos}
            />
        </Overlay>
    )
}

export default ThreadContainer;