import React from 'react';
import { Dialog } from '@blueprintjs/core';
import './Modal.css';

const Modal = (props) => {

    const classes = props.className ? props.className.concat(' st-modal') : 'st-modal'

    return (
        <Dialog {...props} 
            className={classes}
            transitionName='fade'
            // transitionName='fadeSlide'
        />
    )
}

export default Modal;