import React from 'react';
import { Link } from 'react-router-dom';
import PositionedIcon from '../../../ui/PositionedIcon/PositionedIcon';
import { Tag } from '@blueprintjs/core';
import StatusTag from '../../../ui/StatusTag/StatusTag';
import DocTag from '../DocTag/DocTag';

const UpdateDocEvent = ({
    doc,
    docVersion,
    context,
    user
}) => {

    let summary;
    
    if (context === 'GLOBAL_FEED'){
        summary = (
            <span>
                <Link to={`/user/${user.username}`} className='font500'>{user.username}</Link> made some edits
            </span>
        )
    } 

    if (context === 'PROFILE') {
        summary = (
            <span>Made some edits</span>
        )
    }

    return (
        <div className='EventBlock'>
            <div className='EventIcon'>
                <PositionedIcon icon='edit' top={3} iconSize={15} />
            </div>
            <div className='EventInfo'>
                <p className='margin-bottom-12 font-size-12'>
                    {summary}
                    <span className='margin-left-8'>
                        <DocTag
                            link={`/${doc.team}/${doc.docID}`}
                            title={doc.title}
                        />
                    </span>
                </p>
                {(docVersion.changeMessage || docVersion.changeDescription) && (
                    <Link to={`/${doc.team}/${doc.docID}/history/${docVersion.version}`}>
                        <div className='event-quote asymmetric inline-block'>
                            <p className='margin-bottom-0'>
                                {docVersion.changeMessage}
                            </p>
                            <p className='margin-bottom-0 small-text'>
                                {docVersion.changeDescription}
                            </p>
                        </div>
                    </Link>
                )}
            </div>
        </div>
    )
}

export default UpdateDocEvent;
