import React from 'react';
import { Link } from 'react-router-dom';
import StatusTag from '../../../ui/StatusTag/StatusTag';
import PositionedIcon from '../../../ui/PositionedIcon/PositionedIcon';
import './ProposalTag.css';

const ProposalTag = ({
    title,
    link,
    status
}) => {

    const croppedTitle = title && title.length > 50 ? `${title.substring(0, 50)}...` : title;

    return (
        <Link 
            to={link}
            className='ProposalTag activity-tag-link'
        >
            <PositionedIcon icon='lightbulb' iconSize={8} top={-2} />
            <span className='margin-left-8'>{title ? croppedTitle : 'Untitled suggestion'}</span>
            <StatusTag status={status} />
        </Link>
    )
}

export default ProposalTag;