import React from 'react';
import './ModalActionBar.css';

const ModalActionBar = ({
    children
}) => {

    return (
        <div className='ModalActionBar'>
            {children}
        </div>
    )
}

export default ModalActionBar;