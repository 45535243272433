import { useMutation } from '@apollo/client';
import jwt_decode from 'jwt-decode';
import { Button, Card, FormGroup, InputGroup } from '@blueprintjs/core';
import isEmail from "validator/lib/isEmail";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import * as actions from '../../store/actions/index';
import CrowdwriteLogo from '../ui/svg/CrowdwriteLogo';
import { Link } from 'react-router-dom';
import SignInHeader from '../SignIn/SignInHeader/SignInHeader';
import RESET_PASSWORD from './mutations/resetPassword';
import { SteleToaster } from '../ui/Toaster/Toaster';


const ResetPassword = ({

}) => {

    const { token } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        
        const decodedResetToken = token ? jwt_decode(token) : null;

        if (decodedResetToken) {
            
            const now = new Date();
            const expires = new Date(decodedResetToken.exp * 1000);
            const hasExpired = expires < now;

            if (hasExpired) {
                navigate('/forgot');
            }

        }
        
    }, [token]);

    const [passwordInput, setPasswordInput] = useState('');
    const [retypedPasswordInput, setRetypedPasswordInput] = useState('');

    const onRequestPasswordReset = () => {
        // requestPasswordReset();
    }

    const onChangePasswordInput = (e) => {
        setPasswordInput(e.target.value);
    }

    const onChangeRetypedPasswordInput = (e) => {
        setRetypedPasswordInput(e.target.value);
    }

    const [resetPassword, { loading, error, data }] = useMutation(RESET_PASSWORD, {
        variables: {
            password: passwordInput,
            token: token
        },
        onCompleted: () => {
            SteleToaster.show({
                className: 'dark',
                message: 'Your password has been reset'
            });
            navigate('/sign-in')
        }
    })

    const validPassword = passwordInput === retypedPasswordInput && passwordInput.length > 5;

    const onKeyDown = (e) => {
        if (validPassword && e.key === 'Enter') {
            resetPassword();
        }
    }


    return (
        <div className='h-screen flex flex-col justify-center'>
            <div className='w-96 mx-auto'>
                <SignInHeader />
                <p className='font-semibold tracking-tight -mt-2 mb-4'>
                    Reset your password
                </p>
                <FormGroup
                >
                    <InputGroup 
                        id='new-password'
                        large
                        aria-label='Enter your new password'
                        placeholder='New password'
                        value={passwordInput}
                        onChange={onChangePasswordInput}
                        onKeyDown={onKeyDown}
                        type='password' 
                    />
                </FormGroup>
                <FormGroup
                >
                    <InputGroup 
                        id='retype-password'
                        large
                        aria-label='Retype your new password'
                        placeholder='Confirm password'
                        value={retypedPasswordInput}
                        onChange={onChangeRetypedPasswordInput}
                        onKeyDown={onKeyDown}
                        type='password' 
                    />
                </FormGroup>
                <div className='mt-5'>
                    <Button 
                        intent='primary'
                        text={'Reset password'}
                        disabled={!validPassword}
                        onClick={resetPassword}
                        loading={loading}
                    />
                </div>
                <div className='mt-8 text-xs text-center'>
                    Changed your mind? <Link to='/sign-in' className='font-semibold hover:no-underline'>Sign in</Link>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;