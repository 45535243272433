import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { SteleToaster } from '../../../ui/Toaster/Toaster';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createListDepthPlugin from 'draft-js-list-depth-plugin'
import { Button, EditableText, InputGroup, Radio, RadioGroup, TextArea, } from '@blueprintjs/core';
import Modal from '../../../ui/Modal/Modal';
import PositionedIcon from '../../../ui/PositionedIcon/PositionedIcon';
import { useDispatch, useSelector } from 'react-redux';
import UPDATE_DOC from '../mutations/updateDoc';
import CREATE_PROPOSAL from '../mutations/createProposal';
import NewProposalToast from '../../../ui/Toasts/NewProposalToast/NewProposalToast';
import updateDocProposalCount from '../../../../cache/updateUtils/updateDocProposalCount';
import { useNavigate } from 'react-router';
import { Tooltip2 } from '@blueprintjs/popover2';
import EditorToolbar from './EditorToolbar/EditorToolbar';
import styleMap from './draftStyleMap';
import { handleKeyCommand, keyBindingFn, textControls } from './standardDraftProps';
import LinkEditorModal from './LinkEditorModal/LinkEditorModal';
import { applyLinkToLinkString, getEntityArrayFromMap, getEntityMapFromArray, isSelectionLink, removeLink, setSelectionToEditLink } from './entityUtils';
import * as actions from '../../../../store/actions/index';
import decorator from './decorator/decorator';
import CREATE_DOC from '../../../NewDoc/NewDocModal/mutations/createDoc';
import SignInPrompter from '../../../ui/SignInPrompter/SignInPrompter';
import './DocEditorModal.css';
import './draftMods.css';

const DocEditorModal = ({
    isOpen,
    docID,
    teamID,
    title,
    teamSlug,
    text,
    entityMap,
    userCanSave,
    onClose,
}) => {

    const currentUser = useSelector(state => state.auth.user);

    const draftLinkAction = useSelector(state => state.ui.draftLinkAction);
    const dispatch = useDispatch();
    const resetDraftLinkAction = () => dispatch(actions.setDraftLinkAction(null));

    const editorRef = useRef(null);

    const [showingSignUpModal, setSignUpModal] = useState(false);
    const showSignUpModal = () => setSignUpModal(true);
    const closeSignUpModal = () => setSignUpModal(false);

    const [showingLinkEditor, toggleLinkEditor] = useState(false);
    const showLinkEditor = () => toggleLinkEditor(true);

    const navigate = useNavigate();
    const [editAction, setEditAction] = useState(docID === 'NEW' ? `SAVE` : `PROPOSE`); // SAVE | PROPOSE
    const onChangeEditAction = (e) => setEditAction(e.target.value);
    
    const [commitMessage, setCommitMessage] = useState(``);
    const onChangeCommitMessage = (e) => setCommitMessage(e.target.value);

    const [commitDescription, setCommitDescription] = useState(``);
    const onChangeCommitDescription = (e) => setCommitDescription(e.target.value);

    const [titleState, setTitleState] = useState(() => title);
    const onSetTitle = (val) => setTitleState(val);

    const listDepthPlugin = createListDepthPlugin({ maxDepth: 6 });
    const plugins = [listDepthPlugin];

    const [editorState, setEditorState] = useState(
        () => {
            const entityMapObj = getEntityMapFromArray(entityMap);
            
            let initialEditorState;
            if (docID === 'NEW') {
                initialEditorState = EditorState.createEmpty(decorator);
            } else if (docID !== 'NEW') {
                initialEditorState = EditorState.createWithContent(convertFromRaw({
                    blocks: text, 
                    entityMap: entityMapObj
                }), decorator);
            } 

            return initialEditorState;
        }
    );
    const onSetEditorState = (incomingEditorState) => {
        // const newState = EditorState.createWithContent(incomingEditorState.getCurrentContent(), decorator);
        setEditorState(incomingEditorState);
    }

    const hideLinkEditor = () => {
        toggleLinkEditor(false);
    }

    const [createDoc, { loading: createDocLoading }] = useMutation(CREATE_DOC);
    const [updateDoc, { loading: isSaving }] = useMutation(UPDATE_DOC);

    const onCreateDoc = () => {
        
        const rawInitialContent = convertToRaw(editorState.getCurrentContent());

        const processControl = {
            processType: 'OPEN'
        }

        const processInput = {
            controls: [processControl],
            operator: 'AND',
            creatorCanEdit: true,
            creatorCanChangeSettings: true,
        }

        const entityArray = getEntityArrayFromMap(rawInitialContent.entityMap);

        createDoc({ 
            variables: {
                input: {
                    title: titleState,
                    text: rawInitialContent.blocks,
                    // description:                     TODO: add support for document description
                    changeMessage: commitMessage,
                    changeDescription: commitDescription,
                    process: processInput,
                    teamSlug: currentUser.username,
                    entityMap: entityArray,
                    teamID: currentUser._id,
                    deadline: null,
                    viewAccess: `'ANYONE_WITH_LINK'`,
                    participateAccess: `'ANYONE_WITH_LINK`
                }
            },
            onCompleted: (data) => {
                setEditorState(EditorState.createEmpty(decorator));
                setTitleState('');
                onClose();
                navigate(`/${data.createDoc.team}/${data.createDoc.docID}`);
            }
        })
    }

    const onSave = () => {
        const rawTextContent = convertToRaw(editorState.getCurrentContent());
        const entityArray = getEntityArrayFromMap(rawTextContent.entityMap);
        const input = {
            docID: docID,
            changeMessage: commitMessage,
            changeDescription: commitDescription,
            docTitle: titleState,
            text: rawTextContent.blocks,
            entityMap: entityArray // TODO: rename this input property 'entityArray' on the server
        };
        if (docID !== 'NEW') {
            updateDoc({ 
                variables: { input: input },
                onCompleted: () => {
                    onClose();
                    SteleToaster.show({
                        intent: 'success',
                        message: 'Changes saved'
                    })
                }
            });
        }
        if (docID === 'NEW') {
            onCreateDoc();
            // onClose();
        }
    }

    const [createProposal, { loading: isProposing }] = useMutation(CREATE_PROPOSAL);
    const onPropose = () => {
        const rawTextContent = convertToRaw(editorState.getCurrentContent());
        const entityArray = getEntityArrayFromMap(rawTextContent.entityMap);
        const input = {
            docID: docID,
            docTitle: titleState,
            teamID: teamID,
            title: commitMessage,
            description: commitDescription,
            text: rawTextContent.blocks,
            entityMap: entityArray // TODO: rename this input property 'entityArray' on the server
        };
        createProposal({ 
            variables: { input: input },
            onCompleted: (data) => {
                navigate(`/${teamSlug}/${docID}/suggestions/${data.createProposal._id}/changes`);
                const handleToastLink = () => navigate(`/${teamSlug}/${docID}/suggestions/${data.createProposal._id}/changes`);
                SteleToaster.show({
                    timeout: 5000,
                    message: (
                        <NewProposalToast
                            prID={data.createProposal._id}
                            prNumber={data.createProposal.number}
                            handleLink={handleToastLink}
                        />
                    )
                }, data.createProposal._id);
                onClose();
            },
            update: (cache) => updateDocProposalCount(cache, docID)
        });
    }

    const onTryToSave = () => {
        if (!currentUser._id) {
            showSignUpModal();
        }
        else if (currentUser._id) {
            onSave();
        }
    }

    const onTryToPropose = () => {
        if (!currentUser._id) {
            showSignUpModal();
        }
        else if (currentUser._id) {
            onPropose();
        }
    }

    const [modalHasRendered, setModalRendered] = useState(false);

    const focusedBlock = useSelector(state => state.ui.focusedTextBlock);

    useEffect(() => {
        setModalRendered(true);
        // for some reason, the decorator doesn't get applied when initializing the editor.
        // re-rendering it after a tick addresses the issue, though it's not ideal to not get to the root cause.
        // worth sorting out in the future.
        const editorStateWithDecorator = EditorState.set(editorState, {
            decorator: decorator
        });
        setTimeout(() => setEditorState(editorStateWithDecorator), 1);
    }, [])

    // handle changes coming from inline link toolbars
    useEffect(() => {
        // if redux sends a REMOVE LINK update, remove link
        if (draftLinkAction && draftLinkAction.action === 'REMOVE') {
            removeLink(draftLinkAction.entityKey, editorState, setEditorState, decorator);
            resetDraftLinkAction();
        }

        // update selection to entity range, then show link editor
        if (draftLinkAction && draftLinkAction.action === 'EDIT') {
            setSelectionToEditLink(draftLinkAction.entityKey, draftLinkAction.blockKey, editorState, setEditorState, decorator);
            toggleLinkEditor(true);
            resetDraftLinkAction();
        }

    }, [draftLinkAction]);

    useEffect(() => {
        if (!focusedBlock) return null;

        window.setTimeout(() => {
            const blockElement = document.querySelector(`[data-offset-key="${focusedBlock}-0-0"]`);
            if (blockElement) {
                blockElement.scrollIntoView({block: 'center'});
                blockElement.classList.add('st-highlighted-block');
            }
        }, 100);
        
    }, [modalHasRendered, focusedBlock, isOpen]);

    const wrappedTextControls = textControls(userCanSave, editorState, setEditorState);
    const wrappedKeyBindingFn = (e) => keyBindingFn(e, userCanSave, editorState, setEditorState);
    const wrappedHandleKeyCommand = (command, editorState) => {
        if (command === 'add-link') {
            
            // if the selected text is a URL, skip opening the modal and turn the URL into a link
            const isLink = isSelectionLink(editorState);
            if (isLink) {
                applyLinkToLinkString(editorState, setEditorState);
                return 'handled';
            }

            // otherwise show the link editor
            showLinkEditor();
            return 'handled';
        }
        handleKeyCommand(command, userCanSave, editorState, onSetEditorState);
    }

    return (
        <Modal
            className='DocEditorModal'
            isOpen={isOpen}
            onClose={onClose}
            transitionDuration={300}
            backdropClassName={`DocEditorModalBackdrop`}
            canEscapeKeyClose={false}
        >
            <div 
                className='EditorColumn'
            >
                <div className='EditorColumnHeader'>
                    <h4>
                        <PositionedIcon 
                            icon='document' 
                            iconSize={11}
                            top={-3}
                            marginRight={4}
                            opacity={1}
                        />
                        <span className='margin-right-8'>{docID === 'NEW' ? 'New' : 'Edit'}</span>
                        <EditableText
                            className='margin-left-8 font400'
                            value={titleState}
                            onChange={onSetTitle}
                            placeholder={title ? title : 'Untitled document'} 
                            fill
                        />
                    </h4>
                </div>
                <EditorToolbar
                    textControls={wrappedTextControls}
                    editorState={editorState}
                    setEditorState={setEditorState}
                    userIsAdmin={userCanSave}
                    showLinkEditor={showLinkEditor}
                />
                <div className='DraftEditorContainer'>   
                    <Editor 
                        ref={editorRef} 
                        editorState={editorState}
                        handleKeyCommand={wrappedHandleKeyCommand}
                        onChange={onSetEditorState}
                        placeholder='Start writing' 
                        keyBindingFn={wrappedKeyBindingFn}
                        customStyleMap={styleMap}
                        plugins={plugins}
                    />
                </div>
            </div>
            <div className='ChangeActionColumn'>
                {docID === 'NEW' && <h3>Save document</h3>}
                {docID !== 'NEW' && <h3>{editAction === 'SAVE' ? 'Save changes' : 'Suggest changes'}</h3>}
                {docID !== 'NEW' && (
                    <RadioGroup
                        onChange={onChangeEditAction}
                        selectedValue={editAction}
                    >
                        <Radio label={`Save changes`} value='SAVE' disabled={!userCanSave} />
                        <Radio label={`Make a suggestion`} value='PROPOSE' />
                    </RadioGroup>
                )}
                <InputGroup 
                    placeholder={docID === 'NEW' ? 'Hello world' : 'Describe your changes' }
                    value={commitMessage}
                    onChange={onChangeCommitMessage}
                    style={{
                        marginTop: 4
                    }}
                />
                <TextArea fill
                    growVertically={true}
                    placeholder='Add a longer message (optional)'
                    value={commitDescription}
                    onChange={onChangeCommitDescription}
                    style={{
                        marginTop: 8,
                        marginBottom: 12,
                        minHeight: 100,
                        maxHeight: 360,
                        resize: `vertical`
                    }}
                />
                {editAction === `PROPOSE` && (
                    <SignInPrompter>
                        <Button
                            className='st-padded-btn'
                            intent='primary'
                            text={currentUser._id ? 'Suggest' : 'Sign up to suggest'}
                            onClick={onPropose}
                            loading={isProposing}
                        />
                    </SignInPrompter>
                )}
                {userCanSave && editAction === `SAVE` && (
                    <Tooltip2
                        content={
                            <Fragment>
                                <p>Save your changes</p>
                                <p className='tooltip-caption'>
                                    This will update the document
                                </p>
                            </Fragment>
                        }
                        placement='top'
                    >
                        <SignInPrompter>
                            <Button
                                className='st-padded-btn'
                                intent='primary'
                                text={currentUser._id ? 'Save' : 'Sign up to save'}
                                loading={isSaving || createDocLoading}
                                onClick={onSave}
                            />
                        </SignInPrompter>
                    </Tooltip2>
                )}
                <Button
                    minimal
                    text='Cancel'
                    onClick={onClose}
                    style={{
                        marginLeft: 8
                    }}
                />
            </div>
            {showingLinkEditor && (
                <LinkEditorModal
                    isOpen={showingLinkEditor}
                    onClose={hideLinkEditor}
                    editorState={editorState}
                    setEditorState={setEditorState}
                    decorator={decorator}
                />
            )}
        </Modal>
    )
}

export default DocEditorModal;