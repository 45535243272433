import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, FormGroup, Icon, InputGroup, Spinner } from '@blueprintjs/core';
import React, { useCallback, useState } from 'react';
import SIGN_UP_FROM_INVITE from './mutations/completeSignUp';
import { getToken, setLocalToken } from '../../../utils/authClient';
import { useDispatch } from 'react-redux';
import * as actions from '../../../store/actions/index';
import _ from 'lodash';
import UNIQUE_USERNAME from '../queries/uniqueUsername';
import PositionedIcon from '../../ui/PositionedIcon/PositionedIcon';
import { SteleToaster } from '../../ui/Toaster/Toaster';

// TODO: Run a query upon hitting this modal 
// to check whether the user ID in redux is associated with an account that already has a username and password
// if it does, display the sign in screen instead

const InvitedUserScreen = ({
    user,
    onClose,
    checkUsernameForError,
}) => {

    const dispatch = useDispatch();
    const setUser = (user) => dispatch(actions.setUser(user));

    const [editableUsername, setUsername] = useState('');
    const [usernameError, setUsernameError] = useState('');

    const [checkUsername, { called, loading: checkingUsername, data: usernameStatus }] = useLazyQuery(UNIQUE_USERNAME,
        {
            fetchPolicy: 'network-only'
        }
    );

    const debouncedCheckUsername = useCallback(_.debounce(checkUsername, 500), []);

    const onChangeUsername = (e) => {
        const regex = /^[a-zA-Z0-9_]{1,32}$/;
        if (!regex.test(e.target.value) && e.target.value !== '') {
            return;
        }

        setUsername(e.target.value.trim());
        setUsernameError('');
        debouncedCheckUsername({
            variables: {
                username: e.target.value
            },
            onCompleted: (data) => {
                if (data && data.uniqueUsername === false) {
                    setUsernameError('TAKEN')
                }
            },
        });
    }

    const [editablePassword, setEditablePassword] = useState('');
    const onChangePassword = (e) => setEditablePassword(e.target.value);

    const [completeSignUp, { loading, error, data }] = useMutation(SIGN_UP_FROM_INVITE);

    const isValid = editableUsername !== '' && editablePassword !== '' && usernameError === ''; 

    const onSignUp = () => {
        completeSignUp({
            variables: {
                input: {
                    invite: getToken(),
                    id: user._id,
                    username: editableUsername,
                    password: editablePassword
                }
            },
            onCompleted: (data) => {
                if (data && data.signUpFromInvite && data.signUpFromInvite.user) {
                    setLocalToken(data.signUpFromInvite.jwt);
                    setUser(data.signUpFromInvite.user);
                    SteleToaster.show({
                        className: 'dark',
                        message: 'Welcome to Crowdwrite',
                        timeout: 5000
                    })
                    onClose();
                }
            }
        })
    }

    let inputIcon;
    if (checkingUsername) { inputIcon = <Spinner size={18} /> }

    return (
        <div className='modal-deep-padded-section'>
            <p className='font-size-20 font700 margin-bottom-8'>Welcome</p>
            <p className='font-size-16 margin-bottom-24'>Create a username and password to collaborate</p>
            <FormGroup
                className='large-input'
                label='Username'
                labelFor='username'
                intent={usernameError !== '' ? 'danger' : null}
            >
                <InputGroup 
                    value={editableUsername}
                    onChange={onChangeUsername}
                    intent={usernameError !== '' ? 'danger' : null}
                    rightElement={inputIcon}
                />
                {usernameError === 'TAKEN' && <p className='bp3-text-small margin-top-4'>That username is taken</p>}
            </FormGroup>
            <FormGroup
                className='large-input'
                label='Password'
                labelFor='password'
            >
                <InputGroup 
                    type='password'
                    value={editablePassword}
                    onChange={onChangePassword}
                />
            </FormGroup>
            <Button
                large
                intent='primary'
                text='Create account'
                className='margin-top-16'
                onClick={onSignUp}
                loading={loading}
                disabled={!isValid}
            />
        </div>
    )
}

export default InvitedUserScreen;