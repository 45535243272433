import React from 'react';
import { Link } from 'react-router-dom';
import PositionedIcon from '../../../ui/PositionedIcon/PositionedIcon';

const CreateDocEvent = ({ 
    doc,
    user,
    context
}) => {

    if (!doc) { 
        return null; // TODO: figure out why there are some CreateDocEvents with no document
    }

    let summary;
    
    if (context === 'GLOBAL_FEED'){
        summary = (
            <span>
                <Link to={`/user/${user.username}`} className='font500'>{user.username}</Link> started a document
            </span>
        )
    } 

    if (context === 'PROFILE') {
        summary = (
            <span>Started a document</span>
        )
    }

    return (
        <div className='EventBlock'>
            <div className='EventIcon'>
                <PositionedIcon icon='document' top={3} iconSize={14} />
            </div>
            <div className='EventInfo'>
                <p className='margin-bottom-8 font-size-12'>
                    {summary}
                </p>
                <Link to={`/${doc.team}/${doc.docID}`}>
                    <div className='event-card inline-block asymmetric'>
                        <p className='margin-bottom-0'>
                            <PositionedIcon icon='document' />
                            <span className='font600 margin-left-8'>{doc.title ? doc.title : 'Untitled document'}</span>
                        </p>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default CreateDocEvent;
