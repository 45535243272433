import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useParams } from 'react-router';
import * as actions from '../../store/actions/index'
import DocProposals from '../Doc/DocProposals/DocProposals';
import DocText from '../Doc/DocText/DocText';
import SnippetTopMenu from '../SnippetEditor/SnippetTopMenu/SnippetTopMenu';
import SNIPPET_DOC from './queries/snippetDoc';
import SnippetProposal from './SnippetProposal/SnippetProposal';
import SnippetProposals from './SnippetProposals/SnippetProposals';
import DocHistory from './DocHistory/DocHistory';
import DocVersion from './DocVersion/DocVersion';
import TableOfContents from './TableOfContents/TableOfContents';
import DocSkeleton from './DocSkeleton/DocSkeleton';
import DocComments from './DocComments/DocComments';
import SourceViewHeader from './SourceViewHeader/SourceViewHeader';
import DocReadView from './DocReadView/DocReadView';
import './SnippetDoc.css';
import SideNav from '../SideNav/SideNav';
import { updateRecentDocs } from '../../utils/localStorage';

// this will become more robust and standardized as settings develop
export const canUserSave = (userId, docCreator, process) => {
    
    if (process.controls[0].processType === 'OPEN') {
        return true;
    }
    
    if (process.creatorCanEdit && docCreator._id === userId) {
        return true;
    }

    else { 
        return false
    }

}

const SnippetDoc = ({

}) => {

    const dispatch = useDispatch();

    const currentUser = useSelector(state => state.auth.user);
    const titleChanges = useSelector(state => state.ui.titleChanges);
    const userId = currentUser._id;
    const { docID, teamSlug, vNum }= useParams();
    const location = useLocation();
    const { loading, error, previousData, data = previousData } = useQuery(SNIPPET_DOC, {
        variables: {
            docID: docID,
            teamSlug: teamSlug
        },
    });

     // add the document to the recent cache
     useEffect(() => {
        updateRecentDocs(docID);
    }, [])

    useEffect(() => {
        if (data && data.doc) {
            dispatch(actions.setTopMenuContext({
                context: 'DOC',
                title: data.doc.title,
                canAdmin: userId === data.doc.creator._id
            }))
        }
    }, [data])


    if (loading) return <DocSkeleton currentUser={currentUser} />;
    if (error) return 'error';

    const userCanSave = canUserSave(userId, data.doc.creator, data.doc.process);
    const docURL = `${process.env.REACT_APP_SITE_URL}/${teamSlug}/${docID}`;

    let docTitle = data.doc.title;

    return (    
        <div className='SnippetDoc'>
            <Helmet>
                <title>{`${data.doc.title} | Crowdwrite`}</title>
                <meta name="description" content={data.doc.description} />
                <meta property="og:title" content={`${data.doc.title} | ${data.doc.creator.username}`} />
                <meta property="og:url" content={docURL} />
                <meta property="og:description" content={data.doc.description} />
                <meta property="og:site_name" content={`Crowdwrite`} />
            </Helmet>
            <div className='mt-20 mb-48'>
                <Routes>
                    <Route 
                        path='/read'
                        element={(
                            <div className='ml-56 mt-20 pr-16 mb-48'>
                                <DocReadView
                                    title={docTitle}
                                    creator={data.doc.creator}
                                    description={data.doc.description}
                                    sponsor={data.team.name}
                                    lastEdited={data.doc.lastEdited}
                                    text={data.doc.text}
                                    entityMap={data.doc.entityMap}
                                />
                            </div>
                        )}
                    />
                    <Route
                        path='/text'
                        element={(
                            <div className='ml-60 mt-20 pr-20 pl-16 mb-48'>
                                <SourceViewHeader
                                    teamSlug={teamSlug}
                                    docID={docID}
                                    docTitle={docTitle}
                                    data={data}
                                    userId={userId}
                                    userCanSave={userCanSave}
                                    participateAccess={data.doc.participateAccess}
                                    inviteID={data.doc.inviteID}
                                />
                                <div className='SnippetDocText'>
                                    <DocText
                                        docID={docID}
                                        docTextID={data.doc.docTextID}
                                        teamID={data.team._id}
                                        teamSlug={teamSlug}
                                        text={data.doc.text}
                                        entityMap={data.doc.entityMap}
                                        userCanSave={userCanSave}
                                        arweaveID={data.doc.arweaveID}
                                        title={data.doc.title}
                                        lastEdited={data.doc.lastEdited}
                                        lastEditor={data.doc.lastEditor}
                                        versionCount={data.doc.versionCount}
                                        participateAccess={data.doc.participateAccess}
                                        inviteID={data.doc.inviteID}
                                        userAccess={data.doc.userAccess}
                                        userId={userId}
                                    />
                                </div>
                                {/* <div className='SnippetDocSidebar'>
                                    <SnippetProposals
                                        docID={docID}
                                        teamID={data.team._id}
                                        teamSlug={teamSlug}
                                        activeProposalCount={data.doc.activeProposals}
                                        text={data.doc.text}
                                        userId={userId}
                                        title={data.doc.title}
                                        userCanSave={userCanSave}
                                    />
                                    <TableOfContents text={data.doc.text} />
                                </div> */}
                            </div>
                        )}
                    />
                    <Route
                        path='/suggestions'
                        element={(
                            <div className='ml-60 mt-20 pr-20 pl-16 mb-48'>
                                <SourceViewHeader
                                    teamSlug={teamSlug}
                                    docID={docID}
                                    docTitle={docTitle}
                                    data={data}
                                    userId={userId}
                                    userCanSave={userCanSave}
                                    participateAccess={data.doc.participateAccess}
                                    inviteID={data.doc.inviteID}
                                />
                                <div style={{ marginLeft: 20 }}>
                                    <DocProposals
                                        teamID={data.team._id}
                                        teamSlug={teamSlug}
                                        docID={docID}
                                        title={data.doc.title}
                                        activeProposals={data.doc.activeProposals}
                                        proposalCount={data.doc.totalProposals}
                                        supportNeeded={data.doc.supportNeeded}
                                        text={data.doc.text}
                                        userId={userId}
                                        userCanSave={userCanSave}
                                        userAccess={data.doc.userAccess}
                                    />
                                </div>
                            </div>
                        )}
                    />
                    <Route
                        path='/comments'
                        element={(
                            <div className='ml-60 mt-20 pr-20 pl-16 mb-48'>
                                <SourceViewHeader
                                    teamSlug={teamSlug}
                                    docID={docID}
                                    docTitle={docTitle}
                                    data={data}
                                    userId={userId}
                                    userCanSave={userCanSave}
                                    participateAccess={data.doc.participateAccess}
                                    inviteID={data.doc.inviteID}
                                />
                                <div style={{ marginLeft: 20 }}>
                                    <DocComments
                                        docID={docID}
                                        activeUser={userId}
                                        totalTopLevelComments={data.doc.totalTopLevelComments}
                                    />
                                </div>
                            </div>
                        )} 
                    />
                    <Route
                        path='/history'
                        element={(
                            <div className='ml-60 mt-20 pr-20 pl-16 mb-48'>
                                <SourceViewHeader
                                    teamSlug={teamSlug}
                                    docID={docID}
                                    docTitle={docTitle}
                                    data={data}
                                    userId={userId}
                                    userCanSave={userCanSave}
                                    participateAccess={data.doc.participateAccess}
                                    inviteID={data.doc.inviteID}
                                />
                                <div style={{ marginLeft: 20 }}>
                                    <DocHistory
                                        docID={docID}
                                    />
                                </div>
                            </div>
                        )}
                    />

                    <Route
                        path='/history/:vNum'
                        element={(
                            <div className='ml-60 mt-20 pr-20 pl-16 mb-48'>
                                <SourceViewHeader
                                    teamSlug={teamSlug}
                                    docID={docID}
                                    docTitle={docTitle}
                                    data={data}
                                    userId={userId}
                                    userCanSave={userCanSave}
                                    participateAccess={data.doc.participateAccess}
                                    inviteID={data.doc.inviteID}
                                />
                                <div style={{ marginLeft: 20 }}>
                                    <DocVersion />
                                </div>
                            </div>
                        )}
                    />
                    <Route
                        path='/suggestions/:prID/*'
                        element={(
                            <div className='ml-60 mt-20 pr-20 pl-16 mb-48'>
                                <SourceViewHeader
                                    teamSlug={teamSlug}
                                    docID={docID}
                                    docTitle={docTitle}
                                    data={data}
                                    userId={userId}
                                    userCanSave={userCanSave}
                                    participateAccess={data.doc.participateAccess}
                                    inviteID={data.doc.inviteID}
                                />
                                <SnippetProposal 
                                    canParticipate={data.doc.userAccess === 'PARTICIPATE'}
                                />
                            </div>
                        )}
                    />
                    <Route
                        path='/'
                        element={<Navigate replace to={`/${teamSlug}/${docID}/text`} />}
                    />
                </Routes>
            </div>
        </div>
    )
}

export default SnippetDoc;