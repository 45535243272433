import { useQuery } from '@apollo/client';
import { Spinner } from '@blueprintjs/core';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import Comment from '../../../Doc/DocProposals/ProposalModal/DocTextDiff/BlockDiff/BlockFeedback/Comment/Comment';
import CommentEditor from '../../../Doc/DocProposals/ProposalModal/DocTextDiff/BlockDiff/CommentEditor/CommentEditor';
import './ProposalComments.css';
import PROPOSAL_COMMENTS from './queries/proposalComments';

const ProposalComments = ({
    prID,
    status,
    totalComments,
    canParticipate
}) => {

    const { loading, error, data, fetchMore } = useQuery(PROPOSAL_COMMENTS, {
        variables: {
            prID: prID,
            offset: 0,
            limit: 5
        },
        // fetchPolicy: 'cache-and-network'
    })

    if (loading) return <Spinner />
    if (error) return 'error';

    const onLoadMore = () => {
        fetchMore({ 
            variables: { 
                offset: data.comments.length 
            }
        })
    };

    const commentBlocks = data.comments.map((comment, i) => {
        return <Comment key={i} {...comment} />
    })

    return (
        <div className='ProposalComments'>
            {canParticipate && <CommentEditor />}
            <InfiniteScroll 
                pageStart={0}
                loadMore={onLoadMore}
                hasMore={data && data.comments && data.comments.length < totalComments}
                loader={<Spinner size={25} />}
            >
                {commentBlocks}
            </InfiniteScroll>
        </div>
    )
}

export default ProposalComments;