import gql from "graphql-tag";

const CREATE_DOC = gql`
    mutation CreateDoc($input: CreateDocInput) {
        createDoc(input: $input) {
            _id
            docID
            team
            title
            process {
                creatorCanEdit
                creatorCanChangeSettings
                controls {
                    processType
                    supportNeeded
                    approvalCount
                    approvers {
                        _id
                        username
                    }
                }
            }
        }
    }
`

export default CREATE_DOC;