import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import InfiniteScroll from 'react-infinite-scroller';
import ProposalCard from './ProposalCard/ProposalCard';
import PROPOSALS from './queries/proposals';
import './DocProposals.css';
import SortSelect from '../../ui/SortSelect/SortSelect';
import { Button, NonIdealState, Spinner } from '@blueprintjs/core';
import InfiniteScrollLoader from '../../ui/InfiniteScrollLoader/InfiniteScrollLoader';
import DocEditorModal from '../DocText/DocEditorModal/DocEditorModal';
import { getHasInvite } from '../../../utils/localStorage';
import EntryModal from '../../EntryModal/EntryModal';

const DocProposals = ({
    docID,
    teamSlug,
    teamID,
    supportNeeded,
    activeProposals,
    proposalCount,
    text,
    userId,
    title,
    userAccess,
    userCanSave
}) => {

    const [showingEditModal, toggleEditModal] = useState(false);
    const closeEditModal = () => toggleEditModal(false);

    const [entryWay, setEntryWay] = useState('SIGN_UP');
    const [showingSignUpModal, toggleSignUpModal] = useState(false);
    const closeSignUpModal = () => toggleSignUpModal(false);

    const showEditModal = () => {

        if (!userId) {
            toggleSignUpModal(true);
        }

        if (userId) {
            toggleEditModal(true);
        }
    }


    const initialSort = activeProposals > 0 ? 'MOST_POPULAR' : 'NEWEST';
    const [selectedSort, selectSort] = useState(initialSort);
    const updateSort = (sort) => selectSort(sort);

    const hasInvite = getHasInvite(docID)

    const { loading, error, data, fetchMore } = useQuery(PROPOSALS, {
        variables: {
            docID: docID,
            teamID: teamID,
            sort: selectedSort,
            offset: 0,
            limit: 5
        },
        fetchPolicy: 'cache-and-network'
    });

    const onLoadMore = () => {
        fetchMore({ 
            variables: { 
                offset: data.proposals.length 
            }
        })
    };
    
    let proposalCards = (
        <div style={{ margin: '60px 0' }}>
            <Spinner size={25} />
        </div>
    )

    if (error) return 'error';
    if (data && data.proposals && data.proposals.length > 0) {
        proposalCards = data.proposals.map((proposal, i) => {
            return (
                <ProposalCard 
                    key={i} 
                    docID={docID} 
                    teamSlug={teamSlug}
                    supportNeeded={supportNeeded} 
                    {...proposal} 
                />
            )
        });
    }

    if (data && data.proposals && data.proposals.length === 0) {
        proposalCards = (
            <NonIdealState
                className='NonIdealBlock'
                title='No suggestions right now'
                action={(
                    <Button
                        intent='primary'
                        text='Make a suggestion'
                        onClick={showEditModal}
                    />
                )}
            />
        )
    }

    return (
        <div className='DocProposals'>
            <div className='ProposalsControls'>
                <SortSelect 
                    isDisabled={data && data.proposals && data.proposals.length === 0}
                    selectedSort={selectedSort}
                    selectSort={updateSort}
                />
                {(hasInvite || userAccess === `PARTICIPATE`) && (
                    <Button
                        small
                        className='font-size-12 font600'
                        onClick={showEditModal}
                        icon='plus'
                        intent='primary'
                        text='New'
                    /> 
                )}
            </div>
            <InfiniteScroll 
                pageStart={0}
                loadMore={onLoadMore}
                hasMore={data && data.proposals && data.proposals.length < proposalCount}
                loader={<Spinner size={25} />}
            >
                <div className='ProposalCards flex flex-col gap-3' key={'n'}>
                    {proposalCards}
                </div>
            </InfiniteScroll>
            <DocEditorModal 
                docID={docID}
                teamID={teamID}
                teamSlug={teamSlug}
                userCanSave={userCanSave}
                text={text}
                title={title}
                onClose={closeEditModal}
                userId={userId}
                isOpen={showingEditModal}
            />
            <EntryModal
                entryWay={entryWay}
                setEntryWay={setEntryWay}
                isOpen={showingSignUpModal}
                handleClose={closeSignUpModal}
            />
        </div>
    )
}

export default DocProposals;