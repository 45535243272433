import React, { useEffect, useState } from 'react';
import validator from 'validator';
import { Button, ControlGroup, FormGroup, Icon, InputGroup } from '@blueprintjs/core';
import Modal from '../../../../ui/Modal/Modal';
import PositionedIcon from '../../../../ui/PositionedIcon/PositionedIcon';
import { EditorState, Modifier } from 'draft-js';
import './LinkEditorModal.css';

export const addHTTP = (url) => {
    let updatedURL = url;
    if (!/^https?:\/\//i.test(url)) {
        updatedURL = 'http://' + url;
    }
    return updatedURL;
}

const getInitialLinkValue = (editorState) => {
    const selection = editorState.getSelection();
    const anchorKey = selection.getAnchorKey();
    const contentState = editorState.getCurrentContent();
    const block = contentState.getBlockForKey(anchorKey);
    const entityKey = block.getEntityAt(selection.getStartOffset());
    if (!entityKey) return '';
    const entityData = contentState.getEntity(entityKey).getData();
    if (!entityData || !entityData.url) return '';
    return entityData.url;
}

const LinkEditorModal = ({
    isOpen,
    onClose,
    editorState,
    setEditorState,
    decorator
}) => {

    // TODO: initialize linkValue to the link for the current selection range if there is one
    // otherwise default to ''

    const initialLinkValue = getInitialLinkValue(editorState);

    const [linkValue, setLinkValue] = useState(() => initialLinkValue);
    const onChangeLink = (e) => setLinkValue(e.target.value);

    useEffect(() => {
        const initialLinkValue = getInitialLinkValue(editorState);
        setLinkValue(initialLinkValue);
    }, [isOpen])

    const onApplyLink = () => {

        const urlToSave = addHTTP(linkValue.trim());

        const selectionState = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
            url: urlToSave,
        });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        let anchorOffset = selectionState.getAnchorOffset();
        let focusOffset = selectionState.getFocusOffset();
        let isSelection = anchorOffset !== focusOffset;

        let contentStateWithLink;
        // if the selection is a single cursor location (no text is selected)
        // insert the URL as text in the document
        if (!isSelection) {
            contentStateWithLink = Modifier.insertText(
                contentStateWithEntity,
                selectionState,
                urlToSave,
                null,
                entityKey
            );
        }
        
        // otherwise, apply the URL to the selected text
        if (isSelection) {
            contentStateWithLink = Modifier.applyEntity(
                contentStateWithEntity,
                selectionState,
                entityKey,
            );
        }

        const newEditorState = EditorState.push(
            editorState,
            contentStateWithLink,
            'apply-entity'
        );

        const newCursorPosition = isSelection ? selectionState.getEndOffset() : selectionState.getEndOffset() + urlToSave.length;
        const selectionStateWithNewAnchor = selectionState.set('anchorOffset', newCursorPosition);
        const selectionWithNewFocus = selectionStateWithNewAnchor.set('focusOffset', newCursorPosition);
        const editorStateWithSelection = EditorState.forceSelection(newEditorState, selectionWithNewFocus);

        const theEditorState = EditorState.set(editorStateWithSelection, {
            decorator: decorator
        })
        setEditorState(theEditorState);
        setLinkValue('');
        onClose();
    }

    const handleClose = () => {
        // handle this in a set timeout so that clicking the backdrop doesn't cause the editor to lose focus
        setTimeout(() => {
            const selectionState = editorState.getSelection();
            const selectionStateWithNewAnchor = selectionState.set('anchorOffset', selectionState.getEndOffset());
            const selectionWithNewFocus = selectionStateWithNewAnchor.set('focusOffset', selectionState.getEndOffset());
            const editorStateWithSelection = EditorState.forceSelection(editorState, selectionWithNewFocus);
            const theEditorState = EditorState.set(editorStateWithSelection, {
                decorator: decorator
            })
            setEditorState(theEditorState);
        }, 1);
        onClose();
    }

    const isLink = validator.isURL(linkValue);

    const handleKeyDown = (e) => {
        if (e.code === 'Space') {
            e.preventDefault();
        }
        if (isLink && e.code === 'Enter') {
            e.preventDefault();
            onApplyLink();
        }
    }

    return (
        <Modal
            className='minimal-modal LinkEditorModal'
            isOpen={isOpen}
            onClose={handleClose}
            transitionDuration={0}
            portalClassName='LinkEditorPortal'
        >
            <h3 className='margin-bottom-4'>
                <PositionedIcon
                    icon='link'
                    iconSize={11}
                    top={-3}
                    marginRight={4}
                />
                Link
            </h3>
            <div className='LinkInputBar'>
                    <InputGroup fill
                        className='LinkInput'
                        autoFocus={true}
                        onKeyDown={handleKeyDown}
                        value={linkValue}
                        onChange={onChangeLink}
                    />
       
                <Button
                    intent='primary'
                    text='Apply'
                    type='submit'
                    disabled={!isLink}
                    onClick={onApplyLink}
                />
            </div>
        </Modal>
    )
}

export default LinkEditorModal;